import { Guid } from 'guid-typescript';
import * as React from 'react';
import {
    Button,
    Overlay,
    Popover,
    Tabs
} from 'react-bootstrap';
import { CustomQuestionsTabConstants, SectionDeleteWarning, TemplateModalConstants } from '../../../../../components/helper/Constants';
import { VenusNotifier } from '../../../../../components/helper/VenusNotifier';
import { IUserModel } from '../../../../../Core/ViewModels/User/UserViewModel';
import { ICompanyData } from '../../../../../store/company/CompanyStore';
import * as UserStore from '../../../../../store/UserManagement/UserStore';
import {
    validateOrganizerDocumentSettings
} from '../../../Helper/OrganizerHelperFunctions';
import { AdditionalQuestion, CustomQuestions, PdfProperties, RadioButtonProperties } from '../../../Helper/PdfHelper';
import { DeliveryOptionsTab } from '../../AllOrganizer/ProcessOrganizerModal/Tabs/DeliveryOptionsTab';
import EngagementLetterTab from '../../AllOrganizer/ProcessOrganizerModal/Tabs/EngagementLetterTab';
import OrganizerTab from '../../AllOrganizer/ProcessOrganizerModal/Tabs/OrganizerTab';
import { BatchFinishPopoverComponent } from '../BatchOrganizerComponents';
import {
    AdditionalEsignDocumentControlData,
    AdditionalEsignDocumentControls,
    AdditionalEsignModel,
    AdditionalQuestionsForm,
    AdditionalQuestionsListItem,
    BatchCSVData,
    BatchModel,
    BatchTabs,
    BatchValidationStatus,
    BookmarkPage,
    Bookmarks,
    ChoosableControlData,
    Control,
    ControlRole,
    ControlType,
    EngagementLetterForm,
    Form,
    FormGroup,
    FormType,
    GroupType,
    initialOrganizerClient,
    OrganizerBookmarkPage,
    OrganizerBookmarks,
    OrganizerClient,
    OrganizerClientType,
    OrganizerCompanySettings,
    OrganizerDocumentSettings,
    OrganizerForm,
    PreparerMessage,
    QuestionControl,
    QuestionType,
    SectionControl,
    SignatureCheckBoxControl,
    SignatureControlsDictionary,
    SignatureRadioButtonControl
} from './../../../../models/OrganizerComponentModels';
import { closeBatchProcessMessage } from './../../../Helper/OrganizerConstants';
import {
    CustomWindow,
    CustomWindowBody,
    CustomWindowFooter
} from './../../AllOrganizer/ProcessOrganizerModal/CustomWindowComponents/CustomWindow';
import { ErrorTab } from './Tabs/ErrorTab';
import * as bootbox from 'bootbox';
import AdditionalEsignTab from '../../AllOrganizer/ProcessOrganizerModal/Tabs/AdditionalEsignTab';
import * as UserSettingStore from '../../../../../store/UserManagement/UserSettingStore';
import { IUserProfile } from '../../../../../components/navigation/profile/ProfileObjects';
import * as UserSignatureStore from '../../../../store/UserSignatureStore';
import CustomQuestionsTab from '../../AllOrganizer/ProcessOrganizerModal/Tabs/CustomQuestionsTab';
import { HasCustomQuestion, ICustomQuestionTemplate, ICustomQuestionsState, ISelectedTemplate } from 'src/Core/ViewModels/CompanySettings/CustomQuestionModel';
import { updateSectionAndQuestionnaireOrder } from 'src/components/helper/HelperFunctions';
import { IConvertedPdfFileList } from 'src/Organizer/store/AdditionalEsignDocumentStore';

interface ProcessBatchModalProps {
    batch: BatchModel;
    onClose: () => void;
    updateBatch: (batch: BatchModel) => void;
    company: ICompanyData;
    users: IUserModel[];
    companySettings: OrganizerCompanySettings;
    onSave: (batch: BatchModel, callback: () => void) => void;
    onFinish: (batch: BatchModel) => void;
    csvLoader: boolean;
    requestBatchError: (page: number, pageSize: number, batchGuid: string) => void;
    updateBatchError: (csvData: BatchCSVData[], batchGuid: string, csvWithErrorData: BatchCSVData[], callback?: () => void) => void;
    checkErrors: (batchGuid: string, callback: (error: boolean) => void) => void;
    checkWarnings: (batchGuid: string, callback: (error: boolean) => void) => void;
    documentUrl: string;
    showSignaturePanel?: boolean;
    generateTaxPayerView: (batch: BatchModel, callback: () => void) => void;
    sourceDocumentEnabled?: boolean;
    getUploadLink: (callback: (data?: any) => void) => void;
    requestEsignDocuments: (documentGuid: string, taxYear: number, callback?: (esignDocs: AdditionalEsignModel[]) => void) => void;
    deleteBlobAdditionalEsign: (fileGuid: string, callback?: () => void) => void;
    deleteEsignDocuments: (ids: number[], callback?: () => void) => void;
    userSettings: UserSettingStore.UserSettings;
    userBasicProfile: IUserProfile;
    userSignatures: UserSignatureStore.IUserSignatures;
    updateEROSignatureStamp?(userId: number): void;
    updateAdditionalEsignDocument: (data: AdditionalEsignModel, callback: () => void) => void;
    requestDownloadPath(userId: number): void;
    additionalEsignReadOnly: boolean;
    convertDocToPdfArtifactAsync: (documentGuid: string, taxYear: number, fileName: string, isBatch: boolean, callback?: (data?: any) => void) => void;
    preparerMessages: PreparerMessage[];
    partners: UserStore.IPartnerData;
    getCustomQuestionTemplates: (callback: (customQuestions: ICustomQuestionsState) => void, failureCallback: () => void) => void;
    getCustomQuestionTemplate: (id: number, callback: (template: ICustomQuestionTemplate) => void, failureCallback: () => void) => void;
    getCustomQuestion: (id: number, callback: (template: ICustomQuestionTemplate) => void, failureCallback: () => void) => void;
    isCustomQuestionEditable: boolean;
    convertedWordToPdfFiles: IConvertedPdfFileList;
}

interface ProcessBatchModalState {
    activeKey: BatchTabs;
    renderTrigger: string;
    loading: boolean;
    showFinishPopover: boolean;
    questionOrder: AdditionalQuestionsListItem[];
    questionPage: number;
    pdfData: string;
    pdfURL: string;
    sectionControls: SectionControl[];
    questionControls: QuestionControl[];
    deletedEsignDocuments: number[];
    questionnaireModel: any;
    templates: any,
    selectedTemplate: any,
}

const viewMax = BatchTabs.DeliveryOptions;
export class ProcessBatchModal extends React.Component<ProcessBatchModalProps, ProcessBatchModalState> {
    finishPopoverRef: any;

    constructor(props: ProcessBatchModalProps) {
        super(props);
        this.state = {
            activeKey: BatchTabs.Error,
            renderTrigger: '',
            loading: false,
            showFinishPopover: false,
            questionOrder: [],
            questionPage: 0,
            pdfData: '',
            pdfURL: '',
            sectionControls: [],
            questionControls: [],
            deletedEsignDocuments: [],
            questionnaireModel: null,
            templates: [],
            selectedTemplate: null
        }
        this.finishPopoverRef = null;
    }

    componentDidMount() {
        this.setState({
            renderTrigger: Guid.create().toString()
        });
        const questionIndex = this.props.batch.formGroups.findIndex(group => group.type === GroupType.AdditionalQuestions);
        if (questionIndex !== -1) {
            this.createQuestionOrder(this.props.batch.formGroups[questionIndex].forms as AdditionalQuestionsForm[]);
        }

        if (this.props.getCustomQuestionTemplates) {
            this.props.getCustomQuestionTemplates(this.onGetCustomQuestionsCallback, this.onFailureCallback);
        }
        this.initializePDFDetail();
    }

    componentWillReceiveProps(nextProps: ProcessBatchModalProps) {
        if (nextProps.documentUrl != undefined) {
            this.setState({ pdfURL: nextProps.documentUrl });
            if (this.state.pdfData == '')
                this.getBase64(nextProps.documentUrl);
        }
    }

    getBase64 = (url: string) => {
        const _self = this;
        fetch(url)
            .then(resp => resp.blob())
            .then(blob => {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                let base64Data: any;
                reader.onloadend = function () {
                    base64Data = _self.extractBase64(reader.result);
                    _self.setState(
                        {
                            pdfData: base64Data
                        });
                }

            })
    }

    isEsignLetterEnabled = (formGroups: FormGroup[]) => {
        const engagementLetterBookmarks = this.getBookmarks(formGroups, GroupType.Engagement, "Bookmarks");
        const engagementLetterPages = this.getPages(engagementLetterBookmarks);
        return engagementLetterPages.length > 0;
    }

    checkAndUpdateEnagementLetterOption = () => {
        const { formGroups, additionalEsignDocuments } = this.props.batch;
        if (!this.isEsignLetterEnabled(formGroups) && additionalEsignDocuments?.length === 0) {
            this.props.batch.settings.documentSettings.reminderSettings.esignReminder.enabled = false;
        }
    }

    extractBase64 = (dataUrl: any) => {
        var index = dataUrl.indexOf('base64,');
        if (index == -1) {
            return null;
        }
        return dataUrl.substring(index + 7);
    }

    handleTemplateSelection = (template) => {
        this.setState({ selectedTemplate: template, questionnaireModel: template });
    }
    handleTemplateUpdate = (template) => {
        this.setState({ questionnaireModel: template });
    }

    onGetCustomQuestionsCallback = (customQuestions) => {
        this.setState({ loading: false })
        let defaultTemplate;
        const templates = [
            {
                formId: 0,
                template: {
                    templateId: -1,
                    name: CustomQuestionsTabConstants.NoTemplate,
                    description: ""
                },
                label: CustomQuestionsTabConstants.NoTemplate,
                value: -1,
                title: CustomQuestionsTabConstants.NoTemplate,
                description: "",
                isDefault: false,
                isDeleted: false,
                sections: []
            }];

        customQuestions?.forEach((customQuestion) => {
            const template: ISelectedTemplate = {
                label: customQuestion.name,
                title: customQuestion.name,
                value: customQuestion.id,
                template: customQuestion,
                isDefault: customQuestion.isDefault,
                description: customQuestion.description,
                sections: []
            }
            if (template.isDefault) {
                defaultTemplate = template;
            }
            templates.push(template);
        })
        this.setState({ templates: templates })
        if (this.props.batch?.customQuestionTemplateId > 0) {
            this.props.getCustomQuestionTemplate(this.props.batch.processInfoId, this.onGetSelectedCustomQuestionCallback, this.onFailureCallback);
        }
        else if (defaultTemplate && this.props.batch.hasCustomQuestion === HasCustomQuestion[0]) {
            this.props.getCustomQuestion(defaultTemplate.value, this.onGetSelectedCustomQuestionCallback, this.onFailureCallback);
        }
        else if (this.props.batch.hasCustomQuestion === HasCustomQuestion[1]) {
            this.handleTemplateSelection(this.state.templates[0]);
        }
    }

    onGetSelectedCustomQuestionCallback = (template: ICustomQuestionTemplate) => {
        let label = TemplateModalConstants.CustomTemplate;
        this.state.templates.forEach((item) => {
            if (item.value === template.template.templateId) {
                label = template.template?.name;
            }
        })
        let selectedTemplate = { ...template, label: label, value: template.template?.templateId };
        this.setState({ loading: false, selectedTemplate: selectedTemplate })
        this.handleTemplateSelection(selectedTemplate);
    }

    onFailureCallback = () => {
        this.setState({ loading: false });
    }

    handleTabSelect = (index: any) => {
        this.setState({ activeKey: parseInt(index.toString()) });
    }

    onPrevious = (engagementLetterExists: boolean) => {
        let { activeKey } = this.state;
        if (activeKey === BatchTabs.Organizer) {
            if (engagementLetterExists) {
                activeKey = BatchTabs.EngagementLetter
            } else {
                activeKey = BatchTabs.Error
            }
        } else {
            if (activeKey - 1 === BatchTabs.Removed) {
                activeKey -= 2;
            } else {
                activeKey -= 1;
            }
        }
        this.setState({ activeKey: activeKey });
    }

    onNext = (engagementLetterExists: boolean) => {
        let { activeKey } = this.state;
        if (activeKey === BatchTabs.Error) {
            if (engagementLetterExists) {
                activeKey = BatchTabs.EngagementLetter
            } else {
                activeKey = BatchTabs.Organizer
            }
        } else {
            if (activeKey + 1 === BatchTabs.Removed) {
                activeKey += 2;
            } else {
                activeKey += 1;
            }
        }
        this.setState({ activeKey: activeKey });
    }


    onSave = () => {
        if (this.props.batch.settings.documentSettings.deliverySettings.contactPersonId === null || this.props.batch.settings.documentSettings.deliverySettings.contactPersonId == 0) {
            VenusNotifier.Warning(`Please select contact person`, null);
            return;
        }
        this.setState({
            loading: true
        });
        let updatedCSV: BatchCSVData[] = [];
        Object.keys(this.props.batch.csvData).forEach(page => {
            updatedCSV = [...updatedCSV, ...this.props.batch.csvData[Number(page)].filter(data => data.modified)];
        })

        let csvWithErrorData: BatchCSVData[] = [];
        Object.keys(this.props.batch.csvData).forEach(page => {
            csvWithErrorData = [...csvWithErrorData, ...this.props.batch.csvData[Number(page)].filter(data => data.modified === false)];
        })

        if (updatedCSV.length) {
            this.props.updateBatchError(updatedCSV, this.props.batch.id, csvWithErrorData);
        }
        const { deletedEsignDocuments } = this.state;
        this.checkAndUpdateEnagementLetterOption();

        let questionnaireVM = this.state.questionnaireModel ? { ...this.state.questionnaireModel } : { ...this.state.templates[0] };
        delete questionnaireVM.label;
        delete questionnaireVM.value;

        let batchOrganizerDocument: any = {
            batchInfo: this.props.batch,
            questionnaireVM: updateSectionAndQuestionnaireOrder(questionnaireVM)

        }


        this.props.onSave(batchOrganizerDocument, () => {
            if (deletedEsignDocuments.length > 0) {
                this.props.deleteEsignDocuments(deletedEsignDocuments);
            }
            this.props.onClose();

        });
    }

    checkErrors = () => {
        this.setState({
            loading: true
        });
        this.onFinishProcessing();
    }

    updateBarchErrorinDB = () => {
        const { batch } = this.props;

        let updatedCSV: BatchCSVData[] = [];
        let csvWithErrorData: BatchCSVData[] = [];
        if (batch.csvData) {
            Object.keys(batch.csvData).forEach(page => {
                updatedCSV = [...updatedCSV, ...this.props.batch.csvData[Number(page)]
                    .filter(data => data.modified)];
            })

            Object.keys(batch.csvData).forEach(page => {
                csvWithErrorData = [...csvWithErrorData, ...this.props.batch.csvData[Number(page)]
                    .filter(data => data.modified === false)];
            })
        }

        if (updatedCSV.length) {
            this.props.updateBatchError(updatedCSV, this.props.batch.id, csvWithErrorData);
        }
    }

    showWarningMessageBatchContainsErrors = () => {
        VenusNotifier.Warning("Batch contains errors", null);
        this.setState({
            loading: false
        });
    }

    isDeliverBatchPopup = (errorDataCount: number, warningDataCount: number, ignoreWarnings: boolean, ignoreErrors: boolean) => {
        if (ignoreErrors && ignoreWarnings) {
            return true;
        } if (!ignoreErrors && !ignoreWarnings) {
            if (!errorDataCount && !warningDataCount) {
                return true;
            }
        } else if (!ignoreWarnings) {
            if (!errorDataCount) {
                return true;
            }
        } else if (!ignoreErrors) {
            if (!warningDataCount) {
                return true;
            }
        }
        return false;
    }

    onFinishProcessing = () => {
        const { batch } = this.props;
        let errorData: BatchCSVData[] = [];
        let warningData: BatchCSVData[] = [];
        if (batch.csvData) {
            Object.keys(batch.csvData).forEach(page => {
                errorData = [...errorData, ...this.props.batch.csvData[Number(page)]
                    .filter(data => data.validationResult.status === BatchValidationStatus.Failed)];
                warningData = [...warningData, ...this.props.batch.csvData[Number(page)]
                    .filter(data => data.validationResult.status === BatchValidationStatus.Warning)];
            });
        }

        let engagementLetterExists = false;
        const index = batch.formGroups.findIndex(formGroup => formGroup.type === GroupType.Engagement);
        if (index !== -1) {
            engagementLetterExists = true;
        }

        let valid = true;
        if (!validateOrganizerDocumentSettings(batch.settings.documentSettings, engagementLetterExists)) {
            valid = false;
        }

        if (!valid) {
            this.setState({
                loading: false
            });
            return;
        }

        let checkErrorInDB, checkWarningInDB;
        checkErrorInDB = checkWarningInDB = false;

        if (this.isDeliverBatchPopup(errorData.length, warningData.length, batch.settings.ignoreWarnings, batch.settings.ignoreErrors)) {
            this.deliverPopoverToggle();
        }
        else {
            return this.showWarningMessageBatchContainsErrors();
        }
    }

    deliverPopoverToggle = () => {
        this.setState((prevState) => ({
            showFinishPopover: !prevState.showFinishPopover,
            loading: false
        }));
    }

    onDeliver = () => {

        this.setState({
            loading: true
        });
        this.updateBarchErrorinDB();

        document.body.click();
        this.checkAndUpdateEnagementLetterOption();
        let questionnaireVM = this.state.questionnaireModel ? { ...this.state.questionnaireModel } : { ...this.state.templates[0] };
        delete questionnaireVM.label;
        delete questionnaireVM.value;

        let batchOrganizerDocument: any = {
            batchInfo: this.props.batch,
            questionnaireVM: updateSectionAndQuestionnaireOrder(questionnaireVM)

        }

        if (this.state.deletedEsignDocuments.length > 0) {
            this.props.deleteEsignDocuments(this.state.deletedEsignDocuments, () => {
                this.setState({ deletedEsignDocuments: [] });
                this.props.onFinish(batchOrganizerDocument);
            });
        }
        else {
            this.props.onFinish(batchOrganizerDocument);
        }
    }

    validateEngagementLetter = (formGroups: FormGroup[],
        taxpayer: OrganizerClient | undefined,
        spouse: OrganizerClient | undefined) => {

        const signatureControls = this.getSignatureControls(formGroups, GroupType.Engagement);
        const signatureControlstoArray: any = Object.keys(signatureControls).map((key: any) => signatureControls[key]);

        if (taxpayer && !taxpayer.isDeceased &&
            this.filterSignatureByRole(signatureControlstoArray, "controlRole", ControlRole.Taxpayer)
                .filter((i: any) => i.controlType == ControlType.Signature)
                .length <= 0) {
            VenusNotifier.Warning(`There should be at least one signature control for the taxpayer to sign.`, null);
            return false;
        }

        if (spouse && !spouse.isDeceased &&
            this.filterSignatureByRole(signatureControlstoArray, "controlRole", ControlRole.Spouse)
                .filter((i: any) => i.controlType == ControlType.Signature)
                .length <= 0) {
            VenusNotifier.Warning(`There should be at least one signature control for the spouse to sign.`, null);
            return false;
        }
        return true;
    }

    filterSignatureByRole = (signatureControls: any, prop: string, role: ControlRole) => {
        const filtered = [];
        if (role) {
            for (let i = 0; i < signatureControls.length; i++) {
                const obj = signatureControls[i];
                for (const key in obj) {
                    if (typeof (obj[key] === "object")) {
                        const item = obj[key];
                        if (item[prop] == role) {
                            filtered.push(item);
                        }
                    }
                }
            }
        }
        return filtered;
    }


    updateDocumentSettings = (settings: OrganizerDocumentSettings) => {
        this.props.updateBatch({
            ...this.props.batch,
            settings: {
                ...this.props.batch.settings,
                documentSettings: settings
            }
        });
    }

    updateFirstSigner = (type: OrganizerClientType) => {
        this.props.updateBatch({
            ...this.props.batch,
            settings: {
                ...this.props.batch.settings,
                firstSigner: type
            }
        });
    }

    changeIgnoreWarningAndError = (ignoreWarnings: boolean, ignoreErrors: boolean) => {
        this.props.updateBatch({
            ...this.props.batch,
            settings: {
                ...this.props.batch.settings,
                ignoreWarnings,
                ignoreErrors
            }
        });
    }

    getBookmarks = (formGroups: FormGroup[], type: GroupType, heading: string): Bookmarks => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        if (index === -1) {
            return {
                heading: "",
                pages: []
            }
        }
        const bookmarkPages = formGroups[index].forms.map(form => {
            return {
                pageNo: form.pageNo,
                pageTitle: form.bookmark
            } as BookmarkPage
        })
        return {
            heading: heading,
            pages: bookmarkPages
        }
    }

    getOrganizerBookmarks = (formGroups: FormGroup[], heading: string): OrganizerBookmarks => {
        const index = formGroups.findIndex(formGroup => formGroup.type === GroupType.Organizer);
        if (index === -1) {
            return {
                heading: "",
                pages: []
            }
        }
        const bookmarkPages = formGroups[index].forms.map(form => {
            const organizerform = form as OrganizerForm;
            return {
                pageNo: form.pageNo,
                pageTitle: form.bookmark,
                enabledSourceDocument: this.props.sourceDocumentEnabled && organizerform.enabledSourceDocument
            } as OrganizerBookmarkPage
        })
        return {
            heading: heading,
            pages: bookmarkPages
        }
    }

    getPages = (bookmarks: Bookmarks): number[] => {
        return bookmarks.pages.map(page => page.pageNo);
    }

    getSignatureControls = (formGroups: FormGroup[], type: GroupType): SignatureControlsDictionary => {
        const controls: SignatureControlsDictionary = {};

        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        if (formGroups[index] && formGroups[index].forms) {
            formGroups[index].forms.map((f: Form) => {
                const form: EngagementLetterForm = f as EngagementLetterForm;
                if (!form.formData) {
                    form.formData = { controlList: [] };
                }
                controls[form.pageNo] = form.formData.controlList;
            });
        }
        return controls;
    }

    getQuestionControls = (formGroups: FormGroup[], type: GroupType): SignatureControlsDictionary => {
        const controls: SignatureControlsDictionary = {};

        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        if (formGroups[index] && formGroups[index].forms) {
            formGroups[index].forms.map((f: Form) => {
                const form: AdditionalQuestionsForm = f as AdditionalQuestionsForm;
                if (!form.formData) {
                    form.formData = { controlList: [] };
                }
                controls[form.pageNo] = form.formData.controlList;
            });
            this.addQuestionNumber(controls);
        }
        return controls;
    }

    addQuestionNumber = (controlsDictionary: SignatureControlsDictionary) => {
        let _questionNumber = 1;
        let _subQIndex = 0;
        Object.entries(controlsDictionary).forEach(([page, controls]) => {
            if (controls) {
                controls.forEach((control: Control) => {
                    if (control.controlType == ControlType.QuestionControl) {
                        const _question = control as QuestionControl;
                        if (_question.parentQuestionId == Guid.EMPTY) {
                            _question.questionNo = _questionNumber.toString();
                            _questionNumber++;
                            _subQIndex = 0;
                        }
                        else {
                            _question.questionNo = CustomQuestions.SubQuestionSeries.charAt(_subQIndex++);
                        }
                    }
                });
            }
        });
    }

    createQuestionOrder = (forms: AdditionalQuestionsForm[]) => {
        const questionControls: QuestionControl[] = [];
        let index = 1;
        const sectionControls: SectionControl[] = [];
        forms.map((form) => {
            form.formData.controlList?.map((control: Control) => {
                switch (control.controlType) {
                    case ControlType.Section:
                        let _secControl = control as SectionControl;
                        sectionControls.push(_secControl);
                        index++;
                        break;
                    case ControlType.QuestionControl:
                        let _quesControl = control as QuestionControl;
                        questionControls.push(_quesControl);
                        break;
                }
            });
        })

        this.setState({
            questionControls: questionControls,
            sectionControls: sectionControls
        }, () => {
            this.updateQuestionOrder()
        });

    }

    createQuestionForm = (pageNo: number) => {
        let questionForm: any = {
            bookmark: `${CustomQuestions.BookMark}[${pageNo}]`,
            pageNo: pageNo,
            formType: FormType.AdditionalQuestions,
            formData: { controlList: [] },
            formGroupId: 0,
            formGuid: Guid.create().toString(),
            formId: 0,
            formName: ""
        };
        return questionForm;
    }

    reinitializeQuestionForm = (document: BatchModel, pageNo: number) => {
        let customQuestionFormgroup = document.formGroups.filter(x => x.type == GroupType.AdditionalQuestions)[0];
        let index = customQuestionFormgroup.forms.findIndex(form => {
            return Number(pageNo) === form.pageNo
        })
        if (index === -1)
            customQuestionFormgroup.forms.push(this.createQuestionForm(pageNo));
        else {
            customQuestionFormgroup.forms[pageNo - 1].bookmark = `${CustomQuestions.BookMark}[${pageNo}]`;
            (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList = [];
        }
    }

    updateQuestionOrder = () => {
        const { batch, updateBatch } = this.props;
        const { sectionControls, questionControls } = this.state;
        let customQuestionFormgroup = batch.formGroups.filter(x => x.type == GroupType.AdditionalQuestions)[0];
        let capacity = CustomQuestions.InitialCapacity;
        let pageNo = 1;
        let top = CustomQuestions.ReducedTop;
        let questionNo = 1;
        const pageHeight = PdfProperties.PageHeight - (AdditionalQuestion.HeaderHeight + AdditionalQuestion.FooterHeight);//if its first page , leave some space for header and fill remaining space with questions
        if (customQuestionFormgroup.forms.length <= 0) {
            customQuestionFormgroup.forms.push(this.createQuestionForm(pageNo));
        }
        else {
            if (questionControls.length > 0 || sectionControls.length > 0) {
                customQuestionFormgroup.forms.forEach((form, index) => {
                    (form as AdditionalQuestionsForm).formData.controlList = [];
                    (form as AdditionalQuestionsForm).bookmark = `${CustomQuestions.BookMark}[${index + 1}]`;
                });
            }
            else {
                customQuestionFormgroup.forms = [];
            }
        }
        const defaultQuestions = questionControls.filter(x => x.sectionId == Guid.EMPTY && x.parentQuestionId == Guid.EMPTY);
        defaultQuestions.forEach((question) => {
            question.questionNo = questionNo.toString();
            question.textbox.boundingRectangle.top = top;
            question.textbox.boundingRectangle.left = CustomQuestions.defaultQuestion.Left;
            question.textbox.boundingRectangle.width = CustomQuestions.defaultQuestion.Width;
            const questionHeight = question.textbox.boundingRectangle.height;
            const totalHeight = this.getTotalHeight(question);
            if (capacity + totalHeight > pageHeight) {
                capacity = CustomQuestions.InitialCapacity;
                pageNo += 1;
                top = CustomQuestions.DefaultTop;
                question.textbox.boundingRectangle.top = top;
                this.reinitializeQuestionForm(batch, pageNo);
            }
            top -= questionHeight;
            if (question.type === QuestionType.ShortAnswer) {
                top = this.getAdjustedTop(top + questionHeight, question.textbox.boundingRectangle.height);
                question.inputTextbox.boundingRectangle.top = top;
                question.inputTextbox.boundingRectangle.width = question.textbox.boundingRectangle.width;
                question.inputTextbox.boundingRectangle.left = question.textbox.boundingRectangle.left + 15;
                top -= question.inputTextbox.boundingRectangle.height;
            }
            else {
                question.radioButtonGroup.controlData.items[0].boundingRectangle.top = RadioButtonProperties.Top;
                question.radioButtonGroup.controlData.items[1].boundingRectangle.top = RadioButtonProperties.Top;
                question.radioButtonGroup.controlData.items[0].boundingRectangle.left = RadioButtonProperties.Left;
                question.radioButtonGroup.controlData.items[1].boundingRectangle.left = RadioButtonProperties.Left + 40;
            }
            (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(question);
            capacity += totalHeight;
            top -= 10; //space after question
            questionNo++;
            if (question.subQuestions != undefined && question.subQuestions.length > 0) {
                let subquestionIndex = 0;
                question.subQuestions.forEach((subquestion) => {
                    subquestion.subQuestionIds.forEach((id) => {
                        const defaultSubQuestion = questionControls.filter(x => x.id == id)[0];
                        if (defaultSubQuestion) {
                            defaultSubQuestion.questionNo = CustomQuestions.SubQuestionSeries.charAt(subquestionIndex);
                            defaultSubQuestion.textbox.boundingRectangle.top = top;
                            defaultSubQuestion.textbox.boundingRectangle.left = CustomQuestions.defaultSubQuestion.Left;
                            defaultSubQuestion.textbox.boundingRectangle.width = CustomQuestions.defaultSubQuestion.Width;
                            const subQuestionHeight = defaultSubQuestion.textbox.boundingRectangle.height;
                            const totalsubQuestionHeight = this.getTotalHeight(defaultSubQuestion);
                            if (capacity + totalsubQuestionHeight > pageHeight) {
                                capacity = CustomQuestions.InitialCapacity;
                                pageNo += 1;
                                top = CustomQuestions.DefaultTop;
                                defaultSubQuestion.textbox.boundingRectangle.top = top;
                                this.reinitializeQuestionForm(batch, pageNo);
                            }
                            top -= subQuestionHeight;
                            if (defaultSubQuestion.type === QuestionType.ShortAnswer) {
                                top = this.getAdjustedTop(top + subQuestionHeight, defaultSubQuestion.textbox.boundingRectangle.height);
                                defaultSubQuestion.inputTextbox.boundingRectangle.left = defaultSubQuestion.textbox.boundingRectangle.left + 15;
                                defaultSubQuestion.inputTextbox.boundingRectangle.width = defaultSubQuestion.textbox.boundingRectangle.width;
                                defaultSubQuestion.inputTextbox.boundingRectangle.top = top;
                                top -= defaultSubQuestion.inputTextbox.boundingRectangle.height;
                            }
                            else {
                                defaultSubQuestion.radioButtonGroup.controlData.items[0].boundingRectangle.left = CustomQuestions.subQuestionRadio.YesButtonLeft;
                                defaultSubQuestion.radioButtonGroup.controlData.items[1].boundingRectangle.left = CustomQuestions.subQuestionRadio.NoButtonLeft;
                            }
                            (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(defaultSubQuestion);
                            capacity += totalsubQuestionHeight;
                            top -= 10; //space after question
                            subquestionIndex++;
                        }
                    })
                })
            }

        });

        sectionControls.forEach((sectionControl) => {
            sectionControl.section.boundingRectangle.top = top;
            sectionControl.section.boundingRectangle.left = 20;
            const customQuestions = questionControls.filter(x => x.sectionId == sectionControl.id && x.parentQuestionId == Guid.EMPTY);
            const totalcustomQuestionHeight = (customQuestions && customQuestions.length > 0) ? this.getTotalHeight(customQuestions[0]) : 0;

            if (capacity + sectionControl.section.boundingRectangle.height + totalcustomQuestionHeight > pageHeight) {
                capacity = 0;
                pageNo += 1;
                top = CustomQuestions.DefaultTop;
                sectionControl.section.boundingRectangle.top = top;
                this.reinitializeQuestionForm(batch, pageNo);
            }
            (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(sectionControl);
            capacity += sectionControl.section.boundingRectangle.height;
            top -= sectionControl.section.boundingRectangle.height;

            customQuestions.forEach((customQuestion) => {
                customQuestion.questionNo = questionNo.toString();
                customQuestion.textbox.boundingRectangle.top = top;
                customQuestion.textbox.boundingRectangle.left = CustomQuestions.customQuestion.Left;
                customQuestion.textbox.boundingRectangle.width = CustomQuestions.customQuestion.Width;
                const customQuestionHeight = customQuestion.textbox.boundingRectangle.height;
                const totalcustomQuestionHeight = this.getTotalHeight(customQuestion);
                if (capacity + totalcustomQuestionHeight > pageHeight) {
                    capacity = CustomQuestions.InitialCapacity;
                    pageNo += 1;
                    top = CustomQuestions.DefaultTop;
                    customQuestion.textbox.boundingRectangle.top = top;
                    this.reinitializeQuestionForm(batch, pageNo);
                }
                top -= customQuestionHeight;
                if (customQuestion.type === QuestionType.ShortAnswer) {
                    top = this.getAdjustedTop(top + customQuestionHeight, customQuestion.textbox.boundingRectangle.height);
                    customQuestion.inputTextbox.boundingRectangle.left = customQuestion.textbox.boundingRectangle.left + 15;
                    customQuestion.inputTextbox.boundingRectangle.top = top;// top - customQuestion.textbox.boundingRectangle.height;
                    customQuestion.inputTextbox.boundingRectangle.width = customQuestion.textbox.boundingRectangle.width;
                    top -= customQuestion.inputTextbox.boundingRectangle.height;
                }
                else {
                    customQuestion.radioButtonGroup.controlData.items[0].boundingRectangle.left = CustomQuestions.subQuestionRadio.YesButtonLeft;
                    customQuestion.radioButtonGroup.controlData.items[1].boundingRectangle.left = CustomQuestions.subQuestionRadio.NoButtonLeft;
                }
                (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(customQuestion);
                capacity += totalcustomQuestionHeight;
                top -= 10;
                questionNo++;
                if (customQuestion.subQuestions != undefined && customQuestion.subQuestions.length > 0) {
                    let customSubquestionIndex = 0;
                    customQuestion.subQuestions.forEach((subquestion) => {
                        subquestion.subQuestionIds.forEach((id) => {
                            const customSubQuestion = questionControls.filter(x => x.id == id)[0];
                            if (customSubQuestion) {
                                customSubQuestion.questionNo = CustomQuestions.SubQuestionSeries.charAt(customSubquestionIndex);
                                customSubQuestion.textbox.boundingRectangle.top = top;
                                customSubQuestion.textbox.boundingRectangle.left = CustomQuestions.customSubQuestion.Left;
                                customSubQuestion.textbox.boundingRectangle.width = CustomQuestions.customSubQuestion.Width;
                                const subQuestionHeight = customSubQuestion.textbox.boundingRectangle.height;
                                const totalsubQuestionHeight = this.getTotalHeight(customSubQuestion);
                                if (capacity + totalsubQuestionHeight > pageHeight) {
                                    capacity = CustomQuestions.InitialCapacity;
                                    pageNo += 1;
                                    top = CustomQuestions.DefaultTop;
                                    customSubQuestion.textbox.boundingRectangle.top = top;
                                    this.reinitializeQuestionForm(batch, pageNo);
                                }
                                top -= subQuestionHeight;
                                if (customSubQuestion.type === QuestionType.ShortAnswer) {
                                    top = this.getAdjustedTop(top + subQuestionHeight, customSubQuestion.textbox.boundingRectangle.height);
                                    customSubQuestion.inputTextbox.boundingRectangle.left = customSubQuestion.textbox.boundingRectangle.left + 15;
                                    customSubQuestion.inputTextbox.boundingRectangle.width = customSubQuestion.textbox.boundingRectangle.width;
                                    customSubQuestion.inputTextbox.boundingRectangle.top = top;
                                    top -= customSubQuestion.inputTextbox.boundingRectangle.height;
                                }
                                else {
                                    customSubQuestion.radioButtonGroup.controlData.items[0].boundingRectangle.left = CustomQuestions.customSubQuestionRadio.YesButtonLeft;
                                    customSubQuestion.radioButtonGroup.controlData.items[1].boundingRectangle.left = CustomQuestions.customSubQuestionRadio.NoButtonLeft;
                                }
                                (customQuestionFormgroup.forms[pageNo - 1] as AdditionalQuestionsForm).formData.controlList.push(customSubQuestion);
                                capacity += totalsubQuestionHeight;
                                top -= 10;
                                customSubquestionIndex++;
                            }
                        })
                    })
                }

            });
        })

        customQuestionFormgroup.forms = customQuestionFormgroup.forms.filter(form => {
            return (form as AdditionalQuestionsForm).formData.controlList.length > 0
        })
        updateBatch({ ...batch, additionalQuestionsPdf: null });
    }

    getAdjustedTop = (top: number, height: number) => {
        let heightDiff = (height * 0.1);
        top = top - height + heightDiff;
        return top;
    }

    getTotalHeight = (question: QuestionControl) => {
        return (question.type == QuestionType.YesNo) ? question.textbox.boundingRectangle.height + 10 :
            question.textbox.boundingRectangle.height + question.inputTextbox.boundingRectangle.height + 5;
    }

    handleDeleteLastSectionQuestion = (questionId: string, page: number, questionControls: QuestionControl[], sectionId: string, confirmDelete: boolean) => {
        const _questions = questionControls.filter(x => x.sectionId == sectionId && x.parentQuestionId == Guid.EMPTY);
        if (_questions && _questions.length == 1) {
            if (!confirmDelete) {
                const _self = this;
                bootbox.dialog({
                    title: SectionDeleteWarning.QuestionDelete.Title,
                    message: SectionDeleteWarning.QuestionDelete.Message,
                    buttons: {
                        cancel: {
                            label: '<i class="glyphicon glyphicon-remove"></i>' + SectionDeleteWarning.QuestionDelete.Cancel,
                            className: 'btn-white',
                            callback: function () { return }
                        },
                        confirm: {
                            label: '<i class="glyphicon glyphicon-ok"></i>' + SectionDeleteWarning.QuestionDelete.Confirm,
                            className: 'btn-info',
                            callback: function () { _self.handleDeleteQuestion(questionId, page, true); }
                        }
                    }
                });
            }
            else {
                this.handleDeleteSection(sectionId);
            }
            return true;
        }
        return false;
    }

    handleDeleteQuestion = (questionId: string, page: number, confirmDelete: boolean = false) => {
        let _questionControls = [...this.state.questionControls];
        let index = _questionControls.findIndex(a => a.id == questionId);
        if (index != -1) {
            let control = _questionControls.filter(x => x.id === questionId)[0];
            if (control.sectionId != Guid.EMPTY) {
                if (this.handleDeleteLastSectionQuestion(questionId, page, _questionControls, control.sectionId, confirmDelete)) {
                    return;
                }
            }
            if (control && control.subQuestions && control.subQuestions?.length > 0) {
                control.subQuestions.forEach((subQMap) => {
                    subQMap.subQuestionIds.forEach((subQ) => {
                        let subQIndex = _questionControls.findIndex(a => a.id == subQ);
                        const subQuestion = _questionControls.filter(a => a.id == subQ)[0];
                        if (subQIndex != -1) {
                            _questionControls.splice(subQIndex, 1);
                            this.onQuestionControlRemove(subQuestion, page);
                        }
                    })

                });
            }
            _questionControls.splice(index, 1);
            this.onQuestionControlRemove(control, page);
        }
        this.setState({
            questionControls: _questionControls
        }, () => this.updateQuestionOrder());

    }

    onQuestionControlRemove = (control: QuestionControl, pageNo: number) => {
        const { formGroups } = this.props.batch;
        this.props.batch.formGroups = this.removeQuestionControl(formGroups, GroupType.AdditionalQuestions, control, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    removeQuestionControl = (formGroups: FormGroup[], type: GroupType, control: QuestionControl, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type == type);
        formGroups[index].forms.map((f: Form) => {
            const form: AdditionalQuestionsForm = f as AdditionalQuestionsForm;
            const found = form.pageNo === pageNo;
            if (found) {
                form.formData.controlList = form.formData.controlList.filter(x => x.id != control.id);
            }
        });
        return formGroups;
    }

    handleDeleteSection = (id: string) => {
        let { sectionControls } = this.state;
        let _sectionCtrlIndex = sectionControls.findIndex(a => a.id == id);
        const formGroups = this.props.batch.formGroups.filter(x => x.type == GroupType.AdditionalQuestions)[0].forms as AdditionalQuestionsForm[];
        if (_sectionCtrlIndex != -1) {
            const secControl = sectionControls.filter(x => x.id == id)[0];
            const pageNo = formGroups.findIndex(x => x.formData.controlList.filter(y => y.id == id)[0]);
            sectionControls.splice(_sectionCtrlIndex, 1);
            this.onSectionControlRemove(secControl, pageNo + 1);
        }
        let _questionControls = [...this.state.questionControls];
        let _questionsSection = _questionControls.filter(a => a.sectionId == id);
        if (_questionsSection?.length > 0) {
            _questionsSection.forEach((_ques) => {
                let index = _questionControls.findIndex(a => a.id == _ques.id);
                const quesControl = _questionControls.filter(x => x.id == _ques.id)[0];
                const pageNo = formGroups.findIndex(x => x.formData.controlList.filter(y => y.id == _ques.id)[0]);
                if (index != -1) {
                    let control = _questionControls.filter(x => x.id === _ques.id)[0];
                    if (control && control.subQuestions && control.subQuestions?.length > 0) {
                        control.subQuestions.forEach((subQMap) => {
                            subQMap.subQuestionIds.forEach((subQ) => {
                                let subQIndex = _questionControls.findIndex(a => a.id == subQ);
                                if (subQIndex != -1) {
                                    const subQcontrol = _questionControls.filter(x => x.id == subQ)[0];
                                    const pageNo = formGroups.findIndex(x => x.formData.controlList.filter(y => y.id == _ques.id));
                                    this.onQuestionControlRemove(subQcontrol, pageNo + 1);
                                    _questionControls.splice(subQIndex, 1);
                                }
                            })

                        });
                    }
                    _questionControls.splice(index, 1);
                    this.onQuestionControlRemove(quesControl, pageNo + 1);
                }
            });
        }


        this.setState({
            sectionControls: sectionControls,
            questionControls: _questionControls
        }, () => this.updateQuestionOrder());
    }


    onSectionControlRemove = (control: SectionControl, pageNo: number) => {
        const { formGroups } = this.props.batch;
        this.props.batch.formGroups = this.removeSectionControl(formGroups, GroupType.AdditionalQuestions, control, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    removeSectionControl = (formGroups: FormGroup[], type: GroupType, control: SectionControl, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type == type);
        formGroups[index].forms.map((f: Form) => {
            const form: AdditionalQuestionsForm = f as AdditionalQuestionsForm;
            const found = form.pageNo === pageNo;
            if (found) {
                form.formData.controlList = form.formData.controlList.filter(x => x.id != control.id);
            }
        });
        return formGroups;
    }

    addSignatureControl = (formGroups: FormGroup[], type: GroupType, control: Control, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        formGroups[index].forms.map((f: Form) => {
            const form: EngagementLetterForm = f as EngagementLetterForm;
            const found = form.pageNo === pageNo;
            if (found) {
                if (!form.formData) {
                    form.formData = { controlList: [] }
                }
                form.formData.controlList.push(control);
            }
        });
        return formGroups;
    }

    removeSignatureControl = (formGroups: FormGroup[], type: GroupType, control: Control, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        formGroups[index].forms.map((f: Form) => {
            const form: EngagementLetterForm = f as EngagementLetterForm;
            const found = form.pageNo === pageNo;
            if (found) {
                form.formData.controlList = form.formData.controlList.filter(x => x.id !== control.id);
            }
        });
        return formGroups;
    }

    replaceSignatureControl = (formGroups: FormGroup[], type: GroupType, oldControl: Control, newControl: Control, pageNo: number): FormGroup[] => {
        const index = formGroups.findIndex(formGroup => formGroup.type === type);
        formGroups[index].forms.map((f: Form) => {
            const form: EngagementLetterForm = f as EngagementLetterForm;
            const found = form.pageNo === pageNo;
            if (found) {
                for (let i = 0; i < form.formData.controlList.length; i++) {
                    const signControl = form.formData.controlList[i];
                    if (signControl.id === oldControl.id) {
                        signControl.boundingRectangle.left = newControl.boundingRectangle.left;
                        signControl.boundingRectangle.top = newControl.boundingRectangle.top;
                        signControl.controlType = newControl.controlType;
                        break;
                    }
                }
            }
        });
        return formGroups;
    }

    onEngagementLetterControlDrop = (control: Control, pageNo: number) => {
        const { formGroups } = this.props.batch;
        this.props.batch.formGroups = this.addSignatureControl(formGroups, GroupType.Engagement, control, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    onEngagementLetterControlRemove = (control: Control, pageNo: number) => {
        const { formGroups } = this.props.batch;
        this.props.batch.formGroups = this.removeSignatureControl(formGroups, GroupType.Engagement, control, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    onEngagementLetterControlUpdate = (oldControl: Control, newControl: Control, pageNo: number) => {
        const { formGroups } = this.props.batch;
        this.props.batch.formGroups = this.replaceSignatureControl(formGroups, GroupType.Engagement, oldControl, newControl, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    generateTaxPayerView = (callback: () => void) => {

        let questionnaireVM = this.state.questionnaireModel ? { ...this.state.questionnaireModel } : { ...this.state.templates[0] };
        delete questionnaireVM.label;
        delete questionnaireVM.value;

        let batchOrganizerDocument: any = {
            batchInfo: this.props.batch,
            questionnaireVM: updateSectionAndQuestionnaireOrder(questionnaireVM)
        }

        if (this.state.deletedEsignDocuments.length > 0) {
            this.props.deleteEsignDocuments(this.state.deletedEsignDocuments, () => {
                this.setState({ deletedEsignDocuments: [] });
                this.props.generateTaxPayerView(batchOrganizerDocument, callback);
            });
        }
        else {
            this.props.generateTaxPayerView(batchOrganizerDocument, callback);
        }
    }

    submitAdditionalEsignDocs = (data: AdditionalEsignModel[]) => {
        let { additionalEsignDocuments } = this.props.batch;
        this.props.batch.additionalEsignDocuments = [...additionalEsignDocuments, ...data];
        this.props.updateBatch(this.props.batch);
    }

    updateAdditionalEsignDocument = (data: AdditionalEsignModel) => {
        let { additionalEsignDocuments } = this.props.batch;
        this.props.batch.additionalEsignDocuments = additionalEsignDocuments.map((doc) => {
            if (data.fileGuid == doc.fileGuid) {
                return data;
            }
            return doc;
        })
        this.props.updateBatch(this.props.batch);
    }

    deleteAdditionalEsignDocument = (fileGuid: string) => {
        let { additionalEsignDocuments } = this.props.batch;
        let { deletedEsignDocuments } = this.state;
        const deletedDoc = additionalEsignDocuments.find(a => a.fileGuid == fileGuid);
        if (deletedDoc?.id && deletedDoc?.id > 0) {
            this.setState({
                deletedEsignDocuments: [...deletedEsignDocuments, deletedDoc.id]
            });
        }
        this.props.deleteBlobAdditionalEsign(fileGuid);
        this.props.batch.additionalEsignDocuments = additionalEsignDocuments.filter(a => a.fileGuid != fileGuid);
        this.props.updateBatch(this.props.batch);
    }

    onAdditionalEsignControlDrop = (control: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.batch;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        additionalEsignDocuments[index].documentControls = this.addSignatureControlAdditionalEsign(additionalEsignDocuments[index].documentControls, control, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    onAdditionalEsignControlRemove = (control: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.batch;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        additionalEsignDocuments[index].documentControls = this.removeSignatureControlAdditionalEsign(additionalEsignDocuments[index].documentControls, control, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    onAdditionalEsignControlUpdate = (oldControl: Control, newControl: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.batch;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        additionalEsignDocuments[index].documentControls = this.replaceSignatureControlAdditionalEsign(additionalEsignDocuments[index].documentControls, oldControl, newControl, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    onAdditionalEsignSaveToolTip = (control: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.batch;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        additionalEsignDocuments[index].documentControls = this.addSignatureControlAdditionalEsign(additionalEsignDocuments[index].documentControls, control, pageNo);
        let pageIndex = additionalEsignDocuments[index].documentControls.formData.findIndex(a => a.pageNo == pageNo);
        let documentControls = additionalEsignDocuments[index].documentControls.formData[pageIndex].controlList;
        for (let i = 0; i < documentControls.length; i++) {
            let documentControl = documentControls[i];
            if (documentControl.id === control.id) {
                if (control.controlType == ControlType.SignatureRadioButtonControl) {
                    let radioButtonControl = documentControl as SignatureRadioButtonControl;
                    if (radioButtonControl.items.length != Number(control.tooltip)) {
                        radioButtonControl.items = this.addControlItems(control.tooltip);
                    }
                    radioButtonControl.required = control.required;
                    radioButtonControl.tooltip = control.tooltip;
                    documentControl = radioButtonControl;
                    break;
                }
                else if (control.controlType == ControlType.SignatureCheckBoxControl) {
                    let checkBoxControl = documentControl as SignatureCheckBoxControl;
                    if (checkBoxControl.items.length != Number(control.tooltip)) {
                        checkBoxControl.items = this.addControlItems(control.tooltip);
                    }
                    checkBoxControl.required = control.required;
                    checkBoxControl.tooltip = control.tooltip;
                    documentControl = checkBoxControl;
                    break;
                }
                else {
                    documentControl.tooltip = control.tooltip;
                    documentControl.required = control.required;
                    break;
                }
            }
        }
        this.props.updateBatch(this.props.batch);
    }
    private addControlItems = (noofcontrols: any) => {
        let items: any[] = [];
        let controlLeft = 20;
        const name = Guid.create().toString();
        if (noofcontrols) {
            for (let i = 0; i < Number(noofcontrols); i++) {
                let data = ChoosableControlData.createNullObject();
                data.left = (controlLeft * (i + 1));
                data.top = 2;
                data.id = Guid.create().toString();
                data.name = name;
                items.push(data);
            }
        }
        return items;
    }
    onAdditionalEsignUpdateControlData = (control: Control, pageNo: number, documentGuid: string) => {
        let { additionalEsignDocuments } = this.props.batch;
        let index = additionalEsignDocuments.findIndex(a => a.fileGuid == documentGuid);
        let pageIndex = additionalEsignDocuments[index].documentControls.formData.findIndex(a => a.pageNo == pageNo);
        let documentControls = additionalEsignDocuments[index].documentControls.formData[pageIndex].controlList;
        for (let i = 0; i < documentControls.length; i++) {
            let documentControl = documentControls[i];
            if (documentControl.id === control.id) {
                documentControl.boundingRectangle.left = control.boundingRectangle.left;
                documentControl.boundingRectangle.top = control.boundingRectangle.top;
                if (control.controlType == ControlType.SignatureRadioButtonControl) {
                    let radioButtonControl = control as SignatureRadioButtonControl;
                    documentControl = radioButtonControl;
                    documentControl.boundingRectangle.left = control.boundingRectangle.left;
                    documentControl.boundingRectangle.top = control.boundingRectangle.top;
                }
                else if (control.controlType == ControlType.SignatureCheckBoxControl) {
                    let checkBoxControl = documentControl as SignatureCheckBoxControl;
                    documentControl = checkBoxControl;
                    documentControl.boundingRectangle.left = control.boundingRectangle.left;
                    documentControl.boundingRectangle.top = control.boundingRectangle.top;
                }
            }
        }
        this.props.updateBatch(this.props.batch);
    }

    onELPageSaveToolTip = (control: Control, pageNo: number) => {
        const { formGroups } = this.props.batch
        const fGroups = this.removeSignatureControl(formGroups, GroupType.Engagement, control, pageNo);
        this.props.batch.formGroups = this.addSignatureControl(fGroups, GroupType.Engagement, control, pageNo);
        this.props.updateBatch(this.props.batch);
    }

    addSignatureControlAdditionalEsign = (documentControls: AdditionalEsignDocumentControls, control: Control, pageNo: number): AdditionalEsignDocumentControls => {
        if (!documentControls) {
            documentControls = {} as AdditionalEsignDocumentControls;
            documentControls.formData = [];
            let controlList: Control[] = [];
            controlList.push(control);
            documentControls.formData.push({
                pageNo: pageNo,
                controlList: controlList
            } as AdditionalEsignDocumentControlData)
        }
        else {
            var index = documentControls.formData.findIndex(a => a.pageNo == pageNo);
            if (index == -1) {
                let controlList: Control[] = [];
                controlList.push(control);
                documentControls.formData.push({
                    pageNo: pageNo,
                    controlList: controlList
                } as AdditionalEsignDocumentControlData)
            }
            else {
                if (documentControls.formData[index].controlList.every(c => c.id != control.id))
                    documentControls.formData[index].controlList.push(control);
            }
        }
        return documentControls;
    }

    removeSignatureControlAdditionalEsign = (documentControls: AdditionalEsignDocumentControls, control: Control, pageNo: number): AdditionalEsignDocumentControls => {
        const requiredPage = documentControls.formData.find(a => a.pageNo == pageNo);
        if (requiredPage)
            requiredPage.controlList = requiredPage.controlList.filter(a => a.id != control.id);
        return documentControls;
    }

    replaceSignatureControlAdditionalEsign = (documentControls: AdditionalEsignDocumentControls, oldControl: Control, newControl: Control, pageNo: number): AdditionalEsignDocumentControls => {
        const requiredPage = documentControls.formData.find(a => a.pageNo == pageNo);
        if (requiredPage) {
            let requiredControl = requiredPage.controlList.find(a => a.id == oldControl.id);
            if (requiredControl) {
                requiredControl.boundingRectangle.left = newControl.boundingRectangle.left;
                requiredControl.boundingRectangle.top = newControl.boundingRectangle.top;
                requiredControl.controlType = newControl.controlType;
            }
        }
        return documentControls;
    }

    updateEROSignatureStamp = (userId: number) => {
        this.props.batch.settings.documentSettings.signatureSettings.signatureStampUser = userId;
        this.props.updateBatch(this.props.batch);
    }

    private initializePDFDetail() {
        if (this.props.documentUrl != undefined && this.props.documentUrl != '' && (this.state.pdfURL == '' || this.state.pdfData == '')) {
            this.setState({
                pdfURL: this.props.documentUrl
            });
            if (this.state.pdfData == '')
                this.getBase64(this.props.documentUrl);
        }
    }

    render() {

        const { loading, questionPage, pdfData, pdfURL } = this.state;
        const { batch, onClose, showSignaturePanel, requestEsignDocuments, deleteBlobAdditionalEsign,
            users, company, companySettings, csvLoader, requestBatchError, updateBatchError, getUploadLink, additionalEsignReadOnly, preparerMessages,
            isCustomQuestionEditable } = this.props;
        let { formGroups } = this.props.batch;
        const finishPopover = (<Popover id="finish-popover"
            className="finishPopover"
            style={{
                width: "400px",
                // height: "138px",
                top: '735px',
                left: '1503px'
            }}
            title="Finish Batch Processing">
            <BatchFinishPopoverComponent
                deliver={this.onDeliver}
                loading={loading}
                firstSigner={batch.settings.firstSigner}
                updateFirstSigner={this.updateFirstSigner}
                batchName={batch.batchName}
            />
        </Popover>);

        const organizerBookmarks = this.getOrganizerBookmarks(formGroups, "Bookmarks");
        const engagementLetterBookmarks = this.getBookmarks(formGroups, GroupType.Engagement, "Bookmarks");
        const engagementLetterPages = this.getPages(engagementLetterBookmarks);
        const engagementExists = engagementLetterPages.length > 0;
        const additionalFileExists = this.props.batch.additionalEsignDocuments.length > 0;
        const questionsBookmark = this.getBookmarks(formGroups, GroupType.AdditionalQuestions, "Custom Question Pages");
        const questionPages = this.getPages(questionsBookmark);
        let viewMin = engagementExists ? BatchTabs.EngagementLetter : BatchTabs.Organizer;
        let isErrorExist = false;
        let { activeKey } = this.state;
        if (batch?.csvData != undefined) {
            const csvData: BatchCSVData[] = batch.csvData[1]
            if (csvData && csvData?.length > 0) {
                isErrorExist = true;
                viewMin = BatchTabs.Error;
            }
        }
        if (!isErrorExist && activeKey === BatchTabs.Error) {
            activeKey = viewMin;
            this.setState({ activeKey: activeKey })
        }

        const newBatch = {
            ...batch,
            settings: {
                ...batch.settings
            }
        }

        return (<CustomWindow key={`custom-organizer-window-${batch.id}`}
            onClose={() => onClose()}
            title={batch.batchName}
            order={1}
            disableMaximize={true}
            disableMinimize={true}
            disableFullscreen={false}
            alwaysFitToParent={true}
            closeMessage={closeBatchProcessMessage}
            onResize={() => { this.setState({ renderTrigger: Guid.create().toString() }) }}>
            {
                (<CustomWindowBody isBusy={loading}>
                    <Tabs
                        activeKey={activeKey}
                        onSelect={this.handleTabSelect}
                        id="process-return-groups"
                        fill>
                        {isErrorExist &&
                            <Tabs eventKey={BatchTabs.Error}
                                title={<div className="overflowText">Errors</div>}
                                className="zIndex10">
                                {(activeKey === BatchTabs.Error) &&
                                    <ErrorTab
                                        batchGuid={batch.id}
                                        loading={csvLoader}
                                        pagedCSVData={batch.csvData}
                                        requestBatchCSV={requestBatchError}
                                        updateBatchCSV={updateBatchError}
                                        changeIgnoreWarningAndError={this.changeIgnoreWarningAndError}
                                        newBatch={newBatch}
                                    />
                                }

                            </Tabs>
                        }
                        {
                            engagementExists &&
                            <Tabs eventKey={BatchTabs.EngagementLetter}
                                title={<div className="overflowText">Engagement Letter</div>}
                                className="zIndex10">
                                {
                                    (activeKey === BatchTabs.EngagementLetter) &&
                                    <EngagementLetterTab
                                        pdf={batch.organizerPdf}
                                        organizerClients={[]}
                                        bookmarks={engagementLetterBookmarks}
                                        pages={this.getPages(engagementLetterBookmarks)}
                                        onDrop={this.onEngagementLetterControlDrop}
                                        onControlRemove={this.onEngagementLetterControlRemove}
                                        onControlUpdate={this.onEngagementLetterControlUpdate}
                                        signatureControls={this.getSignatureControls(formGroups, GroupType.Engagement)}
                                        hideMoveTo={true}
                                        demoClient={true}
                                        pdfURL={pdfURL}
                                        showSignaturePanel={showSignaturePanel}
                                        onSaveToolTip={this.onELPageSaveToolTip}
                                    />
                                }
                            </Tabs>
                        }
                        <Tabs eventKey={BatchTabs.Organizer}
                            title={<div className="overflowText">Organizer</div>}
                            className="zIndex10">
                            {
                                (activeKey === BatchTabs.Organizer) &&
                                <OrganizerTab
                                    pdf={batch.organizerPdf}
                                    bookmarks={organizerBookmarks}
                                    pages={this.getPages(organizerBookmarks)}
                                    engagementLetterExists={engagementExists}
                                    hideMoveTo={true}
                                    pdfURL={pdfURL}
                                    sourceDocumentEnabled={this.props.sourceDocumentEnabled}
                                    organizerClients={[initialOrganizerClient]}
                                    company={this.props.company}
                                    isAssignedToLoggedinUser={true}
                                    userProfile={this.props.userBasicProfile}
                                    userSignatures={this.props.userSignatures}
                                    userSettings={this.props.userSettings}
                                    ero={batch.ero}
                                    requestDownloadPath={this.props.requestDownloadPath}
                                    users={this.props.users}
                                    signatureSettings={batch.settings.documentSettings.signatureSettings}
                                    isBatchOrganizer={true}
                                    partners={this.props.partners}
                                    onDrop={() => { }}
                                    onControlRemove={() => { }}
                                    onControlUpdate={() => { }}
                                    onSaveToolTip={() => { }}
                                    signatureControls={this.getSignatureControls(formGroups, GroupType.Engagement)}
                                />
                            }
                        </Tabs>

                        <Tabs eventKey={BatchTabs.AdditionalESign}
                            title={<div className="overflowText">Additional E-Sign Documents</div>}
                            className="zIndex10">
                            {
                                (activeKey === BatchTabs.AdditionalESign) &&
                                <AdditionalEsignTab
                                    additionalEsignDocuments={batch.additionalEsignDocuments}
                                    organizerClients={[]}
                                    bookmarks={engagementLetterBookmarks}
                                    onDrop={this.onAdditionalEsignControlDrop}
                                    onControlRemove={this.onAdditionalEsignControlRemove}
                                    onControlUpdate={this.onAdditionalEsignControlUpdate}
                                    readOnlyView={false}
                                    pdfURL={pdfURL}
                                    showSignaturePanel={showSignaturePanel}
                                    getUploadLink={getUploadLink}
                                    documentGuid={batch.id}
                                    submitDocuments={this.submitAdditionalEsignDocs}
                                    requestDocuments={requestEsignDocuments}
                                    deleteDocument={this.deleteAdditionalEsignDocument}
                                    deleteBlobAdditionalEsign={deleteBlobAdditionalEsign}
                                    updateDocument={this.updateAdditionalEsignDocument}
                                    demoClient={true}
                                    isAssignedToLoggedinUser={true}
                                    company={this.props.company}
                                    userSettings={this.props.userSettings}
                                    userProfile={this.props.userBasicProfile}
                                    userSignatures={this.props.userSignatures}
                                    updateEROSignatureStamp={this.updateEROSignatureStamp}
                                    taxYear={batch.taxYear}
                                    signatureSettings={batch.settings.documentSettings.signatureSettings}
                                    ero={batch.ero}
                                    users={this.props.users}
                                    requestDownloadPath={this.props.requestDownloadPath}
                                    readonly={additionalEsignReadOnly}
                                    convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                                    batchId={batch.id}
                                    onSaveToolTip={this.onAdditionalEsignSaveToolTip}
                                    onUpdateControlData={this.onAdditionalEsignUpdateControlData}
                                    partners={this.props.partners}
                                    convertedWordToPdfFiles={this.props.convertedWordToPdfFiles}
                                />
                            }
                        </Tabs>
                        <Tabs eventKey={BatchTabs.AdditionalQuestions}
                            title={<div className="overflowText">Custom Questions</div>}
                            className="zIndex10">
                            {(activeKey === BatchTabs.AdditionalQuestions) &&
                                <CustomQuestionsTab templates={this.state.templates}
                                    isEditable={isCustomQuestionEditable}
                                    selectedTemplate={this.state.selectedTemplate}
                                    onSetSelectedTemplate={this.handleTemplateSelection}
                                    onTemplateUpdate={this.handleTemplateUpdate}
                                />
                            }

                        </Tabs>

                        <Tabs eventKey={BatchTabs.DeliveryOptions}
                            title={<div className="overflowText">Delivery Options</div>}
                            className="zIndex10">
                            {
                                (activeKey === BatchTabs.DeliveryOptions) &&
                                <DeliveryOptionsTab
                                    company={company}
                                    documentSettings={batch.settings.documentSettings}
                                    organizerClients={[]}
                                    updateClients={() => { }}
                                    updateSettings={this.updateDocumentSettings}
                                    preparerMessages={preparerMessages}
                                    users={users}
                                    formGroups={formGroups}
                                    engagementLetterExists={engagementExists}
                                    additionalFileExists={additionalFileExists}
                                    companySettings={companySettings}
                                    demoClient={true}
                                    firstSigner={batch.settings.firstSigner}
                                    updateFirstSigner={this.updateFirstSigner}
                                    generateTaxpayerView={this.generateTaxPayerView}
                                />
                            }
                        </Tabs>
                    </Tabs>
                </CustomWindowBody>)
            }

            <CustomWindowFooter>
                <Button
                    variant="default"
                    data-test-auto="27A54F41-0CFF-4F81-B684-2052B7424A9B"
                    className="btn-white"
                    disabled={activeKey === viewMin || loading}
                    onClick={() => this.onPrevious(engagementExists)}>
                    <i className='fa fa-chevron-left'></i>Previous
                </Button>
                <Button
                    variant="default"
                    data-test-auto="B1C60A60-C306-4A4D-B914-AC034DCB71E6"
                    className="btn-white"
                    disabled={activeKey === viewMax || loading}
                    onClick={() => this.onNext(engagementExists)}>
                    <i className='fa fa-chevron-right'></i>Next
                </Button>

                <Button
                    disabled={loading}
                    variant="info" onClick={
                        this.onSave
                    } >
                    <i className='glyphicon glyphicon-floppy-disk'></i>Save & Close
                </Button>
                <Button
                    disabled={loading}
                    variant="success" onClick={() => {
                        this.state.showFinishPopover ?
                            this.deliverPopoverToggle() :
                            this.checkErrors()
                    }}
                    ref={(reference: Button | null) => { this.finishPopoverRef = reference }}
                >
                    <i className='fa fa-check'></i>Finish
                </Button>

                <Overlay rootClose={true} placement="top"
                    onHide={() => { this.setState({ showFinishPopover: false }) }}
                    target={this.finishPopoverRef} show={this.state.showFinishPopover}>
                    {finishPopover}
                </Overlay>

            </CustomWindowFooter>
        </CustomWindow >)
    }
}
