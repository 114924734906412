import * as React from 'react';
import { BatchUploadModal } from './BatchUploadModal';
import { OrganizerUploadState } from './../../../models/OrganizerStoreModels';
import { OrganizerDocumentUploadType } from './../../../models/OrganizerComponentModels';
import { TaxSoftwareType } from './../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IPartnerModel } from '../../../../Core/ViewModels/User/UserViewModel';
import ProformaUploadModal from './ProformaUploadModal';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';

interface OrganizerUploadModalSelectorProps {
    show: boolean;
    taxSoftware: TaxSoftwareType;
    onHide(): void;
    getUploadLink: (url: string, callback?: (data?: OrganizerUploadState) => void) => void;
    submitDocuments: (url: string, taxData: string, callback: () => void) => void;
    submitSingleDocuments: (url: string, ElData: string, callback: () => void) => void;
    pageReload: () => void;
    deleteDocument: (guid: string, taxSoftware: TaxSoftwareType, isOrganizerFile: boolean, organizerGuid: string, callback: () => void) => void;
    deleteBatchDocument: (url: string, callback: () => void) => void;
    uploadType: OrganizerDocumentUploadType;
    defaultERO: number;
    eroUsers: IPartnerModel[];
    downloadCSVTemplate: (callback: () => void) => void;
    enableEngagementLetter: boolean;
    userLocations : IDropdown[] | [];
}
export enum UploadTabs {
    Organizer,
    Enagagementletter,
}
const OrganizerUploadModalSelector: React.StatelessComponent<OrganizerUploadModalSelectorProps> = ({
    show, taxSoftware, getUploadLink, onHide, pageReload, submitDocuments, deleteDocument, uploadType, submitSingleDocuments,
    deleteBatchDocument, eroUsers, defaultERO, downloadCSVTemplate, enableEngagementLetter, userLocations }) => {
    return (
        <div>
           { show && (uploadType === OrganizerDocumentUploadType.Proforma) && <ProformaUploadModal
                show={show && (uploadType === OrganizerDocumentUploadType.Proforma)}
                getUploadLink={getUploadLink}
                onHide={onHide}
                pageReload={pageReload}
                submitDocuments={submitDocuments}
                deleteDocument={deleteDocument}
                taxSoftware={taxSoftware}
                submitSingleDocuments={submitSingleDocuments}
                enableEngagementLetter={enableEngagementLetter}
            />}
            {
                show && (uploadType === OrganizerDocumentUploadType.Batch) &&
                <BatchUploadModal
                    show={show && (uploadType === OrganizerDocumentUploadType.Batch)}
                    onHide={onHide}
                    taxSoftware={taxSoftware}
                    getUploadLink={getUploadLink}
                    deleteDocument={deleteBatchDocument}
                    pageReload={pageReload}
                    submitDocuments={submitDocuments}
                    eroUsers={eroUsers}
                    defaultERO={defaultERO}
                    downloadCSVTemplate={downloadCSVTemplate}
                    enableEngagementLetter={enableEngagementLetter}
                    userLocations={userLocations}
                />
            }
        </div>
    );
}

export { OrganizerUploadModalSelector }
