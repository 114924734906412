import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';

interface TabContentContainerMiddlePanelProps {
    isContentEmpty?: boolean;
    disableScroll?: boolean;
    style?: React.CSSProperties;
}

export class TabContentContainerFullScreenPanel extends React.Component<TabContentContainerMiddlePanelProps, {}> {

    private createContent = () => {
        if (this.props.isContentEmpty) {
            return (<div className="tab-content-container-middle-panel-no-content col-lg-12 col-sm-12 col-xs-12 h100">
                <div className="clearfix tab-no-content-message">
                    <h1 className="float-left">No forms found !!</h1>
                </div>
            </div>);
        }
        else {


            if (this.props.disableScroll) {
                return (<div className="tab-content-container-middle-panel col-lg-12 col-sm-12 col-xs-12 h100" style={this.props.style}>
                    <div className={"pr-center-pannel"}>
                        <div className={"scroll"} style={{ overflow: "hidden" }}>
                            {this.props.children}
                        </div>
                    </div>
                </div>);
            }
            else {
                return (<div className="tab-content-container-middle-panel col-lg-12 col-sm-12 col-xs-12 h100" style={this.props.style}>
                    <div className={"pr-center-pannel"}>
                        <div className={"scroll"}>
                            <Scrollbars hideTracksWhenNotNeeded={true} autoHide={true}>
                                {this.props.children}
                            </Scrollbars>
                        </div>
                    </div>
                </div>);
            }
        }
    }

    public render() {
        return this.createContent();
    }
}

