import { ReminderSettings } from "../models/OrganizerComponentModels";
export class AutoReminderRequest {

    orgAutoReminders: AutomaticReminderOrganizerInfo[];
    reminderSetting: ReminderSettings;

    constructor(reminderOrganizerList: AutomaticReminderOrganizerInfo[],
        reminderSettings: ReminderSettings) {
        this.orgAutoReminders = reminderOrganizerList;
        this.reminderSetting = reminderSettings;
    }
}

export class AutomaticReminderOrganizerInfo {

    processinfoId: number;
    organizerDeliveredDate: Date;
    signatureStatus: number;
    organizerStatus: number;
    esignLastReminderDate: Date;
    organizerLastReminderDate: Date;

    constructor(processinfoId: number, organizerDeliveredDate: Date, signatureStatus: number, organizerStatus: number, esignLastReminderDate: Date, organizerLastReminderDate: Date) {
        this.processinfoId = processinfoId;
        this.organizerDeliveredDate = organizerDeliveredDate;
        this.signatureStatus = signatureStatus;
        this.organizerStatus = organizerStatus;
        this.esignLastReminderDate = esignLastReminderDate;
        this.organizerLastReminderDate = organizerLastReminderDate;
    }
}
