import { IUserProfile } from "../navigation/profile/ProfileObjects";
import { IUserBaseModel, IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { IFilters } from '../../components/reports/Filters'
import {
    ITaxReturn, DocumentStatus, IFormBase, isPartnership, isIndividual, IK1, IMarriedJointTaxReturn,
    isMutual, DocumentGroups, IGroup, ITaxingAuthority, ISignatureControl, IEFile, EngagementType, SignatureStatus, ClientTypes,
    getAllStateImages, StateUSA, ClientTypesNumber
} from '../common/TaxReturn';
import { VenusNotifier } from './VenusNotifier';
import { isValidEmailAddress, isValidSSN, isValidEIN, NullandEmptyCheck } from './Validations';
import { Moment } from 'moment';
import * as Constants from '../helper/Constants';
import { ICountryState, IPrimaryAdmin } from '../../Core/ViewModels/Company/CompanyViewModel';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ISessionLocalStore } from '../../Core/Utilities/SessionStore';
import { Control, ControlType, DataType, Question, QuestionControl, QuestionType, RadioButton, SectionControl, SubQuestion, SubQuestionMapping, Textbox } from "../../Organizer/models/OrganizerComponentModels";
import { Guid } from "../../Core/Utilities/Guid";
import { RadioButtonProperties, TextBoxProperties } from "../../Organizer/components/Helper/PdfHelper";
import ControlBase from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { API_BASE_URL, SSSUITE_API_BASE_URL } from "src/utils/constants";
import { IFormData, ISection, ISections } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { createCookie, getDomain } from "@sssuite-component-ui/session-timeout";

export const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

let moment = require('moment');
export const NO_INDEX = -1;
export const apiPrefix = `${API_BASE_URL}api/`;
export const sssuiteApiPrefix = `${SSSUITE_API_BASE_URL}api`;

const REGEX_VALUES = [
    { Key: '0', Value: /\d/ }
];
const MASK_PLACEHOLDER = '_';



export function getFileSize(fileSizeInBytes: number) {
    let file = parseInt((fileSizeInBytes / 1024).toString(), 10);
    let fileSize = "";
    fileSize = file < 1024 ? (file + " KB").toString() : (parseInt((file / 1024).toString(), 10) + " MB").toString()
    return fileSize;
}

export function getFileSizeFloatingPoint(fileSizeInBytes: number) {
    let file = Math.ceil(parseFloat((fileSizeInBytes / 1024).toString()));
    let fileSize = "";
    fileSize = file < 1024 ? (file + " KB").toString() : (Math.ceil(parseFloat((file / 1024).toString())) + " MB").toString()
    return fileSize;
}

export function phoneNumberDisplay(phoneNumber: string): string {
    if (!phoneNumber)
        return phoneNumber;
    return phoneNumber && phoneNumber.length == 10 ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10) : phoneNumber;
}

export function getFileSizeBytes(fileSizeInUnit: string) {
    let fileSize = 0;
    if (fileSizeInUnit.indexOf("KB")) {
        fileSizeInUnit = fileSizeInUnit.replace("KB", "")
        fileSize = parseInt(fileSizeInUnit) * 1024;
    }
    else if (fileSizeInUnit.indexOf("MB")) {
        fileSizeInUnit = fileSizeInUnit.replace("MB", "")
        fileSize = parseInt(fileSizeInUnit) * 1024 * 1024;
    }
    else if (fileSizeInUnit.indexOf("GB")) {
        fileSizeInUnit = fileSizeInUnit.replace("GB", "")
        fileSize = parseInt(fileSizeInUnit) * 1024 * 1024 * 1024;
    }
    return fileSize;
}

export function myAccountFetch(): Promise<IUserProfile> {
    return fetch(`${sssuiteApiPrefix}/user-management/user-details`, {
        method: 'GET',
        credentials: 'include'
    })
        .then(response => response.json() as Promise<IUserProfile>)
        .then(data => {
            return data;
        });
}

export function GetFormatedDateTime(date: Date) {
    return moment.utc(date).local().format('MM/DD/YYYY hh:mm:ss A');
}

export function splitName(name: string) {
    let nameList: any;
    let result: string;

    if (name == null) {
        result = "";
    }
    else {
        nameList = name.split(' ').reverse();
        if (nameList.length > 2) {
            result = nameList[0] + ', ' + nameList.slice(1).reverse().join(' ');
        }
        else {
            result = nameList.join(', ');
        }
    }
    return result;
}

export function fullName(user: IUserBaseModel) {
    if (user.lastName) {
        return user.firstName + ' ' + user.lastName;
    }
    return user.firstName;
}

export function userName(user: IUserModel) {
    if (user.lastName) {
        return user.firstName + ' ' + user.lastName;
    }
    return user.firstName;
}


export function intersect(a: any[], b: any[]) {
    var t;
    if (a && b && b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
}

export function insertWhiteSpace(name: string) {
    return name.replace(/([A-Z][a-z]|[A-Z][0-9])/g, ' $1');
}

export function capitaliseString(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function hasRestrictedCharsInClientName(name: string) {
    const restrictedChars = /[%_\[\]\^!]/g;
    return restrictedChars.test(name);
}

export function handleEmailEventCase(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function GetPreviousDate(value: number) {
    const d = new Date();
    d.setDate(d.getDate() - value);
    return moment(d.toString()).format('MM/DD/YYYY');

}

export function GetNumberOfDays(value: any) {
    switch (value) {
        case 'One_Day':
            return GetPreviousDate(1);

        case 'Two_Days':
            return GetPreviousDate(2);

        case 'Three_Days':
            return GetPreviousDate(3);

        case 'Five_Days':
            return GetPreviousDate(5);

        case 'Seven_Days':
            return GetPreviousDate(7);

        case 'Fifteen_Days':
            return GetPreviousDate(15);

        case 'Thirty_Days':
            return GetPreviousDate(30);
        default:
            return GetPreviousDate(1);
    }
}

export function GetFileMagicNumber(file: any): Promise<any> {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file.slice(0, 4));
    return new Promise((resolve) => {
        reader.onload = function () {
            if (this.readyState == reader.DONE) {
                const arrayBuffer: any = this.result;
                const uint = new Uint8Array(arrayBuffer).subarray(0, 4)
                let bytes: any = []
                uint.forEach((byte) => {
                    bytes.push(byte.toString(16))
                })
                return resolve(bytes.join('').toUpperCase());
            }
        }
    });
}


export function getUnmaskedValue(value: string, mask: string): string {
    if (value) {
        const separators = getSeparators(mask);

        return value.split('')
            .filter((v) => { return separators.every((separator) => separator != v) && v != MASK_PLACEHOLDER })
            .join('');
    }
    else {
        return '';
    }
}

export function getSeparators(mask: string): string[] {
    return mask.split('')
        .filter((m, index, self) => {
            return REGEX_VALUES.findIndex(regex => regex.Key == m) == -1 && self.indexOf(m) == index;
        });
}

export function countryCodeDisplay(countryCode: string): string {
    return countryCode ? "(" + countryCode + ") " : "";
}

export function openWindowWithPostRequest(url: string, data: string, fieldName: string, scope?: string) {
    var winName = '_blank';
    var winURL = url;
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", winURL);
    form.setAttribute("target", winName);
    form.setAttribute("enctype", "application/json");
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = fieldName;
    input.value = JSON.stringify(data);
    form.appendChild(input);
    if (scope) {
        var iscope = document.createElement('input');
        iscope.type = 'hidden';
        iscope.name = 'scope';
        iscope.value = scope;
        form.appendChild(iscope);
        document.body.appendChild(form);
    }
    form.target = winName;
    form.submit();
    document.body.removeChild(form);
}

export function getControlTopPositionPDFJS(page: number, top: number, scale: number): number {
    const pageElement: any = ControlBase.getPageElement(page);
    const AWESOME_PDF_VIEWER_LETTER_HEIGHT = 1056;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4 = 842;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER = 792;
    const pageHeight = pageElement ? pageElement.offsetHeight : AWESOME_PDF_VIEWER_LETTER_HEIGHT;
    const baseHeight = pageHeight > AWESOME_PDF_VIEWER_LETTER_HEIGHT ? AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4
        : AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER;
    const topRatio = (pageHeight * scale) / baseHeight;

    top = pageHeight - top;
    top = (top / topRatio) - 5;

    return top;

}

export function resetCookie(cookiename: string, value: string) {
    var cookiestring = document.cookie;
    var cookiearray = cookiestring.split(";");
    for (var i = 0; i < cookiearray.length; ++i) {
        if (cookiearray[i].trim().match("^" + cookiename + "=")) {
            if (cookiearray[i]) {
                cookiearray[i] = `${cookiename}=${value}`.trim();
            }
        }
    }
}
export function validateError(error: any) {
    if (typeof error === 'string') return error;
    if (typeof error === 'undefined') return '';
    if (error.message !== undefined) return error.message;
    if (error.statusText !== undefined) return error.statusText;
    return '';
}

export const getTotalQuestions = (formData: IFormData) => {
    let totalQuestions = 0;

    formData && formData.sections.forEach((section) => totalQuestions = section.sectionItems.length + totalQuestions)
    return totalQuestions;
}

export const getTemplateLimitErrorMessage = (templateLimit: number): string => `Maximum limit of templates is ${templateLimit}`;

export const getQuestionsLimitErrorMessage = (questionLimit: number, text: string): string => `Unable to ${text}. Question limit exceeded! Maximum limit of questions is ${questionLimit}`;

export const getMaxOptionsDisbaledTooltip = (optionLimit: number): string => `Maximum ${optionLimit} options are allowed`;

export const getMaxAdditionalQuestionDisbaledTooltip = (optionLimit: number): string => `Maximum ${optionLimit} additional questions are allowed`;

export function textWithEllipsis(text: string, maxLength: number) {
    if (text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
    }
    return text;
}

export function removeUnwantedWhitespacesFromText(text: string) {
    const splittedText = text.split(/\s+/);
    if (splittedText.length > 1) {
        const lastsplittedText = splittedText.at(-1);
        const firstsplittedText = splittedText.at(0);
        if (lastsplittedText === "") {
            splittedText.pop();
        }
        if (firstsplittedText === "") {
            splittedText.shift();
        }
    }
    return splittedText.join(" ");
}
export const generateRandomString = () => Math.floor(Math.random() * Date.now()).toString(36);

export const generateRandomNumber = () => Number(Math.floor(Math.random() * Date.now()).toString().slice(0, 4));

export const insertRandomIds = (data: IFormData) => {
    if (data?.sections?.length > 0) {
        data.sections.forEach((section) =>
            section.sectionItems.forEach((sectionItem) => (sectionItem.id = generateRandomString()))
        );
    }
    return data;
};

export const updateSectionAndQuestionnaireOrder = (data: IFormData) => {
    if (data) {
        const mutableData = JSON.parse(JSON.stringify(data));
        if (data.sections && mutableData.sections.length > 0) {
            mutableData.sections.forEach((section, sectionIndex) => {
                section.order = sectionIndex + 1;
                section.sectionItems.forEach((sectionItem, sectionItemIndex) => {
                    if (sectionItem.hasOwnProperty('hasFollowUpQuestion')) {
                        delete sectionItem.hasFollowUpQuestion;
                    }
                    sectionItem.order = sectionItemIndex + 1;
                });
            });
        }
        return mutableData;
    }
    return data
};

export const createForethoughtCookie = (key: string, value: string, expiryMiliSeconds?: number) => {
    const domain = getDomain(window.location.href);
    createCookie(key, value, expiryMiliSeconds ?? 24 * 60 * 60 * 1000, `.${domain}`);
};

export const  isFileExistDuplicateName=(fileName: string, tempGridData: any): boolean =>{

    var duplicateFound = false;
    var fileCount = 0;
    for (var i = 0; i < tempGridData.length; i++) {
        if (tempGridData[i].name == fileName) {
            fileCount++;
        }
    }
    if (fileCount > 1) {
        duplicateFound = true;
    }
    return duplicateFound;
}