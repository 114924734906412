export const PdfProperties = {
    PageHeight: 792,
    PageWidth: 612,
    DefaultScale: 1
}

export const AdditionalQuestion = {
    HeaderHeight: 25,
    FooterHeight: 100
}

export const RadioButtonProperties = {
    Height: 25,
    Width: 30,
    Top: 0,
    Left: 670
}

export const TextBoxProperties = {
    Left: 20,
    Width: 600
}

export const CustomQuestions = {
    BookMark: 'Custom Questions',
    DefaultTop: 800,
    ReducedTop: 745,
    InitialCapacity: 0,
    defaultQuestion: {
        Left: 20,
        Width: 540
    },
    defaultSubQuestion: {
        Left: 40,
        Width: 506
    },
    subQuestionRadio: {
        YesButtonLeft: 643,
        NoButtonLeft: 683
    },
    customQuestion: {
        Left: 40,
        Width: 515
    },
    customSubQuestion: {
        Left: 60,
        Width: 490
    },
    customSubQuestionRadio: {
        YesButtonLeft: 617,
        NoButtonLeft: 657
    },
    Header: {
        Yes: {
            jsPDFleft: 525,
            jsPDFtop: 820,
            width: 20,
            height: 20,
            left:700,
            top:29
        },
        No: {
            jsPDFleft: 556,
            jsPDFtop: 820,
            width: 20,
            height:20,
            left:741,
            top:29
        }
    },
    QuestionStyle: 'font-size:13.333px;font-family:sans-serif',
    YesNoStyle: 'font-size:13.333px;font-family:sans-serif;font-weight:bold',
    SectionStyle: 'font-size:15.333px;font-family:sans-serif;font-weight:bold;color:#0973ba',
    SubQuestionSeries: 'abcdefghijklmnopqrstuvwxyz',
    RequiredPlaceholder:' *Required',
    NextPageTopAdjustment:73.5,
    jsPDFCustomTop:128.5,
    PreviousSectionTopSpace:10,
    PreviousControlTopSpace:21.5
}