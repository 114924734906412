import moment from 'moment';
import { TaxSoftwareType } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IUserModel, IPartnerModel } from '../../../Core/ViewModels/User/UserViewModel';
import { isValidEmailAddress } from './../../../components/helper/Validations';
import { VenusNotifier } from './../../../components/helper/VenusNotifier';
import { DefaultSenderInfoType } from './../../../components/navigation/profile/MySettings';
import {
    Control, ControlType, DataType,
    DateControl, EngagementType, OrganizerClient, OrganizerClientType,
    OrganizerDocumentSettings, OrganizerFilter,
    Signature, Textbox, TextPlacement, ModelTaxSoftwareType, SignatureCheckBoxControl, SignatureRadioButtonControl, ControlRole, BoundingRectangle, OrganizerDocument
} from './../../models/OrganizerComponentModels';
import { HelpTextConstant, ClientInfoTabConstants } from './OrganizerConstants';
import sanitizeHtml from 'sanitize-html';
import { hasRestrictedCharsInClientName } from 'src/components/helper/HelperFunctions';
import { ValidationMessageConstant } from './ValidationConstant';

export const getVariableList = () => {
    interface IVariableList {
        variable: string;
        helpText: string;
    }
    const tempVariableListModel: IVariableList[] = [];
    tempVariableListModel.push(
        { variable: "COMPANYNAME", helpText: "The listed Name of your company on your account" },
        { variable: "TAXPAYERNAME", helpText: "The listed Taxpayer on the Organizer" },
        { variable: "SPOUSENAME", helpText: "The listed Spouse on the Organizer" },
        { variable: "HELPCONTACT", helpText: "The name of the user designated for customer support" },
        { variable: "HELPPHONE", helpText: "The phone number of the user designated for customer support" },
        { variable: "HELPEMAIL", helpText: "The email address of the designated help contact" },
        { variable: "TAXYEAR", helpText: "The Taxyear on the Organizer" },
        { variable: "RECIPIENT", helpText: "The person name for which the email is being sent" },
        { variable: "SENDERNAME", helpText: "The name of the user selected as the 'Sender' of the Organizer" }
    )
    return tempVariableListModel;
}


export const validateLocationData = (organizerDocument: OrganizerDocument): boolean => {
    if (organizerDocument && organizerDocument.locationId <= 0) {

        VenusNotifier.Warning(ClientInfoTabConstants.OrganizerInformation.Warning.LocationRequired, null);

        return false;
    }

    return true;
}

export const validateClientName = (clients: OrganizerClient[]): boolean => {
    if (clients.some(client => hasRestrictedCharsInClientName(client.name))) {
        VenusNotifier.Warning(ValidationMessageConstant.ClientNameSpecialCharError, null);
        return false;
    }
    return true;
};

export const validateEROData = (organizerDocument: OrganizerDocument): boolean => {

    if (organizerDocument && (organizerDocument.ero == undefined || organizerDocument.ero <= 0)) {

        VenusNotifier.Warning(ClientInfoTabConstants.OrganizerInformation.Warning.ERORequired, null);

        return false;
    }

    return true;
}

export const validateOrganizerClient = (client: OrganizerClient): boolean => {
    if (client) {
        const { clientType, email, zip, city, mobileNumber, countryCode, name, isDeceased, signingOrder } = client;
        const clientTypeString: string = OrganizerClientType[clientType].toString();

        if (email && !isValidEmailAddress(email, true)) {
            VenusNotifier.Warning(`Please enter valid ${clientTypeString} Email Id`, null);
            return false;
        }

        if (hasRestrictedCharsInClientName(name)) {
            VenusNotifier.Warning(ValidationMessageConstant.ClientNameSpecialCharError, null);
            return false;
        }

        if (!name) {
            VenusNotifier.Warning(`Please enter ${clientTypeString} Name`, null);
            return false;
        }

        if (mobileNumber && !validatePhone(mobileNumber)) {
            VenusNotifier.Warning(`Please enter valid mobile number for ${clientTypeString}`, null);
            return false;
        }

        if (mobileNumber && !countryCode) {
            VenusNotifier.Warning(`Please select a country code for ${clientTypeString}`, null);
            return false;
        }
        if (!mobileNumber && countryCode) {
            VenusNotifier.Warning(`Please enter valid mobile number for ${clientTypeString}`, null);
            return false;
        }
    }
    return true;
}

export const validatePhone = (phoneNo: string) => {
    if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        return false;
    }
    else
        return true;
}

export function validateFilter(filter: OrganizerFilter): boolean {
    let isValid = false;

    for (const fieldKey of Object.keys(filter.fields)) {
        if (fieldKey !== 'selectedTaxYear' && filter.fields[fieldKey] &&
            filter.fields[fieldKey].toString().length > 0) {
            isValid = true;
        }
    }

    if (filter.searchText.length > 0) {
        isValid = true;
    }

    return isValid;
}

export const getEngagementType = (engagementType: string) => {
    return (engagementType.toString() == EngagementType[EngagementType.None] ? engagementType.toString().toUpperCase() : engagementType.toString().slice(1, 10));
}

export const getQuestionsControl = (): any[] => {
    return [
        {
            caption: 'Add Custom Question',
            icon: 'fas fa-question',
            controlType: ControlType.QuestionControl,
            helpText: HelpTextConstant.CustomQuestion
        }
    ];
}

export const getClientSignatureControls = (addCustomInputControls: boolean): any[] => {
    const controls = [
        {
            caption: 'Add Signature Field',
            icon: 'fas fa-signature',
            controlType: ControlType.Signature,
            dataType: DataType.None,
            helpText: HelpTextConstant.ClientSignature
        },
        {
            caption: 'Add Date Signed Field',
            icon: 'fas fa-calendar-alt',
            controlType: ControlType.Date,
            dataType: DataType.None,
            helpText: HelpTextConstant.SignatureDate
        },
        {
            caption: 'Add Print Name Field',
            icon: 'fas fa-user-circle',
            controlType: ControlType.Textbox,
            dataType: DataType.Name,
            helpText: HelpTextConstant.Name
        },
        {
            caption: 'Add Initials Field',
            icon: 'fas fa-font',
            controlType: ControlType.Textbox,
            dataType: DataType.Initial,
            helpText: HelpTextConstant.Initial
        },
        {
            caption: 'Add E-Mail Address Field',
            icon: 'fas fa-envelope',
            controlType: ControlType.Textbox,
            dataType: DataType.Email,
            helpText: HelpTextConstant.Email
        }
    ];
    if (addCustomInputControls) {
        controls.push({
            caption: 'Add Text Field',
            icon: 'fas fa-text-width',
            controlType: ControlType.Textbox,
            dataType: DataType.Text,
            helpText: HelpTextConstant.Text
        });
        controls.push({
            caption: 'Add Check Box Field',
            icon: 'fas fa-check-square',
            controlType: ControlType.SignatureCheckBoxControl,
            dataType: DataType.None,
            helpText: HelpTextConstant.Checkbox
        });
        controls.push({
            caption: 'Add Radio Button Field',
            icon: 'fas fa-dot-circle',
            controlType: ControlType.SignatureRadioButtonControl,
            dataType: DataType.None,
            helpText: HelpTextConstant.RadioButton
        });
    }
    return controls;
}

export function getControlText(controlType: ControlType, dataType: DataType): string {
    switch (controlType) {
        case ControlType.Date:
            return "Date";
        case ControlType.Signature:
            return "Signature";
        case ControlType.Textbox:
            {
                switch (dataType) {
                    case DataType.Initial:
                        return "Initials";
                    case DataType.Name:
                        return "Name";
                    case DataType.Email:
                        return "Email";
                    case DataType.Text:
                        return "Text";
                    default:
                        return "";
                }
            }
        case ControlType.SignatureCheckBoxControl:
            return "Checkbox";
        case ControlType.SignatureRadioButtonControl:
            return "Radio Button";
        default:
            return "";
    }
}

export function getControlIcon(controlType: ControlType, dataType: DataType): string {
    switch (controlType) {
        case ControlType.Date:
            return "fas fa-calendar-alt";
        case ControlType.Signature:
            return "fas fa-signature";
        case ControlType.Textbox:
            {
                switch (dataType) {
                    case DataType.Email:
                        return "fas fa-envelope";
                    case DataType.Initial:
                        return "fas fa-font";
                    case DataType.Name:
                        return "fas fa-user-circle";
                    case DataType.Text:
                        return "fas fa-text-width";
                    default:
                        return "";
                }
            }
        case ControlType.SignatureCheckBoxControl:
            return "fas fa-check-square";
        case ControlType.SignatureRadioButtonControl:
            return "fas fa-dot-circle";
        default:
            return "";
    }
}

export function getControlIconV1(controlType: ControlType, dataType: DataType): string {
    switch (controlType) {
        case ControlType.Date:
            return "fa-calendar-alt";
        case ControlType.Signature:
            return "fa-signature";
        case ControlType.Textbox:
            {
                switch (dataType) {
                    case DataType.Email:
                        return "fa-envelope";
                    case DataType.Initial:
                        return "fa-font";
                    case DataType.Name:
                        return "fa-user-circle";
                    case DataType.Text:
                        return "fa-text-width";
                    default:
                        return "";
                }
            }
        case ControlType.SignatureCheckBoxControl:
            return "fa-check-square";
        case ControlType.SignatureRadioButtonControl:
            return "fa-dot-circle";
        default:
            return "";
    }
}

export function getControl(controlType: ControlType, dataType: DataType, left: number, top: number): Control {
    switch (controlType) {
        case ControlType.Date:
            return {
                controlType: controlType,
                textPlacement: TextPlacement.Left
            } as DateControl;
        case ControlType.Signature:
            return {
                controlType: controlType
            } as Signature;
        case ControlType.Textbox:
            return {
                controlType: controlType,
                dataType: dataType,
                textPlacement: TextPlacement.Left,
                required: true
            } as Textbox;
        case ControlType.SignatureCheckBoxControl:
            return {
                controlType: controlType,
                dataType: dataType,
                required: true,
                tooltip: '',
                page: 0,
                disabled: false,
                items: [],
                id: '',
                signerId: 0,
                controlRole: ControlRole.None,
                readonly: false,
                boundingRectangle: getDefaultCheckBoxBoundingRectangle(left, top),
                name: '',
                customData: ''
            } as SignatureCheckBoxControl;
        case ControlType.SignatureRadioButtonControl:
            return {
                controlType: controlType,
                dataType: dataType,
                required: true,
                tooltip: '',
                page: 0,
                disabled: false,
                items: [],
                id: '',
                signerId: 0,
                controlRole: ControlRole.None,
                readonly: false,
                boundingRectangle: getDefaultRadioButtonBoundingRectangle(left, top),
                name: '',
                customData: ''
            } as SignatureRadioButtonControl;
        default:
            return {} as Control;
    }
}

export function getDefaultCheckBoxBoundingRectangle(left: number, top: number): BoundingRectangle {
    return {
        left: left,
        top: top,
        width: 200,
        height: 100
    } as BoundingRectangle;
}
export function getDefaultRadioButtonBoundingRectangle(left: number, top: number): BoundingRectangle {
    return {
        left: left,
        top: top,
        width: 200,
        height: 100
    } as BoundingRectangle;
}
export const validateOrganizerDocumentSettings = (settings: OrganizerDocumentSettings, ELEnabled: boolean) => {
    const { deliverySettings: {
        contactPersonId,
        preparerMessage,
        sender
    },
        notificationSettings: {
            notifyELCompletedUser,
            notifyOrganizerCompletedUser,
            notifyUploadedFilesUser
        }
    } = settings;
    if (contactPersonId <= 0) {
        VenusNotifier.Warning(`Please select contact person`, null);
        return false;
    }
    //if (!preparerMessage.body) {
    //    VenusNotifier.Warning(`Please select preparer message`, null);
    //    return false;
    //}
    if (sender.senderId <= 0 && sender.senderType !== DefaultSenderInfoType.Company) {
        VenusNotifier.Warning(`Please select sender name`, null);
        return false;
    }
    if (ELEnabled && notifyELCompletedUser <= 0) {
        VenusNotifier.Warning(`Please select signed document completed notification user`, null);
        return false;
    }
    if (notifyOrganizerCompletedUser <= 0) {
        VenusNotifier.Warning(`Please select Organizer completed notification user`, null);
        return false;
    }
    if (notifyUploadedFilesUser <= 0) {
        VenusNotifier.Warning(`Please select files uploaded notification user`, null);
        return false;
    }
    return true;
}

export const sanitize = (content: string): string => {
    return sanitizeHtml(content, {
        allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'span', 'a', 'ul', 'ol',
            'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
            'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
        allowedAttributes: {
            '*': ['href', 'target', 'style']
        }
    });
}

export const replaceHelperVariable = (message: string) => {
    message = message.replace(/<COMPANYNAME>/g, "&lt;COMPANYNAME&gt;");
    message = message.replace(/<TAXPAYERNAME>/g, "&lt;TAXPAYERNAME&gt;");
    message = message.replace(/<SPOUSENAME>/g, "&lt;SPOUSENAME&gt;");
    message = message.replace(/<PREPARERNAME>/g, "&lt;PREPARERNAME&gt;");
    message = message.replace(/<DUEDATE>/g, "&lt;DUEDATE&gt;");
    message = message.replace(/<TAXYEAR>/g, "&lt;TAXYEAR&gt;");
    message = message.replace(/<NUMBEROFDAYS>/g, "&lt;NUMBEROFDAYS&gt;");
    message = message.replace(/<RECIPIENT>/g, "&lt;RECIPIENT&gt;");
    message = message.replace(/<HELPCONTACT>/g, "&lt;HELPCONTACT&gt;");
    message = message.replace(/<HELPPHONE>/g, "&lt;HELPPHONE&gt;");
    message = message.replace(/<HELPEMAIL>/g, "&lt;HELPEMAIL&gt;");
    message = message.replace(/<SENDERNAME>/g, "&lt;SENDERNAME&gt;");
    message = message.replace(/<ERONAME>/g, "&lt;ERONAME&gt;");
    return message;
}

export function replaceHelperVariableForEditor(message: string) {
    message = message.replace(/<COMPANYNAME>/g, "&lt;COMPANYNAME&gt;");
    message = message.replace(/<TAXPAYERNAME>/g, "&lt;TAXPAYERNAME&gt;");
    message = message.replace(/<SPOUSENAME>/g, "&lt;SPOUSENAME&gt;");
    message = message.replace(/<PREPARERNAME>/g, "&lt;PREPARERNAME&gt;");
    message = message.replace(/<DUEDATE>/g, "&lt;DUEDATE&gt;");
    message = message.replace(/<TAXYEAR>/g, "&lt;TAXYEAR&gt;");
    message = message.replace(/<NUMBEROFDAYS>/g, "&lt;NUMBEROFDAYS&gt;");
    message = message.replace(/<RECIPIENT>/g, "&lt;RECIPIENT&gt;");
    message = message.replace(/<HELPCONTACT>/g, "&lt;HELPCONTACT&gt;");
    message = message.replace(/<HELPPHONE>/g, "&lt;HELPPHONE&gt;");
    message = message.replace(/<HELPEMAIL>/g, "&lt;HELPEMAIL&gt;");
    message = message.replace(/<SENDERNAME>/g, "&lt;SENDERNAME&gt;");
    message = message.replace(/<ERONAME>/g, "&lt;ERONAME&gt;");
    return message;
}

export function decodeText(text: string) {
    return text.replace(/\&nbsp;/g, ' ');
}


//We will use this function for all the years dropdown in organizers,
//till we implement the global settings option.
export function getOrganizersTaxYearList() {
    const minTaxYear = 2020;
    let maxTaxYear = new Date().getFullYear() - 1;
    if (new Date().getMonth() == 11) { // If Current month is Dec then add current year in tax year list
        maxTaxYear++;
    }
    let organizerYears: { value: number; label: string }[] = [];
    for (let i = maxTaxYear; i >= minTaxYear; i--) {
        organizerYears.push({
            value: i,
            label: i.toString()
        })
    }
    return organizerYears;
}

export function getOrganizersCurrentTaxYear() {
    if (new Date().getMonth() == 11) {      // If Current month is Dec then take the current year as tax year
        return new Date().getFullYear();
    } else {                                // If Current month is not Dec then take the previous year as tax year
        return new Date().getFullYear() - 1;
    }
}

export const getUserList = (users: IPartnerModel[]) => {
    return users.map(user => {
        let name = user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName;
        return {
            value: user.id,
            label: name
        }
    })
}

export function formattedClientName(clientName: string) {
    if (clientName == undefined) {
        return "";
    }
    else {
        const nameList = clientName.trim().split(/ +/).reverse();
        if (nameList.length > 2) {
            return nameList[0] + ', ' + nameList.slice(1).reverse().join(' ');
        }
        else {
            return nameList.join(', ');
        }
    }
}
export interface ICountryCode {
    value: string
    label: string
}
export function GetCountryCode() {

    var CountryCodeOption: ICountryCode[] = [];
    CountryCodeOption.push({ value: "+1", label: "USA/Canada (+1)" });
    CountryCodeOption.push({ value: "+213", label: "Algeria (+213)" });
    CountryCodeOption.push({ value: "+376", label: "Andorra (+376)" });
    CountryCodeOption.push({ value: "+244", label: "Angola (+244)" });
    CountryCodeOption.push({ value: "+1264", label: "Anguilla (+1264)" });
    CountryCodeOption.push({ value: "+1268", label: "Antigua Barbuda (+1268)" });
    CountryCodeOption.push({ value: "+54", label: "Argentina (+54)" });
    CountryCodeOption.push({ value: "+374", label: "Armenia (+374)" });
    CountryCodeOption.push({ value: "+297", label: "Aruba (+297)" });
    CountryCodeOption.push({ value: "+61", label: "Australia (+61)" });
    CountryCodeOption.push({ value: "+43", label: "Austria (+43)" });
    CountryCodeOption.push({ value: "+994", label: "Azerbaijan (+994)" });
    CountryCodeOption.push({ value: "+1242", label: "Bahamas (+1242)" });
    CountryCodeOption.push({ value: "+973", label: "Bahrain (+973)" });
    CountryCodeOption.push({ value: "+880", label: "Bangladesh (+880)" });
    CountryCodeOption.push({ value: "+1246", label: "Barbados (+1246)" });
    CountryCodeOption.push({ value: "+375", label: "Belarus (+375)" });
    CountryCodeOption.push({ value: "+32", label: "Belgium (+32)" });
    CountryCodeOption.push({ value: "+501", label: "Belize (+501)" });
    CountryCodeOption.push({ value: "+229", label: "Benin (+229)" });
    CountryCodeOption.push({ value: "+1441", label: "Bermuda (+1441)" });
    CountryCodeOption.push({ value: "+975", label: "Bhutan (+975)" });
    CountryCodeOption.push({ value: "+591", label: "Bolivia (+591)" });
    CountryCodeOption.push({ value: "+387", label: "Bosnia Herzegovina (+387)" });
    CountryCodeOption.push({ value: "+267", label: "Botswana (+267)" });
    CountryCodeOption.push({ value: "+55", label: "Brazil (+55)" });
    CountryCodeOption.push({ value: "+673", label: "Brunei (+673)" });
    CountryCodeOption.push({ value: "+359", label: "Bulgaria (+359)" });
    CountryCodeOption.push({ value: "+226", label: "Burkina Faso (+226)" });
    CountryCodeOption.push({ value: "+257", label: "Burundi (+257)" });
    CountryCodeOption.push({ value: "+855", label: "Cambodia (+855)" });
    CountryCodeOption.push({ value: "+237", label: "Cameroon (+237)" });
    CountryCodeOption.push({ value: "+238", label: "Cape Verde Islands (+238)" });
    CountryCodeOption.push({ value: "+1345", label: "Cayman Islands (+1345)" });
    CountryCodeOption.push({ value: "+236", label: "Central African Republic (+236)" });
    CountryCodeOption.push({ value: "+56", label: "Chile (+56)" });
    CountryCodeOption.push({ value: "+86", label: "China (+86)" });
    CountryCodeOption.push({ value: "+57", label: "Colombia (+57)" });
    CountryCodeOption.push({ value: "+242", label: "Congo (+242)" });
    CountryCodeOption.push({ value: "+682", label: "Cook Islands (+682)" });
    CountryCodeOption.push({ value: "+506", label: "Costa Rica (+506)" });
    CountryCodeOption.push({ value: "+385", label: "Croatia (+385)" });
    CountryCodeOption.push({ value: "+53", label: "Cuba (+53)" });
    CountryCodeOption.push({ value: "+90392", label: "Cyprus North (+90392)" });
    CountryCodeOption.push({ value: "+357", label: "Cyprus South (+357)" });
    CountryCodeOption.push({ value: "+42", label: "Czech Republic (+42)" });
    CountryCodeOption.push({ value: "+45", label: "Denmark (+45)" });
    CountryCodeOption.push({ value: "+253", label: "Djibouti (+253)" });
    CountryCodeOption.push({ value: "+1809", label: "Dominican Republic (+1809)" });
    CountryCodeOption.push({ value: "+593", label: "Ecuador (+593)" });
    CountryCodeOption.push({ value: "+20", label: "Egypt (+20)" });
    CountryCodeOption.push({ value: "+503", label: "El Salvador (+503)" });
    CountryCodeOption.push({ value: "+240", label: "Equatorial Guinea (+240)" });
    CountryCodeOption.push({ value: "+291", label: "Eritrea (+291)" });
    CountryCodeOption.push({ value: "+372", label: "Estonia (+372)" });
    CountryCodeOption.push({ value: "+251", label: "Ethiopia (+251)" });
    CountryCodeOption.push({ value: "+500", label: "Falkland Islands (+500)" });
    CountryCodeOption.push({ value: "+298", label: "Faroe Islands (+298)" });
    CountryCodeOption.push({ value: "+679", label: "Fiji (+679)" });
    CountryCodeOption.push({ value: "+358", label: "Finland (+358)" });
    CountryCodeOption.push({ value: "+33", label: "France (+33)" });
    CountryCodeOption.push({ value: "+594", label: "French Guiana (+594)" });
    CountryCodeOption.push({ value: "+689", label: "French Polynesia (+689)" });
    CountryCodeOption.push({ value: "+241", label: "Gabon (+241)" });
    CountryCodeOption.push({ value: "+220", label: "Gambia (+220)" });
    CountryCodeOption.push({ value: "+7880", label: "Georgia (+7880)" });
    CountryCodeOption.push({ value: "+49", label: "Germany (+49)" });
    CountryCodeOption.push({ value: "+233", label: "Ghana (+233)" });
    CountryCodeOption.push({ value: "+350", label: "Gibraltar (+350)" });
    CountryCodeOption.push({ value: "+30", label: "Greece (+30)" });
    CountryCodeOption.push({ value: "+299", label: "Greenland (+299)" });
    CountryCodeOption.push({ value: "+1473", label: "Grenada (+1473)" });
    CountryCodeOption.push({ value: "+590", label: "Guadeloupe (+590)" });
    CountryCodeOption.push({ value: "+671", label: "Guam (+671)" });
    CountryCodeOption.push({ value: "+502", label: "Guatemala (+502)" });
    CountryCodeOption.push({ value: "+224", label: "Guinea (+224)" });
    CountryCodeOption.push({ value: "+245", label: "Guinea - Bissau (+245)" });
    CountryCodeOption.push({ value: "+592", label: "Guyana (+592)" });
    CountryCodeOption.push({ value: "+509", label: "Haiti (+509)" });
    CountryCodeOption.push({ value: "+504", label: "Honduras (+504)" });
    CountryCodeOption.push({ value: "+852", label: "Hong Kong (+852)" });
    CountryCodeOption.push({ value: "+36", label: "Hungary (+36)" });
    CountryCodeOption.push({ value: "+354", label: "Iceland (+354)" });
    CountryCodeOption.push({ value: "+91", label: "India (+91)" });
    CountryCodeOption.push({ value: "+62", label: "Indonesia (+62)" });
    CountryCodeOption.push({ value: "+98", label: "Iran (+98)" });
    CountryCodeOption.push({ value: "+964", label: "Iraq (+964)" });
    CountryCodeOption.push({ value: "+353", label: "Ireland (+353)" });
    CountryCodeOption.push({ value: "+972", label: "Israel (+972)" });
    CountryCodeOption.push({ value: "+39", label: "Italy (+39)" });
    CountryCodeOption.push({ value: "+1876", label: "Jamaica (+1876)" });
    CountryCodeOption.push({ value: "+81", label: "Japan (+81)" });
    CountryCodeOption.push({ value: "+962", label: "Jordan (+962)" });
    CountryCodeOption.push({ value: "+254", label: "Kenya (+254)" });
    CountryCodeOption.push({ value: "+686", label: "Kiribati (+686)" });
    CountryCodeOption.push({ value: "+850", label: "Korea North (+850)" });
    CountryCodeOption.push({ value: "+82", label: "Korea South (+82)" });
    CountryCodeOption.push({ value: "+965", label: "Kuwait (+965)" });
    CountryCodeOption.push({ value: "+996", label: "Kyrgyzstan (+996)" });
    CountryCodeOption.push({ value: "+856", label: "Laos (+856)" });
    CountryCodeOption.push({ value: "+371", label: "Latvia (+371)" });
    CountryCodeOption.push({ value: "+961", label: "Lebanon (+961)" });
    CountryCodeOption.push({ value: "+266", label: "Lesotho (+266)" });
    CountryCodeOption.push({ value: "+231", label: "Liberia (+231)" });
    CountryCodeOption.push({ value: "+218", label: "Libya (+218)" });
    CountryCodeOption.push({ value: "+417", label: "Liechtenstein (+417)" });
    CountryCodeOption.push({ value: "+370", label: "Lithuania (+370)" });
    CountryCodeOption.push({ value: "+352", label: "Luxembourg (+352)" });
    CountryCodeOption.push({ value: "+853", label: "Macao (+853)" });
    CountryCodeOption.push({ value: "+389", label: "Macedonia (+389)" });
    CountryCodeOption.push({ value: "+261", label: "Madagascar (+261)" });
    CountryCodeOption.push({ value: "+265", label: "Malawi (+265)" });
    CountryCodeOption.push({ value: "+60", label: "Malaysia (+60)" });
    CountryCodeOption.push({ value: "+960", label: "Maldives (+960)" });
    CountryCodeOption.push({ value: "+223", label: "Mali (+223)" });
    CountryCodeOption.push({ value: "+356", label: "Malta (+356)" });
    CountryCodeOption.push({ value: "+692", label: "Marshall Islands (+692)" });
    CountryCodeOption.push({ value: "+596", label: "Martinique (+596)" });
    CountryCodeOption.push({ value: "+222", label: "Mauritania (+222)" });
    CountryCodeOption.push({ value: "+269", label: "Mayotte (+269)" });
    CountryCodeOption.push({ value: "+52", label: "Mexico (+52)" });
    CountryCodeOption.push({ value: "+691", label: "Micronesia (+691)" });
    CountryCodeOption.push({ value: "+373", label: "Moldova (+373)" });
    CountryCodeOption.push({ value: "+377", label: "Monaco (+377)" });
    CountryCodeOption.push({ value: "+976", label: "Mongolia (+976)" });
    CountryCodeOption.push({ value: "+1664", label: "Montserrat (+1664)" });
    CountryCodeOption.push({ value: "+212", label: "Morocco (+212)" });
    CountryCodeOption.push({ value: "+258", label: "Mozambique (+258)" });
    CountryCodeOption.push({ value: "+95", label: "Myanmar (+95)" });
    CountryCodeOption.push({ value: "+264", label: "Namibia (+264)" });
    CountryCodeOption.push({ value: "+674", label: "Nauru (+674)" });
    CountryCodeOption.push({ value: "+977", label: "Nepal (+977)" });
    CountryCodeOption.push({ value: "+31", label: "Netherlands (+31)" });
    CountryCodeOption.push({ value: "+687", label: "New Caledonia (+687)" });
    CountryCodeOption.push({ value: "+64", label: "New Zealand (+64)" });
    CountryCodeOption.push({ value: "+505", label: "Nicaragua (+505)" });
    CountryCodeOption.push({ value: "+227", label: "Niger (+227)" });
    CountryCodeOption.push({ value: "+234", label: "Nigeria (+234)" });
    CountryCodeOption.push({ value: "+683", label: "Niue (+683)" });
    CountryCodeOption.push({ value: "+672", label: "Norfolk Islands (+672)" });
    CountryCodeOption.push({ value: "+670", label: "Northern Marianas (+670)" });
    CountryCodeOption.push({ value: "+47", label: "Norway (+47)" });
    CountryCodeOption.push({ value: "+968", label: "Oman (+968)" });
    CountryCodeOption.push({ value: "+680", label: "Palau (+680)" });
    CountryCodeOption.push({ value: "+507", label: "Panama (+507)" });
    CountryCodeOption.push({ value: "+675", label: "Papua New Guinea (+675)" });
    CountryCodeOption.push({ value: "+595", label: "Paraguay (+595)" });
    CountryCodeOption.push({ value: "+51", label: "Peru (+51)" });
    CountryCodeOption.push({ value: "+63", label: "Philippines (+63)" });
    CountryCodeOption.push({ value: "+48", label: "Poland (+48)" });
    CountryCodeOption.push({ value: "+351", label: "Portugal (+351)" });
    CountryCodeOption.push({ value: "+1787", label: "Puerto Rico (+1787)" });
    CountryCodeOption.push({ value: "+974", label: "Qatar (+974)" });
    CountryCodeOption.push({ value: "+262", label: "Reunion (+262)" });
    CountryCodeOption.push({ value: "+40", label: "Romania (+40)" });
    CountryCodeOption.push({ value: "+7", label: "Russia (+7)" });
    CountryCodeOption.push({ value: "+250", label: "Rwanda (+250)" });
    CountryCodeOption.push({ value: "+378", label: "San Marino (+378)" });
    CountryCodeOption.push({ value: "+239", label: "Sao Tome Principe (+239)" });
    CountryCodeOption.push({ value: "+966", label: "Saudi Arabia (+966)" });
    CountryCodeOption.push({ value: "+221", label: "Senegal (+221)" });
    CountryCodeOption.push({ value: "+381", label: "Serbia (+381)" });
    CountryCodeOption.push({ value: "+248", label: "Seychelles (+248)" });
    CountryCodeOption.push({ value: "+232", label: "Sierra Leone (+232)" });
    CountryCodeOption.push({ value: "+65", label: "Singapore (+65)" });
    CountryCodeOption.push({ value: "+421", label: "Slovak Republic (+421)" });
    CountryCodeOption.push({ value: "+386", label: "Slovenia (+386)" });
    CountryCodeOption.push({ value: "+677", label: "Solomon Islands (+677)" });
    CountryCodeOption.push({ value: "+252", label: "Somalia (+252)" });
    CountryCodeOption.push({ value: "+27", label: "South Africa (+27)" });
    CountryCodeOption.push({ value: "+34", label: "Spain (+34)" });
    CountryCodeOption.push({ value: "+94", label: "Sri Lanka (+94)" });
    CountryCodeOption.push({ value: "+290", label: "St. Helena (+290)" });
    CountryCodeOption.push({ value: "+1869", label: "St. Kitts (+1869)" });
    CountryCodeOption.push({ value: "+1758", label: "St. Lucia (+1758)" });
    CountryCodeOption.push({ value: "+249", label: "Sudan (+249)" });
    CountryCodeOption.push({ value: "+597", label: "Suriname (+597)" });
    CountryCodeOption.push({ value: "+268", label: "Swaziland (+268)" });
    CountryCodeOption.push({ value: "+46", label: "Sweden (+46)" });
    CountryCodeOption.push({ value: "+41", label: "Switzerland (+41)" });
    CountryCodeOption.push({ value: "+963", label: "Syria (+963)" });
    CountryCodeOption.push({ value: "+886", label: "Taiwan (+886)" });
    CountryCodeOption.push({ value: "+66", label: "Thailand (+66)" });
    CountryCodeOption.push({ value: "+228", label: "Togo (+228)" });
    CountryCodeOption.push({ value: "+676", label: "Tonga (+676)" });
    CountryCodeOption.push({ value: "+1868", label: "Trinidad Tobago (+1868)" });
    CountryCodeOption.push({ value: "+216", label: "Tunisia (+216)" });
    CountryCodeOption.push({ value: "+90", label: "Turkey (+90)" });
    CountryCodeOption.push({ value: "+993", label: "Turkmenistan (+993)" });
    CountryCodeOption.push({ value: "+1649", label: "Turks Caicos Islands (+1649)" });
    CountryCodeOption.push({ value: "+688", label: "Tuvalu (+688)" });
    CountryCodeOption.push({ value: "+256", label: "Uganda (+256)" });
    CountryCodeOption.push({ value: "+44", label: "UK (+44)" });
    CountryCodeOption.push({ value: "+380", label: "Ukraine (+380)" });
    CountryCodeOption.push({ value: "+971", label: "United Arab Emirates (+971)" });
    CountryCodeOption.push({ value: "+598", label: "Uruguay (+598)" });
    CountryCodeOption.push({ value: "+678", label: "Vanuatu (+678)" });
    CountryCodeOption.push({ value: "+379", label: "Vatican City (+379)" });
    CountryCodeOption.push({ value: "+58", label: "Venezuela (+58)" });
    CountryCodeOption.push({ value: "+84", label: "Virgin Islands" });
    CountryCodeOption.push({ value: "+681", label: "Wallis Futuna (+681)" });
    CountryCodeOption.push({ value: "+969", label: "Yemen North (+969)" });
    CountryCodeOption.push({ value: "+967", label: "Yemen South (+967)" });
    CountryCodeOption.push({ value: "+260", label: "Zambia (+260)" });
    CountryCodeOption.push({ value: "+263", label: "Zimbabwe (+263)" });
    return CountryCodeOption;
}

export function getTaxSoftwareType(taxSoftware: ModelTaxSoftwareType) {

    const taxSoftware_num: number =
        typeof taxSoftware === 'string'
            ? (ModelTaxSoftwareType as any)[taxSoftware as keyof typeof ModelTaxSoftwareType]
            : taxSoftware;

    switch (taxSoftware_num) {
        case ModelTaxSoftwareType.GoSystem:
            return TaxSoftwareType.GoSystem;
        case ModelTaxSoftwareType.Lacerte:
            return TaxSoftwareType.Lacerte;
        case ModelTaxSoftwareType.UltraTax:
            return TaxSoftwareType.UltraTax;
        case ModelTaxSoftwareType.None:
            return TaxSoftwareType.None;
        default:
        case ModelTaxSoftwareType.ProSystems:
            return TaxSoftwareType.ProSystems;

    }
}
const TopAdjustmentForSignatureControl = 12;
export function getTopAdjustmentSignatureControlValue(controlType: ControlType): number {
    switch (controlType) {
        case ControlType.SignatureCheckBoxControl:
        case ControlType.SignatureRadioButtonControl:
            return 0;
        default:
            return TopAdjustmentForSignatureControl;
    }
}
export function isExceedingOneDay(date: Date): boolean {
    const now = moment(new Date(), 'YYYY/MM/DD HH:mm');
    const hours: number = moment.duration(now.diff(moment(date, 'YYYY/MM/DD HH:mm'))).asHours();
    return (hours > 24);
}
