import * as React from 'react';
import {
    Row, FormControl, Col,
    FormLabel, Modal, Button, Card, NavLink
} from 'react-bootstrap';
import { BatchColumn, BatchCSVData, BatchValidationStatus } from './../../../models/OrganizerComponentModels';
import Select from 'react-select';
import { VenusNotifier } from '../../../../components/helper/VenusNotifier';
import { GetCountryCode, validatePhone } from './../../Helper/OrganizerHelperFunctions';
import { isValidEmailAddress } from '../../../../components/helper/Validations';
import { hasRestrictedCharsInClientName } from 'src/components/helper/HelperFunctions';
import { ValidationMessageConstant } from '../../Helper/ValidationConstant';

interface BatchCSVDataModalProps {
    data: BatchCSVData;
    onClose: () => void;
    show: boolean;
    onSave: (data: BatchCSVData) => void;
    onUpdate: (data: BatchCSVData) => void;
}

const BatchCSVDataModal: React.StatelessComponent<BatchCSVDataModalProps> = ({ show, onClose, data, onSave, onUpdate }) => {


    const spouseInfoExists = data.spouseFirstName || data.spouseLastName || data.spouseMobileNo || data.spouseCountryCode || data.spouseEmail ? true : false;

    let noFile, tFName, tLName, tEmail, tMobile, sFName, sEmail, sMobile, sLName, tCountryCode, sCountryCode;
    noFile = tFName = tEmail = tMobile = sFName = sEmail = sMobile = false;

    data.validationResult.validationMessages.forEach(message => {
        switch (message.field) {
            case BatchColumn.DocumentGuid:
                noFile = true;
                break;
            case BatchColumn.TaxpayerFirstName:
                tFName = true;
                break;
            case BatchColumn.TaxpayerLastName:
                tLName = true;
                break;
            case BatchColumn.TaxpayerEmail:
                tEmail = true;
                break;
            case BatchColumn.TaxpayerMobileNo:
                tMobile = true;
                break;
            case BatchColumn.SpouseFirstName:
                sFName = true;
                break;
            case BatchColumn.SpouseLastName:
                sLName = true;
                break;
            case BatchColumn.SpouseEmail:
                sEmail = true;
                break;
            case BatchColumn.SpouseMobileNo:
                sMobile = true;
                break;
            case BatchColumn.TaxpayerCountryCode:
                tCountryCode = true;
                break;
            case BatchColumn.SpouseCountryCode:
                sCountryCode = true;
                break;
        }
    })

    const validate = () => {
        if (spouseInfoExists) {
            if (hasRestrictedCharsInClientName(data.spouseFirstName) || hasRestrictedCharsInClientName(data.spouseLastName)) {
                VenusNotifier.Warning(ValidationMessageConstant.ClientNameSpecialCharError, null);
                return;
            }
            if (!data.spouseFirstName) {
                VenusNotifier.Warning("Enter Spouse First Name", null);
                return;
            }
            if (data.spouseMobileNo && !validatePhone(data.spouseMobileNo)) {
                VenusNotifier.Warning("Enter valid Mobile number for Spouse", null);
                return;
            }
            if (data.spouseEmail && !isValidEmailAddress(data.spouseEmail, true)) {
                VenusNotifier.Warning("Enter valid Email for Spouse", null);
                return;
            }
        }
        if (hasRestrictedCharsInClientName(data.taxpayerFirstName) || hasRestrictedCharsInClientName(data.taxpayerLastName)) {
            VenusNotifier.Warning(ValidationMessageConstant.ClientNameSpecialCharError, null);
            return;
        }

        if (!data.taxpayerFirstName) {
            VenusNotifier.Warning("Enter Taxpayer First Name", null);
            return;
        }
        if (!data.taxpayerLastName) {
            VenusNotifier.Warning("Enter Taxpayer Last Name", null);
            return;
        }
        if (data.taxpayerMobileNo && !validatePhone(data.taxpayerMobileNo)) {
            VenusNotifier.Warning("Enter valid Mobile number for Taxpayer", null);
            return;
        }
        if (!data.taxpayerEmail || !isValidEmailAddress(data.taxpayerEmail, true)) {
            VenusNotifier.Warning("Enter valid Email for Taxpayer", null);
            return;
        }
        let engagementLetterError = data.validationResult.validationMessages.find(m => m.field === BatchColumn.HasEngagementLetter);
        data.modified = true;
        data.validationResult.validationMessages = [];
        if (!engagementLetterError) {
            data.validationResult.status = BatchValidationStatus.Success;
        }
        else {
            data.validationResult.status = BatchValidationStatus.Warning;
            data.validationResult.validationMessages.push(engagementLetterError);
        }
        onSave(data);
    }


    const fieldChange = (e: any) => {
        data.modified = true;
        (data as any)[e.target.name] = e.target.value;
        onUpdate(data);
    }

    const onChangeTaxpayerMobileNumber = (value: any) => {
        data.modified = true;
        data.taxpayerMobileNo = value;
        onUpdate(data);
    }

    const onChangeTaxpayerCountryCode = (e: any) => {
        data.modified = true;
        data.taxpayerCountryCode = e ? e.value : "";
        onUpdate(data);
    }

    const onChangeSpouseMobileNumber = (value: any) => {
        data.modified = true;
        data.spouseMobileNo = value;
        onUpdate(data);
    }

    const onChangeSpouseCountryCode = (e: any) => {
        data.modified = true;
        data.spouseCountryCode = e ? e.value : "";
        onUpdate(data);
    }

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-address-card'>
                    </span>Edit Client Info
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    noFile ?
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4"><strong style={{ color: 'red' }}>This client does not have a corresponding file in the zip</strong></Card.Title>
                            </Card.Header>
                        </Card> : ""
                }
                <Card>
                    <Card.Header>
                        <Card.Title as="h4"><strong>Taxpayer</strong></Card.Title>
                    </Card.Header>
                </Card>
                <div style={{ margin: '20px' }}>
                    <Row>
                        <Col className="text-left padT07" sm={3} as={FormLabel}>
                            Taxpayer First Name
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                value={data.taxpayerFirstName}
                                placeholder="Taxpayer First Name"
                                style={{ marginBottom: '10px' }}
                                onChange={fieldChange}
                                name="taxpayerFirstName"
                                disabled={!tFName}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-left padT07" sm={3} as={FormLabel}>
                            Taxpayer Last Name
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                value={data.taxpayerLastName}
                                placeholder="Taxpayer Last Name"
                                style={{ marginBottom: '10px' }}
                                onChange={fieldChange}
                                name="taxpayerLastName"
                                disabled={!tLName}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-left padT07" sm={3} as={FormLabel}>
                            Taxpayer Email
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                value={data.taxpayerEmail}
                                placeholder="Taxpayer Email"
                                style={{ marginBottom: '10px' }}
                                onChange={fieldChange}
                                name="taxpayerEmail"
                                disabled={!tEmail}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-left padT07" sm={3} as={FormLabel}>
                            Country Code </Col>
                        <Col sm={9}>
                            <Select
                                name="taxpayerCountryCode"
                                value={data.taxpayerCountryCode}
                                onChange={onChangeTaxpayerCountryCode}
                                options={GetCountryCode()}
                                style={{ marginBottom: '10px' }}
                                searchable={true}
                                disabled={!tCountryCode}
                            >
                            </Select>
                        </Col>
                        <Col className="text-left" sm={3} as={FormLabel}>
                            Taxpayer Mobile Phone
                            <NavLink href="javascript:" className="help-icon" style={{ verticalAlign: "middle", marginTop: '-5px', display: 'inline' }}
                                data-toggle="tooltip" data-placement="left" title="Should contain only digits">
                                <i className="glyphicon glyphicon-question-sign" style={{ color: '#ffdb6f' }}></i>
                            </NavLink>
                        </Col>
                        <Col sm={9} className={"zeroborderRadius"}>
                            <FormControl
                                type="text"
                                value={data.taxpayerMobileNo}
                                placeholder="Taxpayer Mobile Phone"
                                style={{ marginBottom: '10px' }}
                                onChange={fieldChange}
                                name="taxpayerMobileNo"
                                disabled={!tMobile}
                            />
                        </Col>
                    </Row>

                </div>
                {
                    spouseInfoExists ?
                        <>
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h4"><strong>Spouse</strong></Card.Title>
                                </Card.Header>
                            </Card>
                            <div style={{ margin: '20px' }}>
                                <Row>
                                    <Col className="text-left padT07" sm={3} as={FormLabel}>
                                        Spouse First Name
                                    </Col>
                                    <Col sm={9}>
                                        <FormControl
                                            type="text"
                                            value={data.spouseFirstName}
                                            placeholder="Spouse First Name"
                                            style={{ marginBottom: '10px' }}
                                            onChange={fieldChange}
                                            name="spouseFirstName"
                                            disabled={!sFName}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="text-left padT07" sm={3} as={FormLabel}>
                                        Spouse Last Name
                                    </Col>
                                    <Col sm={9}>
                                        <FormControl
                                            type="text"
                                            value={data.spouseLastName}
                                            placeholder="Spouse Last Name"
                                            style={{ marginBottom: '10px' }}
                                            onChange={fieldChange}
                                            name="spouseLastName"
                                            disabled={!sLName}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="text-left padT07" sm={3} as={FormLabel}>
                                        Spouse Email
                                    </Col>
                                    <Col sm={9}>
                                        <FormControl
                                            type="text"
                                            value={data.spouseEmail}
                                            placeholder="Spouse Email"
                                            style={{ marginBottom: '10px' }}
                                            onChange={fieldChange}
                                            name="spouseEmail"
                                            disabled={!sEmail}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="text-left padT07" sm={3} as={FormLabel}>
                                        Country Code </Col>
                                    <Col sm={9}>
                                        <Select
                                            name="spouseCountryCode"
                                            value={data.spouseCountryCode}
                                            onChange={onChangeSpouseCountryCode}
                                            options={GetCountryCode()}
                                            style={{ marginBottom: '10px' }}
                                            clearable={true}
                                            disabled={!sCountryCode}
                                        >
                                        </Select>
                                    </Col>
                                    <Col className="text-left" sm={3} as={FormLabel}>
                                        Spouse Mobile Phone
                                        <NavLink href="javascript:" className="help-icon" style={{ verticalAlign: "middle", marginTop: '-5px', display: 'inline' }}
                                            data-toggle="tooltip" data-placement="left" title="Should contain only digits">
                                            <i className="glyphicon glyphicon-question-sign" style={{ color: '#ffdb6f' }}></i>
                                        </NavLink>
                                    </Col>
                                    <Col sm={9} className={"zeroborderRadius"}>
                                        <FormControl
                                            type="text"
                                            value={data.spouseMobileNo}
                                            placeholder="Spouse Mobile Phone"
                                            style={{ marginBottom: '10px' }}
                                            onChange={fieldChange}
                                            name="spouseMobileNo"
                                            disabled={!sMobile}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </>
                        : ""
                }
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    onClick={onClose} >
                    <i className="glyphicon glyphicon-remove" />Cancel
                </Button>
                <Button
                    variant='info'
                    onClick={validate}
                    disabled={noFile}
                >
                    <i className='glyphicon glyphicon-floppy-disk'></i>Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export { BatchCSVDataModal };