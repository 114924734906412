export function encode(value: string): string
{
    let newlyEncodedString = "";
    for (let i = 0; i < value.length; i++) {
        if(value.charCodeAt(i)>31 && value.charCodeAt(i)<127){
            newlyEncodedString += "{STARTASCII}" + value.charCodeAt(i) + "{ENDASCII}";
        } else {
            newlyEncodedString += value[i];
        }
    }
    return newlyEncodedString;
}

export function encodeSpecialCharactersOnly(value: string): string
{
    let specialCharactersCodes=[33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 58, 59, 60, 61, 62, 63, 64, 91, 92, 93, 94, 95, 96, 123, 124, 125, 126];
    let newlyEncodedString = "";
    for (let i = 0; i < value.length; i++) {
        if(value.charCodeAt(i)>31 && value.charCodeAt(i)<127 && specialCharactersCodes.includes(value.charCodeAt(i))){
            newlyEncodedString += "{STARTASCII}" + value.charCodeAt(i) + "{ENDASCII}";
        } else {
            newlyEncodedString += value[i];
        }
    }
    return newlyEncodedString;
}
//////////////////////////////////////////////// ENCODE ENDS ////////////////////////////////////////////////////////////////////


