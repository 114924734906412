import { VenusNotifier } from './VenusNotifier';
import {
    ValidationContants, FinishProcessReturn,
    FileConstants, FileSizeExceedsLimit,
} from './Constants'
import { API_BASE_URL } from '../../utils/constants';
import {  IPasswordSettings, PasswordOptions } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { FileModel } from '../../Core/ViewModels/Common/FileModel';
import { ITaxReturn, ITaxingAuthority } from '../common/TaxReturn';
import './StringExtentions';
interface IValidationResult {
    isError: boolean;
    errorDescription: string;
}
export function isValidEmailAddress(emailAddress: string, trim: boolean = false) {
    let email = emailAddress;
    if (trim) {
        email = email.trimLeft();
        email = email.trimRight();
    }
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    return pattern.test(email);
};

export function validateExtension(ExtensionId: any) {
    var Extension = ExtensionId.trim();
    if (Extension.trim().length > 6 || /^[0-9]{1,10}$/.test(Extension) == false) {
        VenusNotifier.Warning(ValidationContants.ExtensionWarning, "");
        return false;
    }
    else
        return true;
}
export function validateFax(FaxId: any) {
    var Fax = FaxId.trim();
    if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
        VenusNotifier.Warning(ValidationContants.FaxWarning, "");
        return false;
    }
    else
        return true;
}
export function validatePhone(phoneNoId: any) {
    var phoneNo = phoneNoId.trim();
    if (phoneNo.trim() == "") {
        VenusNotifier.Warning(ValidationContants.PhoneNumberWarning, "");
        return false;
    }
    else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
        return false;
    }
    else
        return true;
}
export function validatePassword(passwordId: any) {
    var password = passwordId.trim();
    if (password.trim().length < 8) {
        VenusNotifier.Warning(ValidationContants.PassLengthWarning, "");
        return false;
    }
    else if (password.trim().indexOf(' ') >= 0) {
        VenusNotifier.Warning(ValidationContants.PasswordWithoutSpaceWarning, "");
        return false;
    }
}

export function validatePasswordBasedOnPolicy(password: any, passwordSettings: IPasswordSettings): IValidationResult {
    var password = password.trim();
    const passwordOptions = passwordSettings.passwordOptions;
    if (password.trim().length < passwordSettings.length) {
        return {
            isError: true,
            errorDescription: 'Password must be minimum ' + passwordSettings.length + ' characters.'
        };
    }
    else if (password.trim().indexOf(' ') >= 0) {
        return {
            isError: true,
            errorDescription: ValidationContants.PasswordWithoutSpaceWarning
        };
    }
    else if ((passwordOptions & PasswordOptions.UpperCase) === PasswordOptions.UpperCase && !hasUpperCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.UpperCaseValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.LowerCase) === PasswordOptions.LowerCase && !hasLowerCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.LowerCaseValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.Numbers) === PasswordOptions.Numbers && !hasNumber(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.NumbersValidationError
        };
    }
    else if ((passwordOptions & PasswordOptions.SpecialCharacters) === PasswordOptions.SpecialCharacters && !hasSpecialCharacter(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.SpecialCharactersValidationError
        };
    }

    return {
        isError: false,
        errorDescription: "Success"
    };
}

function hasLowerCase(data: string) {
    var pattern = new RegExp('.*[a-z].*');
    return pattern.test(data);
}

function hasUpperCase(data: string) {
    var pattern = new RegExp('.*[A-Z].*');
    return pattern.test(data);
}

function hasNumber(data: string) {
    var pattern = new RegExp(/[\d]/i);
    return pattern.test(data);
}

function hasSpecialCharacter(data: string) {
    var pattern = new RegExp(/[~!@#$%^&*_]/i);
    return pattern.test(data);
}

export function getFileExtension(fileName: string) {
    return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
}


export function validateFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf") {
        VenusNotifier.Warning(ValidationContants.PdfFileValidation, "")
        return false;
    }
    return true;
}

export function validateZipFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "zip") {
        VenusNotifier.Warning(ValidationContants.ZipFileValidation, "")
        return false;
    }
    return true;
}

export function validateExcelFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "xlsx" && fileExtension.toLowerCase() !== "csv") {
        VenusNotifier.Warning(ValidationContants.ExcelFileValidation, "")
        return false;
    }
    return true;
}

export function validateFileSize(file: any): boolean {
    if (file.size > FileConstants.MaximumFileSize) {
        VenusNotifier.Error(FileSizeExceedsLimit(file.name), null);
        return false;
    }
    return true;
}

export function validateFileNameCharacterLimit(file: any): boolean {
    return !(file.name.trim().length > FileConstants.MaximumFileNameCharacter);
}

export function isValidSSN(ssn: string) {
    if (ssn.replace(/-/g, '').length == 9) { return true; }
};

export function isValidEIN(ein: string) {
    if (ein.replace(/-/g, '').length === 9) { return true; }
}


export function NullandEmptyCheck(text: string) {
    if (text == undefined || text == null || text.trim() == "") {
        return false;
    }
    return true;
}

export function validatePhoneLength(phoneNoId: any) {
    var phoneNo = phoneNoId.trim();
    if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        return false;
    }
    else
        return true;
}

export function validatePdfFileContent(signature: any) {
    if (signature === '25504446') { // 'application/pdf'
        return true;
    }
    return false;
}

export function validateAdditionalEsignUploadFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf" && fileExtension.toLowerCase() !== "doc" && fileExtension.toLowerCase() !== "docx") {
        VenusNotifier.Warning(ValidationContants.UploadAdditionalSignFileWarning, "")
        return false;
    }
    return true;
}


export function validateWordAndPDFFileContent(signature: any) {
    if (signature === '25504446') { // 'application/pdf'
        return true;
    }
    else if (signature === 'D0CF11E0') {
        return true;
    }
    else if (signature === '504B34') {
        return true;
    }
    return false;
}

export function isWordFile(signature: any) {
    return signature === 'D0CF11E0' || signature === '504B34';
}

export function ValidateTenDigitNumber(event: any) {
    var pattern = new RegExp(/^[0-9]{0,10}$/);
    return pattern.test(event.target.value);
}

export function ValidateTenDigitphoneNumber(value: string) {
    var pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
    return pattern.test(value);
}

export function GetSafeFilename(fileName: string): string {
    var pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
    return fileName.replace(pattern, '_');
}

export function GetDisplayFilename(fileName: string, maxLength: number) : string {
    if (fileName.length < maxLength) {
      return fileName;
    } else {
      const extensionDelimiterIndex = fileName.lastIndexOf('.');
      const middleRemovedName = `${fileName.substring(0, maxLength)}...${fileName.substring(extensionDelimiterIndex - 3)}`
      return middleRemovedName;
    }
}

export const checkIfAnyFileIsEncrypted = async (uploadData: FileModel[]): Promise<FileModel[]> => {
    const result: FileModel[] = [];

    for (let i = 0; i < uploadData.length; i++) {
        const uploadedFile = uploadData[i].file;
        const fileExtension = getFileExtension(uploadedFile.name);
        if(fileExtension === "pdf" || fileExtension === "PDF")
        {
            const formData = new FormData();
            formData.append('file', uploadedFile);

            try {
                const response = await fetch(API_BASE_URL + 'api/File/IsPasswordProtected', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                    },
                    body: formData
                });

                if(response.status != 200) {
                    throw new Error("Error while checking if pdf is password protected or not.");
                }

                const jsonResponse = await response.json();
                if (jsonResponse.encrypted) {
                    const fileName = GetDisplayFilename(uploadData[i].file?.name ?? uploadData[i].name, 45);
                    VenusNotifier.Error(ValidationContants.PdfDocumentPasswordProtectedError.format(fileName), null);
                } else {
                    result.push(uploadData[i]);
                }
            }
            catch (error) {
                throw new Error(error);
            }
        } else {
            result.push(uploadData[i]);
        }
    };

    uploadData = [];
    return result;
}
