import * as React from 'react';

export const defaultTheme = {
    title: {
        userSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        MozUserSelect: 'none',
        OUserSelect: 'none',
        overflow: 'hidden',
        width: '100%',
        height: 25,
    },
    frame: {
        position: 'absolute',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
    },
    transition: 'all 0.25s ease-in-out'
}

function prefixedTransition(transition: any) {
    return transition ? {
        transition: transition,
        WebkitTransition: transition,
        msTransition: transition,
        MozTransition: transition,
        OTransition: transition,
    } : {}
}


interface DnRProps {
    titleBar?: any;
    style?: any;
    contentClassName?: any;
    contentStyle?: any;
    titleStyle?: any;
    theme?: any;
    minWidth?: number;
    minHeight?: number;
    edgeDetectionRange?: number;
    initialWidth: number;
    initialHeight: number;
    initialTop: number;
    initialLeft: number;
    transition?: string;
    animate: boolean;
    onMove?: (event: any) => void;
    onResize?: (event: any) => void;
    onTransform?: (event: any) => void;
    cursorRemap?: (event: any) => void;
    boundary?: any;
    attachedTo?: any;
}


export class DnR extends React.Component<DnRProps, any> {

    private cursorX = 0
    private cursorY = 0
    private clicked: any = {}
    private allowTransition = false
    private frameRect: any = {}
    private prevState: any = {};
    private hitEdges: any = {};

    constructor(props: DnRProps) {
        super(props);

        this.state = {
            cursor: 'auto',
            transition: prefixedTransition('all 0.25s ease-in-out')
        };
    }

    componentDidMount() {
        const {
            initialWidth,
            initialHeight,
            initialTop,
            initialLeft,
            attachedTo = window,
        } = this.props;

        const boundingBox = this.getFrameRect()
        this.frameRect.width = initialWidth || boundingBox.width
        this.frameRect.height = initialHeight || boundingBox.height
        this.frameRect.top = initialTop || (this.refs as any).frame.offsetTop
        this.frameRect.left = initialLeft || (this.refs as any).frame.offsetLeft
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.transition !== this.props.transition) {
            this.setState({ transition: prefixedTransition(nextProps.transition) })
        }
    }

    componentWillUnmount() {
        const {
            attachedTo = window,
        } = this.props;
    }

    transform(state: any, allowTransition = true, updateHistory = true) {
        const boundingBox = this.getFrameRect()

        let top = (this.refs as any).frame.offsetTop
        let left = (this.refs as any).frame.offsetLeft
        let width = boundingBox.width
        let height = boundingBox.height

        if (updateHistory) {
            this.prevState = {
                top: top,
                left: left,
                width: width,
                height: height,
            }
        }

        if (!state) return;

        this.frameRect.top = typeof state.top === 'number' ? state.top :
            state.bottom ? (state.bottom - (state.height || height)) : top
        this.frameRect.left = typeof state.left === 'number' ? state.left :
            state.right ? (state.right - (state.width || width)) : left
        this.frameRect.width = typeof state.width === 'number' ? state.width :
            (typeof state.right === 'number' && typeof state.left === 'number') ? state.right - state.left :
                typeof state.right === 'number' ? state.right - this.frameRect.left : width
        this.frameRect.height = typeof state.height === 'number' ? state.height :
            (typeof state.bottom === 'number' && typeof state.top === 'number') ? state.top - state.bottom :
                typeof state.bottom === 'number' ? state.bottom - this.frameRect.top : height
        this.allowTransition = allowTransition

        if (this.props.onTransform) {
            setTimeout(this.props.onTransform.bind(this, this.frameRect, this.prevState))
        }
        this.forceUpdate()
    }

    restore(allowTransition = true) {
        this.transform(this.prevState, allowTransition)
    }

    minimize(allowTransition = true) {
        this.transform({ width: 0, height: 0 }, allowTransition)
    }

    maximize(allowTransition = true) {
        const {
            attachedTo = window,
        } = this.props

        this.transform({ top: 0, left: 0, width: attachedTo.innerWidth, height: attachedTo.innerHeight }, allowTransition)
    }

    render() {
        const {
            style,
            contentStyle,
            titleStyle,
            theme = defaultTheme,
            minWidth = 20,
            minHeight = 20,
            animate,
            cursorRemap,
            children,
            boundary,
            onMove,
            onResize
        } = this.props

        const pervFrameRect = { ...this.frameRect }

        if (this.clicked) {
            let hits = this.hitEdges
            const boundingBox = this.clicked.boundingBox

            if (hits.top || hits.bottom || hits.left || hits.right) {
                if (hits.right) this.frameRect.width = Math.max(this.cursorX - boundingBox.left, minWidth) + 'px'
                if (hits.bottom) this.frameRect.height = Math.max(this.cursorY - boundingBox.top, minHeight) + 'px'

                if (hits.left) {
                    let currentWidth = boundingBox.right - this.cursorX
                    if (currentWidth > minWidth) {
                        this.frameRect.width = currentWidth
                        this.frameRect.left = this.clicked.frameLeft + this.cursorX - this.clicked.x
                    }
                }

                if (hits.top) {
                    let currentHeight = boundingBox.bottom - this.cursorY
                    if (currentHeight > minHeight) {
                        this.frameRect.height = currentHeight
                        this.frameRect.top = this.clicked.frameTop + this.cursorY - this.clicked.y
                    }
                }
            }
            else if (this.state.cursor === 'move') {
                this.frameRect.top = this.clicked.frameTop + this.cursorY - this.clicked.y
                this.frameRect.left = this.clicked.frameLeft + this.cursorX - this.clicked.x
            }
        }

        if (boundary) {
            let {
                top,
                left,
                width,
                height
            } = this.frameRect
            if (typeof boundary.top === 'number' && top < boundary.top) {
                this.frameRect.top = boundary.top
            }
            if (typeof boundary.bottom === 'number' && top + height > boundary.bottom) {
                this.frameRect.top = boundary.bottom - height
                if (typeof boundary.top === 'number' && this.frameRect.top < boundary.top) {
                    this.frameRect.top = boundary.top
                    this.frameRect.height = boundary.bottom - boundary.top
                }
            }
            if (typeof boundary.left === 'number' && left < boundary.left) {
                this.frameRect.left = boundary.left
            }
            if (typeof boundary.right === 'number' && top + height > boundary.right) {
                this.frameRect.left = boundary.right - width
                if (typeof boundary.left === 'number' && this.frameRect.left < boundary.left) {
                    this.frameRect.left = boundary.left
                    this.frameRect.width = boundary.right - boundary.left
                }
            }
        }

        let cursor = this.state.cursor

        if (cursorRemap) {
            let res = cursorRemap.call(this, cursor)

            if (res != null) {
                if (typeof res === 'string') cursor = res
            }
            
        }


        const dnrState = {
            cursor,
            clicked: this.clicked,
            frameRect: this.frameRect,
            allowTransition: this.allowTransition
        }

        let titleBar = (
            <div ref="title"
                style={{
                    ...theme.title,
                    ...titleStyle,
                    cursor
                }}>
                {typeof this.props.titleBar !== 'string' ?
                    React.cloneElement(this.props.titleBar, { cursor: dnrState.cursor }) : this.props.titleBar}
            </div>)

        const childrenWithProps = React.Children.map(children, function (child: any) {
            return typeof child === 'string' ? child : React.cloneElement(child, { cursor: dnrState.cursor })
        })

        let frameTransition = (animate && this.allowTransition) ? this.state.transition : {}

        if (onMove && (pervFrameRect.top !== this.frameRect.top ||
            pervFrameRect.left !== this.frameRect.left)) {
            setTimeout(onMove.bind(this, this.frameRect, pervFrameRect))
        }

        if (onResize && (pervFrameRect.width !== this.frameRect.width ||
            pervFrameRect.height !== this.frameRect.height)) {
            setTimeout(onResize.bind(this, this.frameRect, pervFrameRect))
        }
        return (
            <div ref="frame"
                onMouseDownCapture={this._onDown.bind(this)}
                style={{
                    ...theme.frame,
                    ...frameTransition,
                    cursor: cursor,
                    ...style,
                    ...this.frameRect
                }}>
                {titleBar}
                <div ref='content'
                    className='contentClassName'
                    style={{ position: 'absolute', width: '100%', top: theme.title.height, bottom: 0, ...contentStyle }}>
                    {childrenWithProps}
                </div>
            </div>
        )
    }
    getFrameRect() {
        return (this.refs as any).frame && (this.refs as any).frame.getBoundingClientRect()
    }
    getDOMFrame() {
        return this.refs.frame
    }
    getTitleRect() {
        return (this.refs as any).title && (this.refs as any).title.getBoundingClientRect();
    }
    _cursorStatus(e: any) {

        const {
            edgeDetectionRange = 4
        } = this.props;

        const boundingBox = this.getFrameRect();
        this.cursorX = e.clientX;
        this.cursorY = e.clientY;

        if (this.clicked) return;

        if (boundingBox) {

            let hitRange = edgeDetectionRange
            let hitTop = this.cursorY <= boundingBox.top + hitRange
            let hitBottom = this.cursorY >= boundingBox.bottom - hitRange
            let hitLeft = this.cursorX <= boundingBox.left + hitRange
            let hitRight = this.cursorX >= boundingBox.right - hitRange

            let cursor = 'auto';

            if (hitTop || hitBottom || hitLeft || hitRight) {
                if (hitRight && hitBottom || hitLeft && hitTop) {
                    cursor = 'nwse-resize';
                } else if (hitRight && hitTop || hitBottom && hitLeft) {
                    cursor = 'nesw-resize';
                } else if (hitRight || hitLeft) {
                    cursor = 'ew-resize';
                } else if (hitBottom || hitTop) {
                    cursor = 'ns-resize';
                }
                e.stopPropagation();
            }
            else {
                const titleBounding = this.getTitleRect()
                if (this.cursorX > titleBounding.left && this.cursorX < titleBounding.right &&
                    this.cursorY > titleBounding.top && this.cursorY < titleBounding.bottom) {
                    cursor = 'move';
                }
            }

            this.hitEdges = {
                top: hitTop,
                bottom: hitBottom,
                left: hitLeft,
                right: hitRight
            };

            if (cursor !== this.state.cursor) {
                this.setState({ cursor: cursor });
            }
        }

    }
    _onDown(e: any) {
        this.allowTransition = false
        this._cursorStatus(e)
        const boundingBox = this.getFrameRect()

        let hitTop = boundingBox.top + 100;
        let hitBottom = boundingBox.bottom - 100;
        let hitLeft = boundingBox.left + 20;
        let hitRight = boundingBox.right - 20;

        if (this.cursorX < hitLeft || this.cursorX > hitRight || this.cursorY < hitTop || this.cursorY > hitBottom) {

            this.clicked = {
                x: e.clientX, y: e.clientY, boundingBox: boundingBox,
                frameTop: (this.refs as any).frame.offsetTop, frameLeft: (this.refs as any).frame.offsetLeft
            };
        }

    }
    _onUp(e: any) {
        this.clicked = null
        this._cursorStatus(e)
    }
    _onMove(e: any) {
        this._cursorStatus(e)
        if (this.clicked !== null) {
            this.forceUpdate()
        }
    }
}
