import React, { useEffect, useRef, useState } from "react";
import { LexicalEditor } from "cp-common-ui-components";

interface LexicalEditorComponentProps {
    messageBody: string;
    changeStateEditorBody: (event: any) => void;
    readOnly?: number;
    withLink?: boolean;
    placeholderText?: string;
    uniquekey?: string;
}

const LexicalEditorComponent: React.FC<LexicalEditorComponentProps> = React.memo((props) => {
    const { messageBody, changeStateEditorBody, placeholderText, readOnly, withLink } = props;

    const wrapInParagraph = (htmlString: string): string => {
        const trimmedString = htmlString.trim();
        const startsWithTag = /^<([a-zA-Z]+)(\s|>)/.test(trimmedString);
        return startsWithTag ? htmlString : `<p>${htmlString}</p>`;
    };

    return (
        <LexicalEditor
            key={props.uniquekey}
            placeholderText={placeholderText ?? ""}
            options={withLink ? [
                "edit",
                "format",
                "undo",
                "redo",
                "bold",
                "italic",
                "underline",
                "alignments",
                // "bulletList",
                // "numberList",
                // "adjust",
                "colorPicker",
                "bgColorPicker",
                // "linkImage",
                // "clearFormat",
                // "help"
            ] : [
                "edit",
                "format",
                "undo",
                "redo",
                "bold",
                "italic",
                "underline",
                "alignments",
                // "bulletList",
                // "numberList",
                // "adjust",
                "colorPicker",
                "bgColorPicker",
                // "clearFormat",
                // "help"
            ]
            }
            changeStateEditorBody={changeStateEditorBody}
            messageBody={wrapInParagraph(messageBody)}
            readOnly={readOnly}
        />
    );
});

export default LexicalEditorComponent;
