import { PdfViewer } from 'awesome-pdf-viewer';
import { ControlBase, PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PlaceholderControl } from 'awesome-pdf-viewer/dist/Controls/PlaceholderControl/PlaceholderControl';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { Header } from 'awesome-pdf-viewer/dist/layout/Header';
import { PageProperties, PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { LeftPanel } from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { CustomOptions } from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageMode, PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import * as bootbox from 'bootbox';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as CompanyStore from 'src/store/company/CompanyStore';
import * as UserSettingStore from 'src/store/UserManagement/UserSettingStore';
import { IEROSigner } from '../../../../../../components/common/ProcessReturnModal/ProcessReturnModels';
import { SignatureGroupType } from '../../../../../../components/common/TaxReturn';
import { INVALID_PAGE, SignatureControlConstants, UploadtaxReturnConstants } from '../../../../../../components/helper/Constants';
import { HideLoader, ShowLoader } from '../../../../../../components/helper/Loader';
import { IUserProfile } from '../../../../../../components/navigation/profile/ProfileObjects';
import { UploadStatus } from '../../../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { IUserModel } from '../../../../../../Core/ViewModels/User/UserViewModel';
import * as UserSignatureStore from '../../../../../store/UserSignatureStore';
import { PdfProperties } from '../../../../Helper/PdfHelper';
import { Accordion } from '../../../Common/Accordion';
import { Guid } from './../../../../../../Core/Utilities/Guid';
import {
    AdditionalESignDocumentsUploadData,
    AdditionalEsignModel,
    Bookmarks,
    SignatureCheckBoxControl,
    ChoosableControlData,
    Control, ControlRole,
    ControlType, DataType, DocumentType, DocumentTypeLabels, initialAdditionalEsignUploadData, initialControl, OrganizerClient, OrganizerClientType, OrganizerDocumentSignatureSettings, SignatureRadioButtonControl, SignerDropDown, UserGroup,
    OrganizerProcessPage
} from './../../../../../models/OrganizerComponentModels';
import {
    getControl, getControlIcon, getControlText, getTopAdjustmentSignatureControlValue
} from './../../../../Helper/OrganizerHelperFunctions';
import { EditAdditionalESignModal } from './Modal/EditAdditionalEsignModal';
import { EROSignatureCard } from './Modal/EROSignaturePanel';
import { UploadAdditionalESignModal } from './Modal/UploadAdditionESignModal';
import { BookmarkTree } from './Panels/BookmarkTree';
import { SignaturePanel } from './Panels/SignaturePanel';
import Enumerable from 'linq';
import moment from 'moment';
import { IConvertedPdfFileList } from '../../../../../store/AdditionalEsignDocumentStore';
import { SignerTooltipPopup } from 'src/components/common/SignerToolTip';
import { getControlTopPositionPDFJS } from 'src/components/helper/HelperFunctions';
import { IPartnerData } from '../../../../../../store/UserManagement/UserStore';
import { SignaturePanelConstants } from 'src/components/helper/Constants';
import { Options } from 'react-select';
const Draggabilly: any = require('draggabilly/draggabilly');

export interface AdditionalEsignTabProps {
    additionalEsignDocuments: AdditionalEsignModel[];
    organizerClients: OrganizerClient[];
    bookmarks: Bookmarks;
    onDrop: (control: Control, pageNo: number, documentGuid: string) => void;
    onSaveToolTip: (control: Control, pageNo: number, documentGuid: string) => void;
    onControlRemove: (control: Control, pageNo: number, documentGuid: string) => void;
    onControlUpdate: (oldControl: Control, newControl: Control, pageNo: number, documentGuid: string) => void;
    onUpdateControlData: (control: Control, pageNo: number, documentGuid: string) => void;
    readOnlyView?: boolean;
    demoClient?: boolean;
    pdfURL: string
    showSignaturePanel?: boolean;
    getUploadLink: (callback: (data?: any) => void) => void;
    documentGuid: string;
    submitDocuments: (data: AdditionalEsignModel[]) => void;
    requestDocuments: (documentGuid: string, taxYear: number, callback?: (esignDocs: AdditionalEsignModel[]) => void) => void;
    deleteDocument: (fileGuid: string) => void;
    updateDocument: (data: AdditionalEsignModel) => void;
    deleteBlobAdditionalEsign: (fileGuid: string, callback?: () => void) => void;
    updateEROSignatureStamp?(userId: number): void;
    isAssignedToLoggedinUser: boolean;
    userSignatures: UserSignatureStore.IUserSignatures;
    company: CompanyStore.ICompanyData;
    userProfile: IUserProfile;
    partners: IPartnerData;
    userSettings: UserSettingStore.UserSettings;
    taxYear: number;
    ero: number;
    users: IUserModel[];
    requestDownloadPath(userId: number): void;
    signatureSettings: OrganizerDocumentSignatureSettings;
    readonly: boolean;
    batchId?: string;
    deletedEsignDocuments?: number[];
    convertDocToPdfArtifactAsync: (documentGuid: string, taxYear: number, fileName: string, isBatch: boolean, callback?: (data?: any) => void) => void;
    convertedWordToPdfFiles: IConvertedPdfFileList;
}

export interface IAdditionalEsignDocument {
    documentType: number;
    eSignDocuments: AdditionalESignDocumentsUploadData[];
}

export interface AdditionalEsignTabState {
    bookmarkExpand: boolean;
    currentPage: number;
    zoomEnabled: boolean;
    clientSignatureExpand: boolean;
    senderSignatureExpand: boolean;
    focusGroup: UserGroup;
    seletedSigner: SignerDropDown;
    selectedSender: SignerDropDown;
    scale: number;
    pageHeight: number;
    showDeleteConfirmationPopover: boolean;
    moveToToggle: boolean;
    deletePopoverTarget: any;
    pdfURL: string;
    showUploadPopup: boolean;
    additionalDocuments: IAdditionalEsignDocument[];
    selectedDocument: string;
    showEditModal: boolean;
    selectedAdditionalEsignDocument: AdditionalESignDocumentsUploadData;
    selectedAdditionalFiletoEdit: AdditionalESignDocumentsUploadData;
    selectedEROSigner: IEROSigner;
    eroSigners: IEROSigner[];
    focusedGroup: SignatureGroupType;
    toolTipControl: Control;
    showToolTipPopup: boolean;
    content: {
        header: string,
        title: string,
        tooltipContent: string
    },
    signatureControlType: number,
    signatureDataType: DataType
}



export default class AdditionalEsignTab extends React.Component<AdditionalEsignTabProps, AdditionalEsignTabState> {
    private _viewPanel: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _toolbar: any;
    private _bookmarkPanel: any;
    private _controlDisplayPanel: any;
    constructor(props: AdditionalEsignTabProps) {
        super(props);
        this.state = {
            bookmarkExpand: true,
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            clientSignatureExpand: true,
            focusGroup: UserGroup.None,
            seletedSigner: {
                value: 0,
                label: "",
                disabled: false,
                role: ControlRole.None
            },
            selectedSender: {
                value: 0,
                label: "",
                disabled: false,
                role: ControlRole.None
            },
            senderSignatureExpand: true,
            scale: PdfProperties.DefaultScale,
            pageHeight: PdfProperties.PageHeight,
            showDeleteConfirmationPopover: false,
            moveToToggle: false,
            deletePopoverTarget: {},
            pdfURL: '',
            showUploadPopup: false,
            additionalDocuments: [],
            selectedDocument: '',
            showEditModal: false,
            selectedAdditionalEsignDocument: initialAdditionalEsignUploadData,
            selectedAdditionalFiletoEdit: initialAdditionalEsignUploadData,
            selectedEROSigner: {
                value: 0,
                label: "",
                isEnableSignatureDelegation: true,
                eroImage: ""
            },
            eroSigners: [],
            focusedGroup: SignatureGroupType.None,
            toolTipControl: initialControl,
            showToolTipPopup: false,
            content: {
                header: "",
                title: "",
                tooltipContent: ""
            },
            signatureControlType: 0,
            signatureDataType: DataType.None
        }
    }

    componentDidMount() {
        this.setReferences();
        this.setEroSignerDropdownValues(this.props);
        if (!this.props.demoClient) {
            const selectedClient = this.props.organizerClients.find(client => client.clientType == OrganizerClientType.Taxpayer);
            if (selectedClient?.isDeceased && this.props.organizerClients.length > 1
                && !this.props.organizerClients[1].isDeceased) {
                this.setState({
                    seletedSigner: {
                        value: this.props.organizerClients[1].id,
                        label: this.props.organizerClients[1].name,
                        disabled: this.props.organizerClients[1].isDeceased,
                        role: ControlRole.Spouse
                    }
                });
            }
            else
                if (selectedClient) {
                    this.setState({
                        seletedSigner: {
                            value: selectedClient.id,
                            label: selectedClient.name,
                            disabled: selectedClient.isDeceased,
                            role: ControlRole.Taxpayer
                        }
                    })
                }
        } else {
            this.setState({
                seletedSigner: {
                    value: ControlRole.Taxpayer,
                    label: "Taxpayer",
                    disabled: false,
                    role: ControlRole.Taxpayer
                }
            })
        }
        this.setState({
            currentPage: 1
        });
    }

    createAdditionalDocuments = (esignDocs: AdditionalEsignModel[]) => {

        let esignDocuments: AdditionalESignDocumentsUploadData[] = [];
        if (esignDocs && esignDocs.length > 0) {
            const isFound = esignDocs.findIndex(x => x.sasURL == "" || x.sasURL == null) >= 0;
            if (isFound) {
                this.props.requestDocuments(this.props.batchId ? this.props.batchId : this.props.documentGuid, this.props.taxYear, this.onRequestDocumentComplete);
            }
            esignDocs.forEach((doc) => {
                esignDocuments.push({
                    name: doc.fileName,
                    progressBar: 0,
                    size: "10",
                    status: UploadStatus.Uploaded,
                    uuid: doc.fileGuid,
                    gridRowDisable: true,
                    rowIndex: 1,
                    isPDFloaded: false,
                    documentType: doc.documentType,
                    sasUrl: doc.sasURL,
                    isDeleted: false,
                    sasGuid: doc.fileGuid,
                    id: doc.id
                });
            });

            this.props.deletedEsignDocuments && this.props.deletedEsignDocuments.forEach((id) => {
                const index = esignDocuments.findIndex(x => x.id == id);
                if (index >= 0) {
                    esignDocuments[index].isDeleted = true;
                }
            })

            this.setState({
                additionalDocuments: this.prepareEsignDocumentsData(esignDocuments)
            });
        }
        else {
            this.setState({
                additionalDocuments: [],
                selectedDocument: ''
            })
        }
    }

    onRequestDocumentComplete = (esignDocs: AdditionalEsignModel[]) => {
        let additionalDocs = [...this.state.additionalDocuments];
        additionalDocs.forEach(((doc) => {
            doc.eSignDocuments.map(newDoc => {
                if (!newDoc.sasUrl)
                    newDoc.sasUrl = esignDocs.filter(x => x.id == newDoc.id)[0].sasURL
            });
        }));

        this.setState({
            selectedDocument: additionalDocs[0].eSignDocuments[0].sasUrl,
            additionalDocuments: additionalDocs
        })
    }

    setReferences = () => {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
    }

    componentWillReceiveProps(nextProps: AdditionalEsignTabProps) {
        if (nextProps.additionalEsignDocuments != this.props.additionalEsignDocuments) {
            this.createAdditionalDocuments(nextProps.additionalEsignDocuments);
        }
    }

    addEroSigner = (eroSignersArray: IEROSigner[], eroSigner: IEROSigner) => {
        if (eroSignersArray.filter(e => e.value == eroSigner.value).length === 0) {
            eroSignersArray.push(eroSigner);
        }
    }

    setEroSignerDropdownValues = (nextprops: AdditionalEsignTabProps) => {
        let eroSigners: IEROSigner[] = [];
        if (this.props.company != undefined) {
            let companyAsSigner = {
                label: this.props.company.companyProfile.companyInfo.companyName,
                value: 0,
                isEnableSignatureDelegation: true,
                eroImage: this.props.company.signatureUploadLink,
            }
            this.addEroSigner(eroSigners, companyAsSigner);
        }

        if (this.props.userProfile != undefined
            && this.props.partners?.partners?.length > 0) {
            const currentUser = {
                label: this.props.userProfile.firstName + " " + this.props.userProfile.lastName,
                value: this.props.userProfile.userId as number,
                isEnableSignatureDelegation: true,
                eroImage: this.props.userSignatures[this.props.userProfile.userId]?.signatureDownloadPath
            }
            this.addEroSigner(eroSigners, currentUser);
        }

        if (this.props.userSettings?.delegatedSigners && this.props.userSettings.delegatedSigners.length > 0) {
            this.props.userSettings.delegatedSigners.map(eroSigner => {
                let signer = {
                    label: eroSigner.firstName + " " + eroSigner.lastName,
                    value: eroSigner.userId,
                    isEnableSignatureDelegation: eroSigner.isEnableSignatureDelegation,
                    eroImage: eroSigner.signaturePath,
                }
                this.addEroSigner(eroSigners, signer);
            });
        }

        if (this.props.userProfile.userId !== this.props.ero) {
            const ero = this.props.userSignatures[this.props.ero];
            if (ero == undefined) {
                this.props.requestDownloadPath(this.props.ero), () => {
                    const documentEroUser = this.props.users.filter(x => x.userId === this.props.ero)[0];
                    const eroUser = {
                        label: documentEroUser.firstName + " " + documentEroUser.lastName,
                        value: documentEroUser.userId,
                        isEnableSignatureDelegation: true,
                        eroImage: this.props.userSignatures[this.props.ero].signatureDownloadPath
                    }
                    this.addEroSigner(eroSigners, eroUser);
                }
            }
        }



        const selectedEROId = this.props.signatureSettings.signatureStampUser != 0 ?
            this.props.signatureSettings.signatureStampUser
            : this.state.selectedEROSigner.value;
        let tempSelectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as IEROSigner;
        tempSelectedEROSigner = tempSelectedEROSigner ? tempSelectedEROSigner :
            eroSigners.find(x => x.value === 0) as IEROSigner;

        this.setState({
            eroSigners: eroSigners,
            selectedEROSigner: {
                value: tempSelectedEROSigner.value,
                label: tempSelectedEROSigner.label,
                isEnableSignatureDelegation: tempSelectedEROSigner.isEnableSignatureDelegation,
                eroImage: tempSelectedEROSigner.eroImage
            }
        });
    }

    componentDidUpdate() {
        this.setReferences();

    }

    componentWillMount() {
        const { additionalEsignDocuments } = this.props;
        this.createAdditionalDocuments(additionalEsignDocuments);
    }

    toggleBookmarkExpand = () => {
        this.setState(prevState => ({
            bookmarkExpand: !prevState.bookmarkExpand
        }));
    }

    toggleClientSignatureExpand = () => {
        this.setState(prevState => ({
            clientSignatureExpand: !prevState.clientSignatureExpand
        }));
    }

    toggleSenderSignatureExpand = () => {
        this.setState(prevState => ({
            senderSignatureExpand: !prevState.senderSignatureExpand
        }));
    }

    onZoom = (enable: boolean) => {
        this.setState({ zoomEnabled: enable });
    }


    onPageChange = (pageProps: PageProperties) => {
        this._toolbar.handleZoomChange(this.state.scale);
        this.setState({
            currentPage: pageProps.page
        }, () => {
            if (this._viewPanel) {
                this._viewPanel.updateControlState(pageProps);
                this._viewPanel.gotoPage(pageProps.page);
            } else {
                console.warn("this._viewPanel is null or undefined");
            }
        });
    }

    registerDropEvents = () => {
        const _this = this;
        const elements = document.getElementsByClassName("page");
        for (var i = 0; i <= elements.length - 1; i++) {
            const tmpElement: any = elements[i];
            this._toolbar.handleZoomChange(1);
            tmpElement.ondrop = function (ev: any) {
                ev.preventDefault();
                const tmpPageElement = ev.target.offsetParent;
                if (tmpPageElement.attributes["data-page-number"]) {
                    _this.dropControl(ev);
                }
            };
            tmpElement.ondragover = function (ev: any) {
                ev.preventDefault();
            };
        }
    }

    dragStartSignatureControl = (event: any, controlType: ControlType, dataType: DataType, signerId: number, role: ControlRole) => {
        this.registerDropEvents();
        event.dataTransfer.setData('controlType', controlType.toString());
        event.dataTransfer.setData('dataType', dataType.toString());
        event.dataTransfer.setData('signerId', signerId);
        event.dataTransfer.setData('controlRole', role);

        const element = document.createElement("div");
        element.id = "dragging-element";
        element.style.position = "absolute";
        element.style.top = "-1000px";

        const controlText = getControlText(controlType, dataType);
        const icon = getControlIcon(controlType, dataType);
        element.innerHTML = '<p style="border-style: solid; border-width: 1px;"><i class="'
            + icon + '" style="padding: 5px; background: rgb(240, 240, 241);"></i><label style="font-size: '
            + (12 * this.state.scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px;">'
            + controlText + '</label></p>';

        document.body.appendChild(element);
        if (event.dataTransfer && typeof event.dataTransfer.setDragImage === 'function') {
            event.dataTransfer.setDragImage(element, 0, 0);
        } else {
            console.warn("setDragImage is not supported in this environment.");
        }
    }

    dragEnd = (event: any) => {
        const element = document.getElementById("dragging-element");
        if (element) {
            element.remove();
        }
    }

    dropControl = (event: any) => {

        let controlType = event.dataTransfer.getData('controlType');
        let dataType = event.dataTransfer.getData('dataType');
        const signerId = event.dataTransfer.getData('signerId');
        const role = event.dataTransfer.getData('controlRole');

        if (controlType && dataType && signerId) {
            controlType = parseInt(controlType);
            dataType = parseInt(dataType);
            if (!Number.isNaN(controlType) && !Number.isNaN(dataType)) {
                this.addSignatureControl(controlType, dataType, event.offsetX, event.offsetY, signerId, role);
            }
        }
    }

    addSignatureControl = (type: ControlType, dataType: DataType, clientX: number, clientY: number, signerId: number, role: ControlRole) => {
        const { currentPage } = this.state;
        const left = ControlBase.getBackendControlLeftPosition(clientX);
        const top = getControlTopPositionPDFJS(currentPage, clientY, 1);

        const control: Control = getControl(type, dataType, left, top);
        control.controlRole = role;
        control.id = Guid.newGuid().toString();
        control.signerId = signerId;
        if (type != ControlType.SignatureCheckBoxControl && type != ControlType.SignatureRadioButtonControl) {
            control.boundingRectangle = {
                left: left,
                top: top,
                width: 150,
                height: 30
            }
        }
        const { Textbox, SignatureCheckBoxControl, SignatureRadioButtonControl, Signature } = ControlType;
        const showControlPopup = ControlRole.Ero !== Number(control.controlRole) && [SignatureCheckBoxControl, SignatureRadioButtonControl, Signature].includes(control.controlType);
        if ([Textbox].includes(control.controlType) && dataType == DataType.Text) {
            this.showControlPropertyPopup(control);
        }
        else if (showControlPopup) {
            if (Signature === type) {
                control.required = true;
            }
            this.setControlDefaultTooltipValue(control);
            this.showControlPropertyPopup(control);
            this.props.onSaveToolTip(control, this.state.currentPage, this.state.selectedAdditionalEsignDocument.sasGuid);
        }
        this.props.onDrop(control, this.state.currentPage, this.state.selectedAdditionalEsignDocument.sasGuid);
    }

    saveToolTip(control: Control) {
        this.props.onSaveToolTip(control, this.state.currentPage, this.state.selectedAdditionalEsignDocument.sasGuid);
    }

    private showControlPropertyPopup = (control: Control) => {
        this.setState({
            toolTipControl: control,
            showToolTipPopup: true,
            signatureControlType: control.controlType,
            signatureDataType: control.dataType,
            content: this.getTooltipContent(control.controlType)
        });
    }

    private setControlDefaultTooltipValue = (control: Control) => {
        const { SignatureCheckBoxControl, SignatureRadioButtonControl } = ControlType;
        if (control.controlType === SignatureCheckBoxControl) {
            control.tooltip = "1";
        } else if (control.controlType === SignatureRadioButtonControl) {
            control.tooltip = "2";
        }
    }

    private getTooltipContent = (controlType: number): any => {
        const { SignatureCheckBoxControl, SignatureRadioButtonControl, Signature } = ControlType;
        switch (controlType) {
            case SignatureCheckBoxControl:
                return SignaturePanelConstants.CheckBoxControl;
            case SignatureRadioButtonControl:
                return SignaturePanelConstants.RadioButtonControl;
            case Signature:
                return SignaturePanelConstants.Signature;
            default:
                return SignaturePanelConstants.Default;
        }
    }

    private onCancelToolTipPopup = () => {
        this.setState({
            toolTipControl: initialControl,
            showToolTipPopup: false,
            signatureControlType: 0,
            signatureDataType: DataType.None
        });
    }

    private onUpdateToolTipPopupControl = (control: SignatureCheckBoxControl | SignatureRadioButtonControl | Control) => {
        this.setState({
            toolTipControl: control
        });
    }

    adjustSamePositionControl = (control: Control): Control => {
        let signatureControls = this.getCurrentPageControls();
        if (!signatureControls) {
            signatureControls = [];
        }
        const index = signatureControls.findIndex(x => x.id === control.id);
        if (index !== -1) {
            control.boundingRectangle.left += 5;
            control.boundingRectangle.top += 5;
            return this.adjustSamePositionControl(control);
        }
        else {
            return control;
        }
    }

    onControlRemove = (control: Control) => {
        this._controlLayer.removeControl("control_" + control.id);
        this.props.onControlRemove(control, this.state.currentPage, this.state.selectedAdditionalEsignDocument.sasGuid);
    }

    onControlUpdate = (oldControl: Control, newControl: Control) => {
        this.props.onControlUpdate(oldControl, newControl, this.state.currentPage, this.state.selectedAdditionalEsignDocument.sasGuid);
    }

    onSignerChange = (singerId: number) => {
        const selectedClient = this.props.organizerClients.find(client => client.id == singerId);
        if (selectedClient) {
            this.setState({
                seletedSigner: {
                    value: selectedClient.id,
                    label: selectedClient.name,
                    disabled: selectedClient.isDeceased,
                    role: selectedClient.clientType == OrganizerClientType.Taxpayer ? ControlRole.Taxpayer : ControlRole.Spouse
                }
            })
        }
    }

    onSenderChange = (signerGuid: string) => {

    }

    onFocusChange = (focusGroup: UserGroup) => {
        this.setState({
            focusGroup: focusGroup
        });
    }

    getRecievers = (): SignerDropDown[] => {
        return [
            {
                value: ControlRole.Taxpayer,
                label: "Taxpayer",
                role: ControlRole.Taxpayer,
                disabled: false
            },
            {
                value: ControlRole.Spouse,
                label: "Spouse",
                role: ControlRole.Spouse,
                disabled: false
            }
        ]
    }

    onDemoSignerChange = (signerId: number) => {
        this.setState({
            seletedSigner: {
                value: signerId,
                label: ControlRole[signerId].toString(),
                role: signerId,
                disabled: false
            }
        })
    }

    private onDeleteControl = (id: string) => {
        const control: any = this.getCurrentPageControls().find(a => a.id == id);
        this._controlLayer.removeControl("control_" + id);
        this.props.onControlRemove(control, this.state.currentPage, this.state.selectedAdditionalEsignDocument.sasGuid);
    }

    getCurrentPageControls = () => {
        const { currentPage, selectedAdditionalEsignDocument } = this.state;
        if (this.props.additionalEsignDocuments) {
            const requiredDoc = this.props.additionalEsignDocuments.find(a => a.fileGuid == selectedAdditionalEsignDocument.sasGuid);
            if (requiredDoc?.documentControls?.formData && requiredDoc.documentControls.formData.length > 0) {
                const requiredPage = requiredDoc.documentControls.formData.find(a => a.pageNo == currentPage);
                return (requiredPage && requiredPage.controlList) ?? [];
            }
        }
        return [];
    }

    onControlLoad = (control: any, pageElement: any) => {
        //For Radio button and checkbox functionality 
        if (control.draggable === false &&
            (control.data.controlType === ControlType.SignatureCheckBoxControl || control.data.controlType === ControlType.SignatureRadioButtonControl) &&
            !this.props.readonly) {
            this.applyControlStyles(control);
            this.makeDraggableControl(control, pageElement, this.handleControlDragEnd);
            this.registerResizeEvent(control, this.handleResize);
        }
    }

    private applyControlStyles(control: any) {
        const controlElement = document.getElementById(control.id);
        if (controlElement) {
            controlElement.removeAttribute('class');
            controlElement.removeAttribute('title');
            controlElement.classList.add('choosable-control-group');
            controlElement.classList.add('resizable-both');
            const selectedSignerStyle = controlElement.children[0]?.getAttribute('data-signerstyle');
            controlElement.classList.add(selectedSignerStyle ? selectedSignerStyle : 'choosable-signature-control-border');
        }
    }
    private makeDraggableControl = (control: any, pageElement: any, dragEndCallBack: any) => {
        const draggie = new Draggabilly('#' + control.id, {
            containment: pageElement,
            handle: '.draggable-btn-control'
        });

        draggie.off('dragEnd', '#' + control.id);
        draggie.on('dragEnd', function (event: any, pointer: any) {
            event = event;
            pointer = pointer;
            const controlElement = document.getElementById(control.id);
            controlElement &&
                dragEndCallBack(control.id.replace(SignatureControlConstants.ControlIdPrefix, ''),
                    controlElement.offsetTop, controlElement.offsetLeft)
        });

        this.setDraggableControlItems(this.handleControlItemsDragEnd);
    }

    private setDraggableControlItems(dragEndCallBack: any, handle?: string): any {

        const elements: any = document.querySelectorAll('.choosable-control-item');

        for (var i = 0; i <= elements.length - 1; i++) {

            const element: any = elements[i];

            const draggable = element.attributes["data-draggable"]?.value;

            if (!draggable) {
                element.setAttribute("data-draggable", "true");
                const draggie = new Draggabilly(element, {
                    containment: element.parentElement,
                    handle: handle
                });

                draggie.off('dragEnd', element);
                draggie.on('dragEnd', function (event: any, pointer: any) {
                    event = event;
                    pointer = pointer;

                    if (element) {
                        const id: string = element.attributes["data-id"].value;
                        const controlGuid = element.attributes["data-controlguid"].value;

                        dragEndCallBack(id,
                            controlGuid.replace(SignatureControlConstants.ControlIdPrefix, ''),
                            element.offsetTop, element.offsetLeft)
                    }
                });
            }
        };
    }

    public handleResize = (controlGuid: string, height: number, width: number) => {
        let controls = this.getCurrentPageControls();
        const index = controls.findIndex(x => x.id === controlGuid);
        if (index != -1) {
            let control = controls[index] as SignatureRadioButtonControl | SignatureCheckBoxControl;
            control.boundingRectangle.height = height;
            control.boundingRectangle.width = width;
        }
    }

    private registerResizeEvent(control: any, onResize: any): any {
        const element = document.getElementById(control.id);
        if (element) {
            element.onmouseup = function (event: any) {
                event = event;
                onResize(control.id.replace(SignatureControlConstants.ControlIdPrefix, ''),
                    element.offsetHeight, element.offsetWidth);
            };
        }
    }

    private handleControlDragEnd = (controlGuid: string, top: number, left: number) => {
        let controls = this.getCurrentPageControls();
        const index = controls.findIndex(x => x.id === controlGuid);
        if (index != -1) {
            const controltop = top / this.state.scale;
            const controlLeft = left / this.state.scale;
            const pageSize: PageSize = this.getCurrentPageSize();
            controls[index].boundingRectangle.left = ControlBase.getBackendControlLeftPosition(controlLeft);
            controls[index].boundingRectangle.top = ControlBase.getBackendControlTopPosition(pageSize.height,
                controltop + getTopAdjustmentSignatureControlValue(controls[index].controlType));
            let control = controls[index] as SignatureRadioButtonControl | SignatureCheckBoxControl;
            //this.props.onUpdateControlData(control, this.state.currentPage, this.state.selectedAdditionalEsignDocument.sasGuid);
            this.onUpdateControlData(control);
        }
    }

    private onUpdateControlData = (control: Control) => {
        const { currentPage, selectedAdditionalEsignDocument } = this.state;
        const draggedControl = this.props.additionalEsignDocuments
            .filter(a => a.fileGuid == selectedAdditionalEsignDocument.sasGuid)[0].documentControls.formData
            .filter(c => c.pageNo == currentPage)[0].controlList
            .filter(c => c.id == control.id)[0];
        draggedControl.boundingRectangle.left = control.boundingRectangle.left;
        draggedControl.boundingRectangle.top = control.boundingRectangle.top;
    }

    private handleControlItemsDragEnd = (id: string, controlGuid: string, top: number, left: number) => {
        let controls = this.getCurrentPageControls();
        const index = controls.findIndex(x => x.id === controlGuid);
        if (index != -1) {
            let control = controls[index] as SignatureRadioButtonControl | SignatureCheckBoxControl;
            if (control) {
                let itemIndex = control.items.findIndex(x => x.id == id);
                if (itemIndex != -1) {
                    control.items[itemIndex].left = left;
                    control.items[itemIndex].top = top;
                    //this.props.onUpdateControlData(control, this.state.currentPage, this.state.selectedAdditionalEsignDocument.sasGuid);
                    this.onUpdateControlData(control);
                }
            }
        }
    }

    public getCurrentPageSize = (): PageSize => {
        const { currentPage } = this.state;
        if (this._viewPanel) {
            return this._viewPanel.getPageSize(currentPage, 1);
        }
        else {
            return PageSize.create(PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH);
        }
    }

    createControls = () => {
        const { currentPage } = this.state;
        const controlCollection: any[] = [];
        this._controlList = [];
        const eroSignHighlightStyle = "ero-sign-highlight";
        const signatureControls: Control[] = this.getCurrentPageControls();
        const iconStyle: string[] = ['fas'];
        const divStyle: string[] = ['draggable-signature', 'react-draggable'];
        const deleteIconStyle: string[] = ['remove-handle', 'glyphicon', 'glyphicon-remove'];
        if (signatureControls && signatureControls.length) {
            signatureControls
                .forEach((control: Control) => {
                    const pageSize: PageSize = this.getCurrentPageSize();
                    const pdfViewerTop = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.boundingRectangle.top) - getTopAdjustmentSignatureControlValue(control.controlType);
                    const pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.boundingRectangle.left);
                    const height = control.boundingRectangle.height == 0 ? 30 : control.boundingRectangle.height;
                    const width = control.boundingRectangle.width == 0 ? 150 : control.boundingRectangle.width;
                    const top = pdfViewerTop;
                    const left = pdfViewerLeft;
                    const controlText = getControlText(control.controlType, control.dataType);
                    if (control.controlRole == ControlRole.Ero)
                        controlCollection.push(
                            <PlaceholderControl
                                height={(height * this.state.scale)}
                                id={"control_" + control.id}
                                key={"control_key_" + control.id}
                                ref={(ref) => {
                                    this._controlList.push(ref)
                                }}
                                page={currentPage}
                                data={{ 'controlType': control.controlType }}
                                width={(width * this.state.scale)}
                                top={pdfViewerTop}
                                left={pdfViewerLeft}
                                helptext={control.tooltip}
                                disabled={false}
                                isRequired={control.required}
                                draggable={!this.props.readonly}
                                onDragStop={(top: number, left: number) => { this.onDragStop(control.id, top, left) }}>
                                <div className={`esignEROStamp ${!this.props.readonly && eroSignHighlightStyle}`} style={{ height: "100%", width: "100%" }}>
                                    {this.state.selectedEROSigner.eroImage && this.state.selectedEROSigner.eroImage.length > 1 ?
                                        <img src={this.state.selectedEROSigner.eroImage} draggable="false" /> : null}
                                </div>
                                {
                                    !this.props.readonly && <span
                                        onClick={() => { this.onDeleteControl(control.id) }}
                                        aria-disabled={false}
                                        className="remove-handle glyphicon glyphicon-remove"
                                        style={{ background: "#000", color: "#fff", cursor: 'pointer', fontSize: `${16 * this.state.scale}px`, float: "right", lineHeight: 1, margin: "-10% auto auto -10%", position: "relative" }}></span>
                                }
                            </PlaceholderControl>);
                    else
                        controlCollection.push(
                            <PlaceholderControl
                                height={(height * this.state.scale)}
                                id={"control_" + control.id}
                                key={"control_key_" + control.id}
                                ref={(ref) => {
                                    this._controlList.push(ref)
                                }}
                                page={currentPage}
                                data={{ 'controlType': control.controlType }}
                                width={(width * this.state.scale)}
                                top={top}
                                left={left}
                                name={controlText}
                                helptext={control.tooltip}
                                disabled={this.props.readOnlyView}
                                isRequired={control.required}
                                draggable={(control.controlType === ControlType.SignatureCheckBoxControl ||
                                    control.controlType === ControlType.SignatureRadioButtonControl) ? false : !this.props.readOnlyView}
                                onLoad={this.onControlLoad}
                                onDragStop={(top: number, left: number) => { this.onDragStop(control.id, top, left) }}>

                                {this.getSignatureControlContent(control, controlText)}

                            </ PlaceholderControl>);
                });
        }
        return controlCollection;
    }

    getSignatureControlContent = (control: Control, controlText: string): (JSX.Element) => {
        const controlContent =
            control.controlType === ControlType.SignatureRadioButtonControl
                || control.controlType === ControlType.SignatureCheckBoxControl ? (
                this.choosableControlGroup(control, this.getSelectedStyle(control.controlRole, control.id))
            ) : (
                <div className={'draggable-signature-control ' + this.getSelectedStyle(control.controlRole, control.id)}
                    style={{ height: "100%", width: "auto", fontSize: `${16 * this.state.scale}px` }} title=''>
                    <i className={getControlIcon(control.controlType, control.dataType)}
                        style={{ padding: '5px', background: '#f0f0f1' }}></i>{' '}
                    {controlText}
                    {
                        !this.props.readonly && (control.controlType === ControlType.Signature || (control.controlType === ControlType.Textbox && control.dataType === DataType.Text)) &&
                        <i
                            className='edit-handle fas fa-edit'
                            onClick={() => { this.showControlPropertyPopup(control) }}
                            title=''
                        ></i>
                    }

                    {
                        !this.props.readonly && <span
                            onClick={() => { this.onDeleteControl(control.id) }}
                            aria-disabled={false}
                            className="remove-handle glyphicon glyphicon-remove"
                            style={{ background: "#000", color: "#fff", cursor: 'pointer', fontSize: `${16 * this.state.scale}px`, float: "right", lineHeight: 1, margin: "auto auto auto -10%", position: "relative" }}
                            title=''></span>
                    }
                </div>
            )
        return controlContent;
    }

    private choosableControlGroup = (control: any, selectedSignStyle: any) => {
        let choosableControl = control as SignatureRadioButtonControl | SignatureCheckBoxControl;
        return <div id={'choosable_control_' + control.id} data-id={control.id}
            data-signerstyle={selectedSignStyle}
            className={'choosable-signature-control choosable-signature-control-border'}
            style={{
                height: "100%",
                width: "100%",
            }}
            title=''>
            {this.createChoosableControls(choosableControl, control.id, choosableControl.items)}
            {!this.props.readonly && this.getControlActions(control)}
            {!this.props.readonly && <div className={"radio-group-control-footer"} title=''>
                <button id={control.id + '_draggable-btn'}
                    className={"btn btn-default btn-xs draggable-btn-control"}>
                    <span className={"fas fa-arrows-alt"} title=''></span>
                </button>
            </div>
            }
        </div>
    }
    private createChoosableControls = (control: Control, controlId: string, data:
        ChoosableControlData[]) => {

        let controls: any = [];

        let icon = getControlIcon(control.controlType, control.dataType);

        data.forEach(function (control: ChoosableControlData) {
            controls.push(<div id={control.id} key={"key_" + control.id}
                data-id={control.id} data-controlguid={controlId} className={"radio-btn-container choosable-control-item"} style={{
                    left: control.left + "px", top: control.top + "px"
                }} title=''>
                <i className={icon} style={{ padding: '2px', background: '#f0f0f1' }} title=''></i>
            </div>);
        });
        return controls;
    }
    private getControlActions = (control: Control) => {
        switch (control.controlType) {
            case ControlType.SignatureCheckBoxControl:
            case ControlType.SignatureRadioButtonControl:
                return <><span
                    onClick={() => {
                        this.showControlPropertyPopup(control)
                    }}
                    aria-disabled={!this.props.isAssignedToLoggedinUser}
                    className="edit-handle fas fa-edit"
                    style={{ cursor: 'pointer' }}
                    title=''
                ></span>
                    <span
                        onClick={() => {
                            this.onControlRemove(control)
                        }}
                        aria-disabled={!this.props.isAssignedToLoggedinUser}
                        className="remove-handle glyphicon glyphicon-remove"
                        style={{ cursor: 'pointer' }}
                        title=''
                    ></span></>
            case ControlType.Textbox:
                {
                    if (control.controlType == ControlType.Textbox && control.dataType == DataType.Text) {
                        return <><span
                            onClick={() => {
                                this.showControlPropertyPopup(control)
                            }}
                            aria-disabled={!this.props.isAssignedToLoggedinUser}
                            className="edit-handle fas fa-edit"
                            style={{ cursor: 'pointer' }}
                            title=''
                        ></span>
                            <span
                                onClick={() => {
                                    this.onControlRemove(control)
                                }}
                                aria-disabled={!this.props.isAssignedToLoggedinUser}
                                className="remove-handle glyphicon glyphicon-remove"
                                style={{ cursor: 'pointer' }}
                                title=''
                            ></span></>
                    }
                    else {
                        return <>
                            <span
                                onClick={() => {
                                    this.onControlRemove(control)
                                }}
                                aria-disabled={!this.props.isAssignedToLoggedinUser}
                                className="remove-handle glyphicon glyphicon-remove"
                                style={{ cursor: 'pointer' }}
                                title=''
                            ></span>
                        </>
                    }
                }

            default:
                return <>
                    <span
                        onClick={() => {
                            this.onControlRemove(control)
                        }}
                        aria-disabled={!this.props.isAssignedToLoggedinUser}
                        className="remove-handle glyphicon glyphicon-remove"
                        style={{ cursor: 'pointer' }}
                    ></span>
                </>
        }
    }

    getSelectedStyle = (role: ControlRole, id: string) => {
        if (role == this.state.seletedSigner.role && !this.props.readOnlyView) {
            ControlBase.focusControl('custom_control_' + id, 'organizer-taxpayer-highlight');
            return 'organizer-taxpayer-highlight';
        }
        else {
            ControlBase.removeFocus('custom_control_' + id, 'organizer-taxpayer-highlight');
            return '';
        }
    }

    onSelectFirstPage = (newPages: number[]) => {
        this._viewPanel.gotoPage(newPages[0]);
        this.setState({
            currentPage: newPages[0]
        });
    }

    onDragStop = (id: string, top: number, left: number) => {
        const pageControlList = this.getCurrentPageControls();
        const draggedControl = pageControlList.filter(x => x.id == id)[0];
        let newControl = { ...draggedControl };
        const pageSize: PageSize = this.getCurrentPageSize();
        newControl.boundingRectangle.left = ControlBase.getBackendControlLeftPosition(left);
        newControl.boundingRectangle.top = ControlBase.getBackendControlTopPosition(pageSize.height,
            top + getTopAdjustmentSignatureControlValue(newControl.controlType));
        this.onControlUpdate(draggedControl, newControl);
    }
    onDragEroStop = (control: Control, top: number, left: number) => {
        const draggedControl = this.getCurrentPageControls().filter(x => x.id == control.id.split('_')[1])[0];
        let newControl = { ...draggedControl };
        const pageSize: PageSize = this.getCurrentPageSize();
        newControl.boundingRectangle.left = ControlBase.getBackendControlLeftPosition(left);
        newControl.boundingRectangle.top = ControlBase.getBackendControlTopPosition(pageSize.height,
            top + getTopAdjustmentSignatureControlValue(newControl.controlType));
        this.onControlUpdate(draggedControl, newControl);
    }

    isDocumentTypeNameSet = (additionalDocuments: IAdditionalEsignDocument[]) => {
        let isNameSet: boolean = false;
        let temp: any = [];
        additionalDocuments && additionalDocuments.map((item: any, index: number) => {
            temp.push(item);
            if (item.documentTypeName != null && item.documentTypeName != "") {
                isNameSet = true;
                return;
            }
        });
        return temp.length === 0 ? true : (temp.length > 0 && isNameSet);
    }


    onAddAdditionalDocument = () => {
        this.setState({ showUploadPopup: true });
    }

    getFileExtension(fileName: string) {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    onDocumentClick = (document: AdditionalESignDocumentsUploadData) => {
        let selected = this.state.additionalDocuments.filter(x => x.documentType === document.documentType)[0];
        this.setState({
            selectedDocument: selected.eSignDocuments.filter(x => x.uuid === document.uuid)[0].sasUrl,
            selectedAdditionalEsignDocument: document
        })
    }

    onDocumentEditClick = (document: AdditionalESignDocumentsUploadData) => {
        this.setState({
            showEditModal: true,
            selectedAdditionalFiletoEdit: document
        });
    }

    getDocumentTypeDropdown = () => {
        let options: Options = [];
        options.push({ "value": DocumentType.ConsentForm, "label": DocumentTypeLabels[DocumentType.ConsentForm] });
        options.push({ "value": DocumentType.EngagementLetter, "label": DocumentTypeLabels[DocumentType.EngagementLetter] });
        options.push({ "value": DocumentType.Other, "label": DocumentTypeLabels[DocumentType.Other] });
        return options;
    }

    prepareEsignDocumentsData = (addedEsignDocuments: AdditionalESignDocumentsUploadData[]) => {
        let documentsData: AdditionalESignDocumentsUploadData[] = addedEsignDocuments;
        documentsData = documentsData.filter((item, index) => item.isDeleted == false);
        let result: any = [];
        if (documentsData && documentsData.length > 0) {
            let selectedDocument = {};
            result = Enumerable.from(documentsData).where(x => x != undefined)
                .groupBy(x => x.documentType)
                .select(groupedItem => {
                    return {
                        "documentType": groupedItem.first().documentType,
                        "documentTypeName": DocumentTypeLabels[groupedItem.first().documentType],
                        "eSignDocuments": groupedItem.getSource()
                    }
                }).orderBy(y => y.documentTypeName).toArray();
            this.setState({
                selectedDocument: result[0].eSignDocuments[0].sasUrl,
                selectedAdditionalEsignDocument: result[0].eSignDocuments[0]
            });
        }
        return result;
    }

    submitAdditionalEsignDocs = (data: AdditionalEsignModel[], callback: () => void) => {
        const { submitDocuments } = this.props;
        data.forEach((doc) => {
            doc.uploadedOn = moment.utc(new Date()).format() as any;
        });
        submitDocuments(data);
        callback();
    }

    deleteEsignDocument = (fileGuid: string) => {
        const _self = this;
        const { documentGuid } = this.props;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader();
                    _self.closeEditModal();
                    _self.props.deleteDocument(fileGuid);
                    HideLoader();
                }
            }
        });
    }

    onSaveAdditionalEsignDoc = () => {
        ShowLoader();
        this.closeEditModal();
        this.props.updateDocument(this.convertToAdditionalEsignModel());
        HideLoader();
    }

    closeEditModal = () => {
        this.setState({
            showEditModal: false
        });
    }

    onChangeDocumentType = (value: DocumentType) => {
        this.setState({
            selectedAdditionalFiletoEdit: {
                ...this.state.selectedAdditionalFiletoEdit,
                documentType: value
            }
        });
    }

    convertToAdditionalEsignModel = () => {
        const { selectedAdditionalFiletoEdit } = this.state;
        return {
            id: selectedAdditionalFiletoEdit.id,
            fileGuid: selectedAdditionalFiletoEdit.sasGuid,
            documentType: selectedAdditionalFiletoEdit.documentType,
            fileName: selectedAdditionalFiletoEdit.name,
            sasURL: selectedAdditionalFiletoEdit.sasUrl,
            uploadedOn: moment.utc(new Date()).format() as any,
            documentControls: this.props.additionalEsignDocuments.find(a => a.fileGuid == selectedAdditionalFiletoEdit.sasGuid)?.documentControls
        } as AdditionalEsignModel;
    }

    handleEROSignerChange = (selectedOption: IEROSigner) => {
        if (selectedOption && selectedOption.eroImage !== "") {
            this.setState({
                selectedEROSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
                    eroImage: selectedOption.eroImage
                }
            }, () => {
                this.props.updateEROSignatureStamp && this.props.updateEROSignatureStamp(selectedOption.value);
            })
        }
    }

    private onSignatureControlFocus = (focusedGroup: SignatureGroupType) => {
        this.setState({
            focusedGroup: focusedGroup
        });
    }

    dragStartEroControl = (event: any, controlType: ControlType, signatureRole: ControlRole, dataType: DataType, signerId: number) => {
        if (this.state.selectedEROSigner.eroImage != "") {
            this.registerDropEvents();
            event.dataTransfer.setData('controlType', controlType.toString());
            event.dataTransfer.setData('dataType', dataType.toString());
            event.dataTransfer.setData('signerId', signerId);
            event.dataTransfer.setData('controlRole', signatureRole);
            var element = document.createElement("div");
            element.id = "dragging-element";
            element.style.position = "absolute";
            element.style.top = "-1000px";
            element.innerHTML = '<p class="esignEROStamp" style="border-style: solid; border-width: 1px; max-height: '
                + (22 * this.state.scale).toString() + 'px; font-size: '
                + (12 * this.state.scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px; overflow: hidden;">'
                + this.state.selectedEROSigner.label + '</p>';

            document.body.appendChild(element);
            if (event.dataTransfer && typeof event.dataTransfer.setDragImage === 'function') {
                event.dataTransfer.setDragImage(element, 0, 0);
            } else {
                console.warn("setDragImage is not supported in this environment.");
            }
        }
    }

    render() {
        var _self = this;
        const { readOnlyView,
            organizerClients, demoClient, showSignaturePanel, getUploadLink, documentGuid, deleteBlobAdditionalEsign, readonly } = this.props;
        const { clientSignatureExpand, seletedSigner, selectedAdditionalEsignDocument, selectedAdditionalFiletoEdit
        } = this.state;

        const recievers = !demoClient ? organizerClients.map(client => {
            return {
                value: client.id,
                label: client.name,
                disabled: client.isDeceased,
                role: client.clientType == OrganizerClientType.Taxpayer ? ControlRole.Taxpayer : ControlRole.Spouse
            } as SignerDropDown
        }) : this.getRecievers();


        return (
            <>
                <PdfViewer>
                    {
                        _self.state.additionalDocuments && _self.state.additionalDocuments.length > 0 &&
                        <Header>
                            <Toolbar
                                ref={(ref: any) => this._toolbar = ref}
                                customToolbarPosition={'left'}
                                hideReadOnly={true}
                                hideRightPanel={readOnlyView != undefined ? true : false}
                            >

                                <Pagination />
                                <Zoom />
                                <CustomOptions position="left" />

                            </Toolbar>
                        </Header>
                    }
                    <div className="main" style={{
                        height: 'calc(100vh - 250px)',
                        fontSize: '12px'
                    }}>
                        <LeftPanel>

                            <BookmarkTree
                                sectionTitle="Added Documents"
                                bookMarksLoading={this.isDocumentTypeNameSet(_self.state.additionalDocuments) ? false : true}
                                headerContent={
                                    <button
                                        id="btn-add"
                                        title="Add Document"
                                        onClick={() => { this.onAddAdditionalDocument(); }}
                                        data-test-auto="DBF1C6CF-B54F-4BE1-A864-A240FEE8F5AB"
                                        className="btn-plus btn-success fa fa-plus"
                                        disabled={readonly}>
                                    </button>
                                }
                                bodyContent={
                                    _self.state.additionalDocuments && _self.state.additionalDocuments.map(function (item: any, index: number) {
                                        return <Accordion key={item.documentType}
                                            uniqueId={item.documentType}
                                            header={item.documentTypeName}
                                            content={
                                                <ul style={{ marginBottom: 0 }}>
                                                    {
                                                        item && item.eSignDocuments && item.eSignDocuments.map(function (value: AdditionalESignDocumentsUploadData, index: number) {
                                                            return <li className="custom-list-group-item" style={{ marginRight: 0, paddingRight: 0 }}>
                                                                <Row style={{ padding: 0 }}>
                                                                    <Col sm={9} className="bookmark-name wordbreak ellipsis cursor-pointer"
                                                                        title={(value.name).replace("." + _self.getFileExtension(value.name), '')}
                                                                        style={{ display: "block", paddingRight: 0, marginRight: 0 }}
                                                                        onClick={() => { _self.onDocumentClick(value) }}>
                                                                        {(value.name).replace("." + _self.getFileExtension(value.name), '')}
                                                                    </Col>
                                                                    <Col sm={3} className="cursor-pointer fas fa-edit"
                                                                        style={{ float: "right" }}
                                                                        onClick={() => { !readonly && _self.onDocumentEditClick(value) }}
                                                                        disabled={readonly}>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            }
                                            loading={false} />
                                    })
                                }
                                isNoBodyContent={_self.state.additionalDocuments && _self.state.additionalDocuments.length > 0 ? false : true}
                            />
                            <UploadAdditionalESignModal
                                show={this.state.showUploadPopup}
                                onHide={() => { this.setState({ showUploadPopup: false }); }}
                                getUploadLink={getUploadLink}
                                documentGuid={documentGuid}
                                submitDocuments={this.submitAdditionalEsignDocs}
                                deleteBlobAdditionalEsign={deleteBlobAdditionalEsign}
                                documentTypeOptions={this.getDocumentTypeDropdown()}
                                taxYear={this.props.taxYear}
                                convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                                isBatch={this.props.batchId == undefined ? false : true}
                                convertedWordToPdfFiles={this.props.convertedWordToPdfFiles}
                            />
                            <EditAdditionalESignModal
                                show={this.state.showEditModal}
                                onHide={this.closeEditModal}
                                selectedValue={selectedAdditionalFiletoEdit.documentType}
                                dropDownOptions={this.getDocumentTypeDropdown()}
                                fileGuid={selectedAdditionalFiletoEdit.sasGuid}
                                onDelete={this.deleteEsignDocument}
                                onSave={this.onSaveAdditionalEsignDoc}
                                onChangeDropdown={this.onChangeDocumentType}
                            />

                        </LeftPanel>

                        <ControlLayer enableControlsLazyLoad={false} ref={(ref: any) => { this._controlLayer = ref; }} useDefaultNavigationStartControl={false} >
                            {
                                this.createControls()
                            }
                        </ControlLayer>
                        {_self.state.additionalDocuments && _self.state.additionalDocuments.length > 0 &&
                            <ViewPanel 
                                onDocumentLoad={() => {
                                    this.setState({
                                        scale: 1,
                                        currentPage: 1
                                    })
                                }}
                                ref={(ref: any) => this._viewPanel = ref}
                                pdfWorkerSource = {`${process.env.PUBLIC_URL}/pdf.worker.min.mjs`}
                                onPageChanged={this.onPageChange}
                                onScaleChanged={(scale: number) => {
                                    this.setState({
                                        scale: scale
                                    })
                                }}
                                pageMode={PageMode.SinglePage}
                                defaultPage={this.state.currentPage}
                                pdfSource={PdfSource.createFromUrl(this.state.selectedDocument)}
                                disableTextLayer={true}
                            >
                            </ViewPanel>
                        }
                        {

                            (_self.state.additionalDocuments && _self.state.additionalDocuments.length > 0 && showSignaturePanel && !readonly) &&
                            <RightPanel>
                                {
                                    <SignaturePanel
                                        expanded={clientSignatureExpand}
                                        group={UserGroup.Reciever}
                                        title={"e-Signatures"}
                                        toggleExpand={this.toggleClientSignatureExpand}
                                        onDragEnd={this.dragEnd}
                                        onDragStart={this.dragStartSignatureControl}
                                        onSignerChange={(id: any) => {
                                            !demoClient ? this.onSignerChange(id) : this.onDemoSignerChange(id)
                                        }}
                                        selectedSigner={seletedSigner}
                                        signers={recievers}
                                        onFocusChange={this.onFocusChange}
                                        addCustomInputControls={true}
                                    />
                                }

                                {this.props.company.companySettings?.signatureSettingsModel?.useSignatureStamp && !readonly &&
                                    <EROSignatureCard
                                        title="ERO Signature Stamp"
                                        selectedEROSigner={this.state.selectedEROSigner}
                                        eroSigner={this.state.eroSigners}
                                        onEROSignerChange={this.handleEROSignerChange}
                                        isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                                        onSignatureControlFocus={this.onSignatureControlFocus}
                                        dragStart={this.dragStartEroControl}
                                        dragEnd={this.dragEnd}
                                        signerId={this.state.seletedSigner.value}
                                        group={UserGroup.Sender}
                                        onFocusChange={this.onFocusChange}
                                        toggleExpand={this.toggleSenderSignatureExpand}
                                    />
                                }
                            </RightPanel>
                        }
                    </div>
                </PdfViewer>
                <SignerTooltipPopup
                    control={this.state.toolTipControl}
                    showState={this.state.showToolTipPopup}
                    onHide={this.onCancelToolTipPopup}
                    onUpdateToolTipControl={this.onUpdateToolTipPopupControl}
                    submitButtonClick={this.props.onSaveToolTip}
                    initialControl={initialControl}
                    content={this.state.content}
                    controlType={this.state.signatureControlType}
                    dataType={this.state.signatureDataType}
                    pageNo={this.state.currentPage}
                    documentGuid={this.state.selectedAdditionalEsignDocument.sasGuid}
                    pageType={OrganizerProcessPage.AdditionalEsign}
                />
            </>

        );
    }
}


