import { IPartnerModel } from '../../Core/ViewModels/User/UserViewModel';


export function convertPartnersToDropDownList(partners: IPartnerModel[]) {
    var partnersList: any = [];
    partners?.map((partner: IPartnerModel) => {
        let name = partner.lastName ? `${partner.firstName} ${partner.lastName}` : partner.firstName;
        partnersList.push({
            value: partner.id,
            label: name
        })
    });
    if (partnersList.length > 0) {
        partnersList = partnersList.sort((a: any, b: any) => {      //Sorting
            if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) {
                return 1;
            }
            if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) {
                return -1;
            }
            return 0;
        });
    }
    return partnersList;
}