import * as React from 'react';
import { TabContentContainer } from './../../../../../../components/common/ProcessReturnModal/Tabs/TabContentContainer/TabContentContainer';
import { Col, Row, FormLabel, Button } from 'react-bootstrap';
import { DropdownComponent } from './../../../../../../components/common/controls/DropdownComponent';
import { ICompanyData } from './../../../../../../store/company/CompanyStore';
import { IUserModel } from './../../../../../../Core/ViewModels/User/UserViewModel';
import { PreparerMessage, OrganizerCompanySettings, OrganizerClientType, OrganizerClient, OrganizerDocumentSettings, OrganizerDocument } from './../../../../../models/OrganizerComponentModels';
import * as Enumerable from 'linq';
import { DeliveryPreparerMessageModal } from './../../../Modals/DeliveryTabPreparerMessageModal';
import { VenusNotifier } from './../../../../../../components/helper/VenusNotifier';
import { SavedMessageConstants } from './../../../../../../components/helper/Constants';
import { getVariableList, sanitize, replaceHelperVariable, replaceHelperVariableForEditor, validateLocationData, validateEROData } from './../../../../Helper/OrganizerHelperFunctions';
import { DefaultSenderInfoType } from './../../../../../../components/navigation/profile/MySettings';
import DeliveryRemindersPanel from './Panels/DeliveryRemindersPanel';
import { FormGroup } from './../../../../../models/OrganizerComponentModels';
import 'react-select/dist/react-select.css';

const htmlencode = require('htmlencode');
const CustomMessage = "Custom message";

interface DeliveryOptionsTabProps {
    users: IUserModel[];
    company: ICompanyData;
    companySettings: OrganizerCompanySettings;
    preparerMessages: PreparerMessage[];
    documentSettings: OrganizerDocumentSettings;
    updateSettings: (settings: OrganizerDocumentSettings) => void;
    updateClients: (clients: OrganizerClient[]) => void;
    engagementLetterExists: boolean;
    additionalFileExists: boolean;
    readOnlyView?: boolean;
    organizerClients: OrganizerClient[];
    demoClient?: boolean;
    formGroups?: any;
    firstSigner?: OrganizerClientType;
    updateFirstSigner?: (client: OrganizerClientType) => void;
    generateTaxpayerView: (callback: () => void) => void;
    document: OrganizerDocument;
    isEsignLetterEnabled?: (formgroups: FormGroup[]) => boolean;
}

interface DeliveryOptionsTabState {
    contactPerson: any[];
    message: any[];
    sender: any[];
    selectedContactPerson?: number;
    selectedMessage: number;
    isAllowToEdit: boolean;
    selectedMessageId: number;
    customPlaceHolder: string;
    preparerMessageShow: boolean;
    prepareMessageMode: PrepareMessageMode;
    prepareMessage: string;
    selectedSender: number;
    isVariableListExpand: boolean;
    disableTaxPayerView: boolean;
    reminderOptions: any;
    disableTaxPayerPreview: boolean,
    isTaxpayerDeceased: boolean
}

interface SelectOptions {
    value: any;
    label: string;
}

enum PrepareMessageMode {
    Add,
    Update
}

export class DeliveryOptionsTab extends React.Component<DeliveryOptionsTabProps, DeliveryOptionsTabState>
{
    constructor(props: DeliveryOptionsTabProps) {
        super(props);
        let taxpayer = this.props.organizerClients.find(u => u.clientType == OrganizerClientType.Taxpayer);
        this.state = {
            contactPerson: [],
            message: [],
            sender: [],
            selectedContactPerson: 0,
            selectedMessage: 0,
            isAllowToEdit: false,
            selectedMessageId: 0,
            customPlaceHolder: "Select...",
            preparerMessageShow: false,
            prepareMessageMode: 0,
            prepareMessage: "",
            selectedSender: 0,
            isVariableListExpand: false,
            disableTaxPayerView: false,
            reminderOptions: {},
            disableTaxPayerPreview: this.props.organizerClients.length > 0 && this.props.organizerClients.every(u => u.isDeceased),
            isTaxpayerDeceased: this.props.organizerClients.length > 0 && taxpayer !== undefined && taxpayer.isDeceased
        }
    }

    componentWillMount() {
        this.setContactDropdown();
        this.setSenderDropdown();
        this.setMessageDetailsDropDown();
        this.setReminders();
    }

    setReminders = () => {
        const { documentSettings } = this.props;
        this.setState({ reminderOptions: documentSettings?.reminderSettings })
    }

    setMessageDetailsDropDown = () => {
        const { documentSettings, preparerMessages, companySettings: { defaultSettings: { savedMessage } } } = this.props;
        let _messageDetails: SelectOptions[] = [];

        preparerMessages.map((message, index) => {
            _messageDetails.push({
                value: message.id,
                label: message.name
            })
        });


        let defaultMessageDetails = documentSettings.deliverySettings.preparerMessage ?
            documentSettings.deliverySettings.preparerMessage.id
            : savedMessage ? savedMessage : 0;

        const defaultMessage = preparerMessages.find(message => message.id === defaultMessageDetails)

        let selectedMessageValue: number = 0;
        let isAllowToEdit: boolean = false;
        if (documentSettings.deliverySettings.preparerMessage &&
            documentSettings.deliverySettings.preparerMessage.id == 0) {
            selectedMessageValue = documentSettings.deliverySettings.preparerMessage.id;
            isAllowToEdit = documentSettings.deliverySettings.preparerMessage.isAllowToEdit;
        }
        else {
            if (defaultMessage) {
                selectedMessageValue = defaultMessage.id;
                isAllowToEdit = defaultMessage.isAllowToEdit;
            }
        }

        this.setState({
            message: _messageDetails,
        }, () => {
            this.setState({
                selectedMessage: selectedMessageValue,
                isAllowToEdit: isAllowToEdit
            })
        });
    }

    setContactDropdown = () => {
        const { users, documentSettings } = this.props;
        const index = Enumerable.from(users).indexOf(x => x.id == documentSettings.deliverySettings.contactPersonId)
        const _contactPerson: any = []
        for (let i = 0; i < users.length; i++) {
            _contactPerson.push({
                value: users[i] ? users[i].id : 0,
                label: users[i] ? users[i].firstName + " " + users[i].lastName : ""
            })
        }
        this.setState({
            contactPerson: _contactPerson,
            selectedContactPerson: users[index] ? users[index].id : 0
        });
    }

    setSenderDropdown = () => {
        const { users, documentSettings, company: { companyProfile: { companyInfo: { companyName } } } } = this.props;
        let index: number;
        if (documentSettings.deliverySettings.sender.senderType == DefaultSenderInfoType.User) {
            if (documentSettings.deliverySettings.sender.senderId !== null) {
                index = (Enumerable.from(users).indexOf(x => x.id === documentSettings.deliverySettings.sender.senderId)) + 1
            }
            else {
                index = -1;
            }
        }
        else {
            index = 0;
        }
        var _sender: any = []
        _sender.push({
            value: 0,
            label: companyName
        })
        for (let i = 0; i < users.length; i++) {
            _sender.push({
                value: users[i] ? users[i].id : 0,
                label: users[i] ? users[i].firstName + " " + users[i].lastName : ""
            })
        }
        this.setState({
            sender: _sender
        }, () => (this.setState({
            selectedSender: index >= 0 ? this.state.sender[index].value : 0
        })));
    }

    handleMessageChange = (value: any) => {
        const { documentSettings, preparerMessages } = this.props;
        if (!value) {
            documentSettings.deliverySettings.preparerMessage = {
                id: 0,
                name: "",
                body: "",
                isAllowToEdit: false
            };
            this.setState({
                selectedMessage: 0
            });
            return;
        }

        const selectedMessage = preparerMessages.find(message => message.id === value);
        if (selectedMessage) {
            if (value) {
                this.setState({
                    selectedMessage: value,
                    isAllowToEdit: selectedMessage.isAllowToEdit,
                    selectedMessageId: value
                });
            }
            documentSettings.deliverySettings.preparerMessage = selectedMessage;
        }
        this.props.updateSettings(documentSettings);
    }

    handleContactPersonChange = (value: number) => {
        if (value === null) {
            value = 0;
        }
        this.props.documentSettings.deliverySettings.contactPersonId = value
        this.setState({ selectedContactPerson: value });
        this.props.updateSettings(this.props.documentSettings);
    }

    preparerMessageHide = () => {
        this.setState({
            preparerMessageShow: false
        })
    }

    onEditButtonClick = () => {
        this.setState({
            preparerMessageShow: true,
            prepareMessageMode: PrepareMessageMode.Update,
            prepareMessage: this.props.documentSettings.deliverySettings.preparerMessage.body
        })
    }

    onAddButtonClick = () => {
        this.setState({
            preparerMessageShow: true,
            prepareMessageMode: PrepareMessageMode.Add,
            prepareMessage: ""
        })
    }

    onChangeEditor = (event: any) => {
        this.setState({
            prepareMessage: event
        })

    }

    preparerMessageUpdateButtonClick = (event: any) => {
        if (this.state.prepareMessage == "" || this.state.prepareMessage.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() == "") {
            VenusNotifier.Error(SavedMessageConstants.ValidateBody, "Error");
            return;
        }
        else if (this.state.prepareMessage != '') {
            this.props.documentSettings.deliverySettings.preparerMessage.body = htmlencode.htmlEncode(this.state.prepareMessage ? this.state.prepareMessage : "");
            this.props.updateSettings(this.props.documentSettings);
            VenusNotifier.Success(SavedMessageConstants.UpdateMessageSuccess, "Success");
        }
        this.setState({
            preparerMessageShow: false
        })
    }

    savePreparerMessage = (event: any) => {
        const newMessage = this.state.prepareMessage;
        if (newMessage === "" || newMessage.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() == "") {
            VenusNotifier.Error(SavedMessageConstants.ValidateBody, "Error");
            return;
        }
        else if (newMessage !== '') {
            this.props.documentSettings.deliverySettings.preparerMessage = ({
                id: 0,
                name: newMessage,
                body: htmlencode.htmlEncode(this.state.prepareMessage),
                isAllowToEdit: true
            });
            this.props.updateSettings(this.props.documentSettings);
            VenusNotifier.Success(SavedMessageConstants.SaveMessageSuccess, "Success");
        }
        this.setState({
            preparerMessageShow: false,
            prepareMessage: '',
            selectedMessage: 0,
            customPlaceHolder: CustomMessage,
            isAllowToEdit: true
        })
    }

    handleSenderChange = (value: any) => {
        const { documentSettings } = this.props;
        if (value === 0) {
            documentSettings.deliverySettings.sender.senderType = DefaultSenderInfoType.Company
            documentSettings.deliverySettings.sender.senderId = 0
        }
        else {
            documentSettings.deliverySettings.sender.senderType = DefaultSenderInfoType.User
            documentSettings.deliverySettings.sender.senderId = value
        }
        this.setState({ selectedSender: value });
        this.props.updateSettings(documentSettings);
    }

    handleELUserChange = (value: any) => {
        this.props.documentSettings.notificationSettings.notifyELCompletedUser = value
        this.props.updateSettings(this.props.documentSettings);
    }

    handleOrganizerUserChange = (value: any) => {
        this.props.documentSettings.notificationSettings.notifyOrganizerCompletedUser = value
        this.props.updateSettings(this.props.documentSettings);
    }

    handleUploadedFileUserChange = (value: any) => {
        this.props.documentSettings.notificationSettings.notifyUploadedFilesUser = value;
        this.props.updateSettings(this.props.documentSettings);
    }

    toggleVariableList = () => {
        this.setState(prevState => ({
            isVariableListExpand: !prevState.isVariableListExpand
        }))
    }

    handleDeliverFirstToChange = (type: OrganizerClientType) => {
        const clients = this.props.organizerClients.map(client => {
            if (client.clientType === type) {
                client.signingOrder = 1;
                return client;
            }
            return { ...client, signingOrder: 2 };
        })
        this.props.updateClients(clients);
    }

    handleDemoClientChange = (type: OrganizerClientType) => {
        this.props.updateFirstSigner && this.props.updateFirstSigner(type);
    }

    generateTaxPayerView = () => {
        
        if (!validateLocationData(this.props.document)) {
            return;
        }
        
        if (!validateEROData(this.props.document)) {
            return;
        }

        if (this.props.documentSettings.deliverySettings.contactPersonId === null || this.props.documentSettings.deliverySettings.contactPersonId == 0) {
            VenusNotifier.Warning(`Please select contact person`, null);
            return;
        }
        this.setState({
            disableTaxPayerView: true
        })
        this.props.generateTaxpayerView && this.props.generateTaxpayerView(() => {
            this.setState({
                disableTaxPayerView: false
            })
        });
    }

    updateReminders = (key1: string, key2: string, value: number) => {
        const { reminderOptions } = this.state;
        reminderOptions[key1][key2] = value;
        this.setState({ reminderOptions: reminderOptions });
        this.props.documentSettings.reminderSettings = reminderOptions;
        this.props.updateSettings(this.props.documentSettings);
    }

    render() {
        const { documentSettings, preparerMessages, engagementLetterExists, isEsignLetterEnabled, formGroups,
            readOnlyView,
            documentSettings:
            { notificationSettings: { notifyELCompletedUser, notifyOrganizerCompletedUser, notifyUploadedFilesUser } },
            additionalFileExists } = this.props;
            const { isVariableListExpand, disableTaxPayerView, disableTaxPayerPreview, isTaxpayerDeceased } = this.state;
        const isOrganizerSignatureEnabled = (isEsignLetterEnabled && isEsignLetterEnabled(formGroups)) || false;
        let messageBody = "";
        if (documentSettings.deliverySettings.preparerMessage && documentSettings.deliverySettings.preparerMessage.body) {
            messageBody = documentSettings ? documentSettings.deliverySettings.preparerMessage.body : "";
        }
        else {
            const selectedMessage = preparerMessages.find(message => message.id === this.state.selectedMessage);
            if (selectedMessage) {
                messageBody = selectedMessage.body ?
                    selectedMessage.body
                    : "";
            }

        }
        messageBody = htmlencode.htmlDecode(messageBody);

        return <TabContentContainer enableScroll={true}>
            <Col sm={12}>
                <Row>
                    <Col sm={12}>
                        <div className="form-horizontal marL20">
                            <Row className="marB15">
                                <Col sm={12}>
                                    <div className="col-sm-3" style={{ display: "inline-flex", marginRight: 5, paddingLeft: "0px" }}>
                                        <h4 className="header-delivery-options" style={{ marginRight: 5 }}>
                                            <i className="modalIcon fas fa-user-circle" style={{ marginRight: 7 }}></i>
                                            Taxpayer Dashboard
                                        </h4>
                                    </div>
                                    <Button type="button" style={{ marginTop: 5, marginLeft: 4, backgroundColor: "#21a9e1" }} bsStyle="info"
                                        onClick={this.generateTaxPayerView}
                                        disabled={disableTaxPayerView || disableTaxPayerPreview || isTaxpayerDeceased} 
                                        title={disableTaxPayerPreview ? `Cannot preview organizer, all organizer client(s) are deceased` : isTaxpayerDeceased ? 'Cannot preview organizer, taxpayer is deceased' : ''}>
                                        <i className={disableTaxPayerView ? 'fas fa-spinner fa-spin text-white bg-transparent' : 'glyphicon glyphicon-eye-open'} aria-hidden="true"></i>
                                        Taxpayer Preview
                                    </Button>
                                    <hr />
                                </Col>

                            </Row>
                            <Row className="marB15">
                                <Col className="text-left" sm={3} as={FormLabel}>
                                    Contact Person:
                                </Col>
                                <Col sm={5} className={"zeroborderRadius"}>
                                    <DropdownComponent
                                        id="ddlContactPerson"
                                        options={this.state.contactPerson}
                                        onChange={this.handleContactPersonChange}
                                        selectedValue={this.state.selectedContactPerson}
                                        clearable={false}
                                        disabled={readOnlyView}
                                    />
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="text-left" sm={3} as={FormLabel}>
                                    Message From Preparer:
                                </Col>

                                <Col sm={5} className={"zeroborderRadius"}>
                                    <DropdownComponent
                                        id="ddlPreparer"
                                        options={this.state.message}
                                        onChange={this.handleMessageChange}
                                        customPlaceHolder={this.state.selectedMessageId > 0 ? this.state.customPlaceHolder : CustomMessage}
                                        selectedValue={this.state.selectedMessage}
                                        clearable={true}
                                        disabled={readOnlyView}
                                    />
                                </Col>
                                <Col sm={3}>
                                    {!readOnlyView &&
                                        <Button type="button"
                                            title="Add New Message"
                                            variant="success"
                                            data-test-auto="2599FBD9-2565-45DB-AF1F-7250D3DB45F7"
                                            style={{ paddingRight: 20, width: 76, }}
                                            onClick={this.onAddButtonClick}
                                        ><i className="fa fa-plus" style={{ paddingRight: 10 }}></i>
                                            New
                                        </Button>
                                    }
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col sm={3} >
                                </Col>
                                <Col sm={5}>
                                    <div dangerouslySetInnerHTML={{ __html: sanitize(replaceHelperVariable(messageBody)) }}
                                        className="selected-message-view" style={{ padding: 9 }}>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    {!readOnlyView && ((this.state.message.length > 0 && this.state.selectedMessage != 0) || (this.state.isAllowToEdit && messageBody != "")) &&
                                        <Button type="button"
                                            title={this.state.isAllowToEdit ? "Edit Message": "View Message"}
                                            variant="info"
                                            style={{ paddingRight: "inherit", width: 76, marginLeft: 2 }}
                                            data-test-auto="2599FBD9-2565-45DB-AF1F-7250D3DB45F7"
                                            onClick={this.onEditButtonClick}
                                        >
                                            <i className="fas fa-edit" aria-hidden="true"></i>
                                            {this.state.isAllowToEdit ? "Edit" : "View"}
                                        </Button>
                                    }
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col sm={12} className="new-main-content">
                                    <h4 className="header-delivery-options">
                                        <i className="modalIcon fas fa-envelope"></i>
                                        Email Notifications
                                    </h4>
                                    <hr style={{ marginTop: -2 }} />
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="text-left" sm={3} as={FormLabel}>
                                    Sender Name:
                                </Col>
                                <Col sm={5} className={"zeroborderRadius"}>
                                    <DropdownComponent
                                        id="ddlSender"
                                        options={this.state.sender}
                                        onChange={this.handleSenderChange}
                                        selectedValue={this.state.selectedSender}
                                        clearable={false}
                                        disabled={readOnlyView}
                                    />
                                </Col>
                            </Row>
                            {
                                (engagementLetterExists || additionalFileExists || isOrganizerSignatureEnabled) &&
                                <Row className="marB15">
                                    <Col className="text-left" sm={3} as={FormLabel}>
                                        Notify About Completed Signature Document
                                    </Col>
                                    <Col sm={5} className={"zeroborderRadius"}>
                                        <DropdownComponent
                                            id="notifyELCompletedUser"
                                            options={this.state.contactPerson}
                                            onChange={this.handleELUserChange}
                                            selectedValue={notifyELCompletedUser}
                                            clearable={false}
                                            disabled={readOnlyView}
                                        />
                                    </Col>
                                </Row>
                            }
                            <Row className="marB15">
                                <Col className="text-left" sm={3} as={FormLabel}>
                                    Notify About Organizer Events
                                </Col>
                                <Col sm={5} className={"zeroborderRadius"}>
                                    <DropdownComponent
                                        id="notifyOrganizerCompletedUser"
                                        options={this.state.contactPerson}
                                        onChange={this.handleOrganizerUserChange}
                                        selectedValue={notifyOrganizerCompletedUser}
                                        clearable={false}
                                        disabled={readOnlyView}
                                    />
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="text-left" sm={3} as={FormLabel}>
                                    Notify About Uploaded Files
                                </Col>
                                <Col sm={5} className={"zeroborderRadius"}>
                                    <DropdownComponent
                                        id="notifyUploadedFilesUser"
                                        options={this.state.contactPerson}
                                        onChange={this.handleUploadedFileUserChange}
                                        selectedValue={notifyUploadedFilesUser}
                                        clearable={false}
                                        disabled={readOnlyView}
                                    />
                                </Col>
                            </Row>
                            <DeliveryRemindersPanel
                                reminderSettings={this.state.reminderOptions}
                                formGroups={this.props.formGroups}
                                additionalFileExists={additionalFileExists}
                                updateReminders={this.updateReminders}
                                readOnlyView={!!readOnlyView}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>

            {this.state.prepareMessageMode === PrepareMessageMode.Add ?
                <DeliveryPreparerMessageModal
                    changeStateEditorBody={this.onChangeEditor}
                    onHide={this.preparerMessageHide}
                    showState={this.state.preparerMessageShow}
                    messageBody={""}
                    isAllowEdit={true}
                    updateButtonClick={this.preparerMessageUpdateButtonClick}
                    saveButtonClick={this.savePreparerMessage}
                    buttonText={"Save"}
                    variableList={getVariableList()}
                    isVariableListExpand={isVariableListExpand}
                    onVariableListExpandToggle={this.toggleVariableList}
                />
                :

                <DeliveryPreparerMessageModal
                    changeStateEditorBody={this.onChangeEditor}
                    onHide={this.preparerMessageHide}
                    showState={this.state.preparerMessageShow}
                    messageBody={replaceHelperVariableForEditor(messageBody)}
                    isAllowEdit={this.state.isAllowToEdit}
                    updateButtonClick={this.preparerMessageUpdateButtonClick}
                    saveButtonClick={this.savePreparerMessage}
                    buttonText={"Update"}
                    variableList={getVariableList()}
                    isVariableListExpand={isVariableListExpand}
                    onVariableListExpandToggle={this.toggleVariableList}
                />
            }
        </TabContentContainer>
    }
}
