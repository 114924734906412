import * as bootbox from 'bootbox';
import * as React from 'react';
import { Button, FormControl, Modal, ProgressBar } from 'react-bootstrap';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { UploadStatus } from '../../../../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { checkIfAnyFileIsEncrypted, getFileExtension, GetSafeFilename, isWordFile, validateAdditionalEsignUploadFileType, validateFileSize, validateFileNameCharacterLimit, validateFileType, validatePdfFileContent, validateWordAndPDFFileContent } from '../../../../../../../components/helper/Validations';
import { GetFileMagicNumber, getFileSize, isFileExistDuplicateName } from '../../../../../../../components/helper/HelperFunctions';
import { VenusNotifier } from '../../../../../../../components/helper/VenusNotifier';
import { FileExtensions, UploadtaxReturnConstants, ValidationContants, FileNameCharacterLimitExceeds } from '../../../../../../../components/helper/Constants';
import DocumentUploadDropzoneComponent from '../../../../../../../components/common/UploadDocument/DocumentUploadDropzoneComponent';
import { UploadedDocumentTable } from '../../../../../Reports/UploadDocumentsModal/UploadedDocumentTable';
import { HideLoader, ShowLoader } from '../../../../../../../components/helper/Loader';
import { organizerSubmitSuccess, PreparerMessageConstants, StoreConstants } from '../../../../../Helper/OrganizerConstants';
import { OrganizerUploadState } from '../../../../../../models/OrganizerStoreModels';
import { AdditionalESignDocumentsUploadData, AdditionalEsignModel, DocumentType } from '../../../../../../models/OrganizerComponentModels';
import DropdownComponent from '../../../../../../../components/common/controls/DropdownComponent';
import { FileModel } from '../../../../../../../Core/ViewModels/Common/FileModel';
import { isFileExist, UploadFunctions } from '@sssuite-js-packages/file-utility';
import store from 'src/configureStore';
import { actionTypes } from 'src/store/ActionTypes';
import { IConvertedPdfFileList } from 'src/Organizer/store/AdditionalEsignDocumentStore';
import { StatusType } from 'src/store/common/NotificationStore';

const MAXFILELIMIT = 30;
export interface UploadAdditionalESignProps {
    onHide: () => void;
    show: boolean;
    submitDocuments: (data: AdditionalEsignModel[], callback: () => void) => void;
    getUploadLink: (callback: (data?: any) => void) => void;
    documentGuid: string;
    deleteBlobAdditionalEsign: (fileGuid: string, callback: () => void) => void;
    documentTypeOptions: any;
    convertDocToPdfArtifactAsync: (documentGuid: string, taxYear: number, fileName: string, isBatch: boolean, callback?: (data?: any) => void) => void;
    taxYear: number;
    isBatch: boolean;
    convertedWordToPdfFiles: IConvertedPdfFileList;
}

interface UploadAdditionalESignModalState {
    disableSubmit: boolean;
    uploadData: AdditionalESignDocumentsUploadData[];
    sasContainer: any[];
}

export class UploadAdditionalESignModal extends React.Component<UploadAdditionalESignProps, UploadAdditionalESignModalState> {
    constructor(props: UploadAdditionalESignProps) {
        super(props);
        this.state = {
            disableSubmit: false,
            uploadData: [],
            sasContainer: []
        }
    }

    componentDidUpdate(): void {
        if (this.props?.convertedWordToPdfFiles?.convertedFiles?.length > 0) {
            this.updateConvertedDocToPdf(this.props.convertedWordToPdfFiles);
        }
    }

    componentConfig = {
        dropzoneSelector: 'div.filepicker',
        iconFiletypes: ['.pdf'],
        showFiletypeIcon: true,
        postUrl: "/api/"
    }

    djsConfig = {
        uploadMultiple: true,
        addRemoveLinks: true,
        headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false
    }

    eventHandlers = {
        init: () => {
            this.reset();
        },
        addedfiles: (files: any) => {
            try {
                const _self = this;
                _self.validateFileCount(files);
                let uploadData: AdditionalESignDocumentsUploadData[] = _self.convertToModel(files);
                uploadData = _self.handleDuplicates(uploadData);
                if (uploadData.length > 0) {
                    _self.validateFileContent(uploadData).then((result) => {
                        const fileModels: FileModel[] = [];
                        uploadData.map(obj => fileModels.push({ id: obj.id, name: obj.name, file: obj.file } as FileModel));
                        checkIfAnyFileIsEncrypted(fileModels).then((fileModels) => {
                            const anyFileEncrypted = fileModels.length != uploadData.length;
                            const uploadedFilesCount = _self.state.uploadData.length;
                            let disableSubmit = true;
                            if (anyFileEncrypted && uploadedFilesCount > 0) {
                                disableSubmit = false;
                            }

                            result = result.filter(m => fileModels.some(x => x.id == m.id));
                            _self.setState({
                                uploadData: _self.state.uploadData.concat(result),
                                disableSubmit: disableSubmit
                            }, () => {
                                _self.getUploadLink();
                            });
                        })
                    });
                }
            }
            catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    }

    validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.uploadData.length > MAXFILELIMIT) {
            throw new Error(`You cannot upload more than ${MAXFILELIMIT} files.`);
        }
    }

    handleDuplicates = (uploadData: AdditionalESignDocumentsUploadData[]): AdditionalESignDocumentsUploadData[] => {
        for (let i = 0; i < uploadData.length; i++) {
            const file = uploadData[i];
            let fileName: string = GetSafeFilename(file.name) || "";
            const fileExtension = getFileExtension(fileName);
            fileName = fileName.replace(`.${fileExtension}`, '') + FileExtensions.Pdf;

            if (isFileExistDuplicateName(fileName, uploadData)) {
                throw new Error(`You cannot upload file having duplicate filename ${fileName}.`);
            }

            let filecount = 1;
            while (isFileExist(fileName, this.state.uploadData)) {
                fileName = file.name || "";
                fileName = fileName.replace(`.${fileExtension}`, '');
                fileName = fileName + ` (${filecount})` + FileExtensions.Pdf;
                filecount++;
            }
            uploadData[i].name = fileName;
        }
        return uploadData;
    }

    validateFileContent = (uploadData: AdditionalESignDocumentsUploadData[]): Promise<any> => {
        let promise: any = null;
        const _uploadData: AdditionalESignDocumentsUploadData[] = Object.assign({}, uploadData);
        for (let i = 0; i < uploadData.length; i++) {
            let uploadedFile = uploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    if (!validateWordAndPDFFileContent(result)) {
                        if (result == "") {
                            VenusNotifier.Warning(ValidationContants.UploadAdditionalSignSizeWarning, null);
                        } else {
                            VenusNotifier.Warning(ValidationContants.UploadAdditionalSignFileWarning, null);
                        }
                        const index = uploadData.findIndex(x => x.name == _uploadData[i].name)
                        uploadData.splice(index, 1)
                    }

                    resolve(result)
                });
            });
        }
        return promise.then(() => { return uploadData })
    }

    convertToModel = (uploadedFiles: any): AdditionalESignDocumentsUploadData[] => {
        const uploadData: AdditionalESignDocumentsUploadData[] = [];
        let failedUploadFilecounter = 0;
        for (let i = 0; i < uploadedFiles.length; i++) {
            const uploadedFile = uploadedFiles[i];
            if (validateFileNameCharacterLimit(uploadedFile)) {
                if (validateFileSize(uploadedFile)) {
                    const tmpFileName = GetSafeFilename(uploadedFiles[i].name);
                    const fileExtension = getFileExtension(tmpFileName);
                    if (validateAdditionalEsignUploadFileType(fileExtension)) {
                        uploadData.push({
                            name: tmpFileName,
                            progressBar: 0,
                            size: getFileSize(uploadedFile.size),
                            status: UploadStatus.Wait,
                            sasGuid: "",
                            sasUrl: "",
                            gridRowDisable: true,
                            rowIndex: (uploadData.length +
                                this.state.uploadData.length) + 1,
                            file: uploadedFile,
                            documentType: DocumentType.None,
                            isPDFloaded: false,
                            uuid: "",
                            id: i
                        });
                    }
                }
            }
            else {
                failedUploadFilecounter++;
            }
        }
        if (failedUploadFilecounter > 0) {
            VenusNotifier.Error(FileNameCharacterLimitExceeds(failedUploadFilecounter, uploadedFiles.length), null);
        }
        return uploadData;
    }

    getUploadLink = () => {

        const _self = this;
        const { documentGuid } = _self.props;
        const uploadHelperFunctions = new UploadFunctions();
        this.state.uploadData.filter(x => x.status == UploadStatus.Wait).forEach((file: AdditionalESignDocumentsUploadData, index: number) => {

            file.status = UploadStatus.Initiating;

            this.props.getUploadLink((data?: OrganizerUploadState) => {

                try {

                    if (data) {

                        file.sasGuid = data ? data.guid : "";
                        file.status = UploadStatus.Uploading;

                        _self.setState({ uploadData: _self.state.uploadData },
                            () => uploadHelperFunctions.uploadFile(file.file, data, file.name ? file.name : "",
                                _self.uploadProgressCallback, (fileToUpload: any) => { _self.uploadCommitCallBack(fileToUpload, file.sasGuid) }));
                    } else {
                        throw new Error("Upload link not found !!");
                    }

                } catch (error) {
                    _self.state.uploadData.splice(index, 1);
                    _self.setState({ uploadData: _self.state.uploadData });
                }

            });

        });

    }
    onDropDownOpen = (row: any) => {
        let content = document.getElementById('uploadDocument');
        // enabling autoscroll only for the last 3 rows
        if (content && (row.rowIndex > this.state.uploadData.length - 3) && (content.scrollHeight > content.clientHeight || content.scrollTop > 0)) {
            content.scrollTo({ left: 0, top: content.scrollHeight });
        }
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    selectFormat = (cell: any, row: any) => {
        return <DropdownComponent
            id="ddlUploadDocumentType"
            options={this.props.documentTypeOptions}
            selectedValue={cell}
            onChange={(e: any) => this.handleDocumentType(row, e)}
            onOpen={() => this.onDropDownOpen(row)}
            customPlaceHolder="Document Type"
            disabled={row.gridRowDisable}
            clearable={false}
        />
    }

    fileNameFormatter = (cell: any, row: any) => {
        return <FormControl type="text" disabled={row.gridRowDisable}
            value={cell}
            onChange={(e: any) => this.onFileNameChange(e, row)}
            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
    }

    onFileNameChange = (event: any, row: any) => {
        const _gridData = [...this.state.uploadData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.name = event.target.value
            }
        });

        this.setState({ uploadData: _gridData });
    }

    handleDocumentType = (row: any, value: any) => {
        var _gridData = [...this.state.uploadData];

        _gridData.map((gridData, index) => {
            if (gridData.rowIndex == row.rowIndex) {
                gridData.documentType = (value == null || value == undefined) ? 0 : value;
            }
        });
        this.setState({ uploadData: _gridData });
    }

    progressbarFormatter = (cell: any, row: any) => {
        return <ProgressBar striped
            variant={(cell != undefined) && (cell != 100) ? "warning" : "success"}
            now={cell} />
    }

    actionFormatter = (cell: any, row: any) => {
        return (
            <button type="button" className="btn-white btn-xs"
                disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false} onClick={() => { this.deleteDocument(row) }}>
                <i className="text-danger glyphicon glyphicon-remove"></i>
            </button>
        );
    }

    deleteDocument = (row: any) => {

        const _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader();
                    _self.props.deleteBlobAdditionalEsign(row.sasGuid, () => {
                        HideLoader();
                        var _gridData = [..._self.state.uploadData];
                        _gridData = _gridData.filter(i => i.rowIndex != row.rowIndex);
                        for (let i = 0; i < _gridData.length; i++) {
                            _gridData[i].rowIndex = i;
                            _gridData[i].id = i + 1;
                        }
                        const _tempgridData = _gridData;

                        let sasContainer = [..._self.state.sasContainer];
                        sasContainer = sasContainer.filter(i => i.sasGuid != row.sasGuid);

                        if (_gridData.length == 0) {
                            _self.setState({ disableSubmit: true });
                        }
                        _self.setState({ uploadData: _tempgridData, sasContainer: sasContainer }, () => _self.forceUpdate());
                    });
                }
            }
        });

    }



    handleUploaderClose = () => {
        if (this.state.uploadData.length === 0) {
            this.handleClose();
            return;
        }
        if (this.isUploadOnGoing()) {
            bootbox.alert({
                message: UploadtaxReturnConstants.AbortingUploadWarning,
                buttons: {
                    ok: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-info'
                    }
                }
            });
        }
        else {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                size: 'small',
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.handleClose();
                        this.forceUpdate();
                    }
                }
            });
        }
    }

    reset = () => {
        this.setState({ sasContainer: [], uploadData: [], disableSubmit: true });
    }

    handleClose = () => {
        this.reset();
        this.props.onHide();
    }

    isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.uploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true; return;
            }
        });
        return isuploading;
    }

    onSubmit = () => {
        if (this.validateUploadData(this.state.uploadData)) {
            ShowLoader();
            this.props.submitDocuments(this.convertToAdditionalEsignModel(), () => {
                HideLoader();
                this.handleClose();
            });
        }
    }

    convertToAdditionalEsignModel = () => {
        const { documentGuid } = this.props;
        return this.state.uploadData.map(uploadData => {
            return {
                fileGuid: uploadData.sasGuid,
                documentType: uploadData.documentType,
                fileName: uploadData.name,
                sasURL: uploadData.sasUrl
            } as AdditionalEsignModel
        })
    }

    uploadConfirmation = () => {
        let message: string = `<h5 class='marT0'>${organizerSubmitSuccess} :</h5><ul class='marL30'>`;
        this.state.uploadData.map((value) => {
            message = message + `<li>${value}</li>`;
        });
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            size: 'small',
            buttons: {
                ok: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback() { }
        });
    }

    validateUploadData = (uploadData: AdditionalESignDocumentsUploadData[]): boolean => {
        let valid = true;
        for (let i = 0; i < uploadData.length; i++) {

            if (!uploadData[i].name || uploadData[i].name!.trim() === "") {
                VenusNotifier.Warning("Please enter file name to submit", "");
                valid = false;
                break;
            }
            if (uploadData[i].documentType === DocumentType.None) {
                VenusNotifier.Warning("Please select document type to submit", "");
                valid = false;
                break;
            }

        }
        return valid;
    }

    uploadProgressCallback = (percent: number, fileToUpload: any) => {
        const tempGridData = this.state.uploadData;
        tempGridData.map((tempGridDataValue) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            uploadData: tempGridData
        });
    }

    uploadCommitCallBack = (fileToUpload: any, sasGuid: string) => {
        if (this.state.uploadData.length > 0) {
            const tempGridData = this.state.uploadData;
            tempGridData.map((tempGridDataValue) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    let magicNumber: string = "";
                    new Promise((resolve) => {
                        GetFileMagicNumber(fileToUpload.file).then((result) => {
                            magicNumber = result;
                            if (isWordFile(result)) {
                                resolve(true);
                            }
                            else {
                                tempGridDataValue.progressBar = 100;
                                tempGridDataValue.status = UploadStatus.Uploaded;
                                tempGridDataValue.gridRowDisable = false;
                                tempGridDataValue.sasUrl = fileToUpload.sasUrl
                                tempGridDataValue.sasGuid = sasGuid
                                this.updateDocumentData(tempGridDataValue);
                            }
                        });
                    }).then(result => {
                        if (result) {
                            this.props.convertDocToPdfArtifactAsync(this.props.documentGuid,
                                this.props.taxYear,
                                escape(fileToUpload.fileGUID != undefined ? fileToUpload.fileGUID : ""),
                                this.props.isBatch,
                                () => {
                                    tempGridDataValue.name = ((fileToUpload.fileName).substr(0, (fileToUpload.fileName).lastIndexOf(".")) + FileExtensions.Pdf);
                                    tempGridDataValue.sasGuid = fileToUpload.fileGUID;
                                    this.updateDocumentData(tempGridDataValue);
                                });
                        }
                    });
                }
            });
            this.setState({
                uploadData: tempGridData
            }, () => {
                if (!this.isUploadOnGoing()) {
                    this.setState({ disableSubmit: false });
                }
            });
        }
    }

    updateConvertedDocToPdf = (convertedPdfFileList: IConvertedPdfFileList) => {
        const uploadedDocumentData = this.state.uploadData;
        convertedPdfFileList.convertedFiles.forEach(convertedPdfFile => {
            uploadedDocumentData.forEach(uploadedDocument => {
                if (uploadedDocument.sasGuid === convertedPdfFile.fileNameGuid) {
                    if (convertedPdfFile.isTransformSuccess) {
                        uploadedDocument.sasUrl = convertedPdfFile.fileSasUrl;
                        uploadedDocument.progressBar = 100;
                        uploadedDocument.gridRowDisable = false;
                        uploadedDocument.status = UploadStatus.Uploaded;
                    }
                    else {
                        uploadedDocument.sasUrl = convertedPdfFile.fileSasUrl;
                        uploadedDocument.progressBar = 100;
                        uploadedDocument.gridRowDisable = true;
                        uploadedDocument.status = UploadStatus.Error;
                        store.dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: StoreConstants.Failure.AdditionalEsignUploadFailed,
                            statusType: StatusType.Error,
                        });
                    }
                }
            });
        });

        this.setState({ uploadData: uploadedDocumentData }, () => {
            if (!this.isUploadOnGoing()) {
                this.setState({ disableSubmit: false });
            }
        });

        const fileGuidsToClear = convertedPdfFileList.convertedFiles.map(file => file.fileNameGuid);

        store.dispatch({
            type: actionTypes.CLEAR_ADDITIONAL_ESIGN_DOCUMENT_LINK,
            payload: fileGuidsToClear
        });
    }

    updateDocumentData = (documentData: AdditionalESignDocumentsUploadData) => {
        let uploadedDocumentData: AdditionalESignDocumentsUploadData[] = this.state.uploadData;;
        uploadedDocumentData.map((item, index) => {
            if (item.name == documentData.name) {
                item = documentData;
            }
        })
        this.setState({ uploadData: uploadedDocumentData }, () => {
            if (!this.isUploadOnGoing()) {
                this.setState({ disableSubmit: false });
            }
        });
    }

    render() {
        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                isHidden: true,
                width: 'auto',

            },
            {
                header: 'Name',
                key: 'name',
                isKey: false,
                dataFormat: this.defaultFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: 'word-Visible text-left-align'
            },
            {
                header: 'Upload Progress',
                key: 'progressBar',
                isKey: false,
                dataFormat: this.progressbarFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Size',
                key: 'size',
                isKey: false,
                dataFormat: this.defaultFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Document Type',
                key: 'documentType',
                isKey: false,
                dataFormat: this.selectFormat,
                width: 'auto',
                isHidden: false,
                columnClassName: 'overflowVisible text-left-align',
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            }
        ];

        return (<div>
            <Modal className="upload-doc-modal"
                show={this.props.show}
                onHide={this.handleUploaderClose} enforceFocus={false}>
                <Modal.Header closeButton >
                    <Modal.Title>Add Documents for E-Signature</Modal.Title>
                </Modal.Header>
                <Modal.Body className="upload-doc-body">
                    <div className='batch-note-info'>
                        <i className="fas fa-info-circle"></i>
                        {PreparerMessageConstants.UploadFileWarning}
                    </div>
                    <div className="row">
                        <div className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.componentConfig}
                                djsConfig={this.djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"2D99CF95-2A80-4173-BE02-6FDBC87A226B"}
                            />
                        </div>
                        <div className="col-sm-10 uploaded-documents-table-container" id="uploadDocument">
                            <UploadedDocumentTable
                                columns={columns}
                                data={this.state.uploadData}
                                bodyContainerClass={"overflowVisible"}
                                noDataText={"Files list will appear here."}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={this.handleUploaderClose}>
                        <i className='glyphicon glyphicon-remove'></i>Close</Button>
                    <Button variant="info" onClick={this.onSubmit}
                        disabled={this.state.disableSubmit}>
                        <i className='glyphicon glyphicon-floppy-disk'></i>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
        );
    }
}
