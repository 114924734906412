import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction, StatusType } from './../../store/common/NotificationStore';
import { apiPrefix } from '../models/OrganizerStoreModels';

export interface IBlobFile {
    sas: string;
    guid: string;
}

export interface IConvertedPdfFileList {
    convertedFiles: IConvertedWordToPdfFile[],
}

export interface IConvertedWordToPdfFile {
    fileSasUrl: string,
    fileNameGuid: string
    isTransformSuccess: boolean
}

export interface ReceiveAdditionalEsignDocumentUploadLinkAction {
    type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK;
    payload: IConvertedWordToPdfFile;
}

export interface ClearAdditionalEsignDocumentUploadLinkAction {
    type: actionTypes.CLEAR_ADDITIONAL_ESIGN_DOCUMENT_LINK;
    payload: string[]
}

const unloadedFileState: IConvertedPdfFileList =
{
    convertedFiles: [],
};

type DispatchAction = ReceiveAdditionalEsignDocumentUploadLinkAction | ClearAdditionalEsignDocumentUploadLinkAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
    convertDocToPdfArtifactAsync: (documentGuid: string, taxYear: number, fileName: string, isBatch: boolean = false, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`${apiPrefix}Organizer/ConvertDocToPdf?documentGuid=${documentGuid}&taxYear=${taxYear}&fileName=${fileName}&isBatch=${isBatch}`, {
            method: 'POST',
            credentials: 'include'
        })
            .then(data => {

                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AdditionalEsignUploadFailed,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
            });
    },
};

export const reducer: Reducer<IConvertedPdfFileList> = (state: IConvertedPdfFileList = unloadedFileState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK:
            return {
                ...state,
                convertedFiles: [...state.convertedFiles, action.payload],
            };
        case actionTypes.CLEAR_ADDITIONAL_ESIGN_DOCUMENT_LINK:
            return {
                ...state,
                convertedFiles: state.convertedFiles.filter(
                    (file) => !action.payload.includes(file.fileNameGuid)
                ),
            };
        default:
            return state;

    }
}
