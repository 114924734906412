export const Common = {
	ButtonLabel: {
		Cancel: "Cancel",
		Ok: "Ok",
		Save: "Save",
		Delete: "Delete"
	},
	OverlayMessages: {
		Downloading: "Downloading...Please wait..",
		Processing: "Processing...Please wait",
		Deleting: "Deleting...Please wait"
	}
}


export const UiTextConstants = {
	FeatureDisabled: 'Feature Disabled',
	AccessDenied: 'Access Denied',
	NoProductAccessMessageLine1: 'We are sorry, you do not have permission to access Safesend Organizers.',
	NoProductAccessMessageLine2: 'Please contact your system administrator to update your user profile',
	FeatureDisabledMessageLine1: 'This product is not supported on your current subscription tier,',
	FeatureDisabledMessageLine2: 'please contact your Customer Success Representative for access.',
	NoPermissionButProductAcessMessageLine1: 'Please consult your system',
	NoPermissionButProductAcessMessageLine2: 'administrator for assistance.',
	ProductDisabledMessageLine1: 'We are sorry, but this feature is currently not available for your use.',
	ProductDisabledMessageLine2: 'Please contact your System Administrator for more information.',
	CommonFeatureDisabledMessageLine1: 'This feature is unavailable due to permission or subscription restrictions.',
	CommonFeatureDisabledMessageLine2: 'Contact your System Admin or Customer Success Representative for details.',
}

export const UserManagementConstants = {
	PleaseNoteText: 'Please Note!',
	SelectUserGroupWarning: 'Please select atleast one user role in Memberships.',
	LoadingUserError: 'An Error occured when loading users.Please Try Again.',
	SavingUserError: 'Saving user details failed, please re-login',
	UserUpdationError: 'Updating user details failed, please re-login',
	EditUserLoadingError: 'User Loading failed, please re-login.',
	DeletingUserError: 'Deleting user failed, please re-login',
	SelectUserWarning: 'Please select a user from the list.',
	SelectGroupWarning: 'Please select a group from the list.',
	SaveUserSuccess: 'User added successfully',
	DeleteUserSuccess: 'Selected user has been deleted',
	UserUpdationSuccess: 'User details has been updated.',
	DeleteUserConfirmation: 'Are you sure you want to delete this user?',
	AddUserDetails: 'Add User Details',
	SetPAssword: 'Set Password',
	PasswordChangeOnNextLogin: 'User must change password on next login',
	ManageMembership: 'User Group',
	UserManagement: 'User Management',
	EditUserDetails: 'Edit User Details',
	ChangePassword: 'Change Password',
	UserExists: 'User already exists.',
	UserPasswordSuccess: 'Password changed successfully',
	DeleteLoggedInUser: 'You cannot delete the logged in user',
	DependentUsers: 'Warning:  You are trying to delete a user that has been selected as the Contact Person for returns that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the deleted user.',
	SignatureFlowDisabledWarning: 'Signature service  is currently disabled for your company. Please enable from company settings.',
	ExportToExcel: 'Export to Excel',
	MobileNumberEmpty: 'Mobile Number can\'t be empty',
	invalidOrganizerDownload: 'Organizers in the following statuses cannot be downloaded.',
	downloadWaitProcessing: 'Download process may take some time , the downloaded forms will be available in "My Downloads"',
	downloadError: 'Download Failed - An error occurred while downloading the selected records. Please try again',
	deleteReturnsError: 'We are unable to delete the selected documents.',
	downloadNowWaitProcessing: 'Please wait as we are preparing your files',
	bulkDownloadMessageTitle: 'Download will initiate only for following organizer statuses:',
	bulkDownloadValidElStatus: 'Signature Document : Partially Signed, E-signed.',
	bulkDownloadValidOrganizerStatus: 'Organizer: Partially Completed, Completed.',
	bulkDownloadValidSourceDocumentStatus: 'Source Document: Uploaded.',
	DownloadElStatus: 'Signature Document : Awaiting Signature.',
	DownloadOrganizerStatus: 'Organizer: Delivered.',
	DownloadSourceDocumentStatus: 'Source Document: Awaiting upload.',
	SigningRelatedStatusNames: '“Awaiting Signature”, “Partially Reviewed”, “Partially Signed”.',
	OrganizerRelatedStatusNames: '“Delivered”, “Partially Completed”, “Questionnaire Completed”.',
	AutoReminderSignWarning: 'Signing Reminder will be sent for statuses: ',
	AutoReminderOrganizerWarning: 'Organizer Reminder will be sent for statuses: ',
	DeliveredReporESignWarning: 'User cannot update reminders for the signature statuses “Reviewed”,“E-signed”, “Downloaded”, “NA”, “Manually Signed"',
	AutoReminderSourceWarning: 'Source Doc. Reminder will be sent for statuses: “Awaiting Upload”, ” Uploaded”, "Downloaded". ',
	DeliveredReportOrganizerWarning: 'User cannot update reminders for the organizer statuses “Completed”, “Downloaded”, “Manually Completed”',
	AutoReminderIconStatus: 'If the status is Downloaded but the TP already Finished the upload step a reminder will not be sent.',
}

export const ValidationContants = {
	FirstNameWarning: 'Please enter first name.',
	LastNameWarning: 'Please enter last name.',
	EmailAdrressWarning: 'Please enter email address.',
	ValidEmailAddressWarning: 'Please enter valid  email address.',
	PhoneNumberWarning: 'Please enter the phone number.',
	PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
	MobileNumberWarning: 'Please enter the mobile number.',
	MobileNumberLengthWarning: 'Please enter a 10 digit mobile number.',
	CountryCodeWarning: 'Please select the country code.',
	PTINWarning: 'PTIN should start with P followed by 8 digit number.',
	EnterPasswordWarning: 'Please enter password and confirm password.',
	PasswordNotMatchedWarning: 'Password and confirmed password did not match.',
	NewPasswordWarning: 'Please enter new password.',
	OldPasswordWarning: 'Please enter old password',
	ConfirmPasswordWarning: 'Please enter confirm password',
	PassLengthWarning: 'Password must be 8 characters.',
	PasswordWithoutSpaceWarning: 'Please enter password without space.',
	ZipWarning: 'Please enter zip.',
	ZipLengthWarning: 'Please enter a 5 digit zip.',
	ExtensionWarning: 'Please enter less than 7 digit extension number.',
	FaxWarning: 'Please enter a 10 digit fax number.',
	StateWarning: 'Please select state.',
	CityWarning: 'Please enter city.',
	ValidCityWarning: 'Please enter valid city name.',
	PdfFileValidation: 'Please upload Pdf files only.',
	ZipFileValidation: 'Please upload ZIP files only.',
	ExcelFileValidation: 'Please upload Excel files only.',
	PaymentURLValidation: 'Please enter a valid payment URL.',
	FirmKeyWarning: 'Please enter valid firm key.',
	AzureGroupIdWarning: 'Please enter valid Azure Group Id.',
	PasswordWarning: 'Please enter password.',
	K1InstructionNotAVailable: 'K1 instruction is not available in the uploaded file',
	NoContentAvailable: 'No content available',
	EINWarning: 'Please enter valid EIN.',
	FileFormatNotSupported: 'Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.',
	UpperCaseValidationError: 'Password should contain at least one uppercase character.',
	LowerCaseValidationError: 'Password should contain at least one lowercase character.',
	NumbersValidationError: 'Password should contain at least one number.',
	SpecialCharactersValidationError: 'Password should contain at least one special character.',
	CsvFileValidation: 'Please upload Csv files only.',
	SameEmailValidation: 'Email Already selected.',
	UploadAdditionalSignFileWarning: 'Please upload .doc, .docx or .pdf files only.',
	UploadAdditionalSignSizeWarning: 'Please upload a file size greater than 0kb.',
	PdfDocumentPasswordProtectedError: `{0} \n is an encrypted document and cannot be used. Please remove the encryption and try again.`,
	MultiSelectWarning: 'Action not permitted as organizer is closed.',
	MultiSelectOldCQWarning: 'Action not permitted as Organizer is closed with old Custom Questions.'
}



export const CompanySettingsConstants = {
	StatusMessage: {
		CompanySettingsError: 'Error occured while fetching company settings',
		CommonSettingsError: 'Error occured while fetching common settings',
		CompanyProfileError: 'Error while loading company profile',
		CompanyLogoError: 'Error while loading company logo',
		UpdateCompanySettingsSuccess: 'Company settings updated successfully.',
		UpdateCompanySettingsError: 'Error occured while updating company settings',
		UpdateRetentionPeriodError: 'Error occured while applying retention period for all documents',
		UpdateCompanyProfileSuccess: 'Company Information saved',
		UpdateRetentionForAllDocuments: 'Retention Settings applied successfully',
		UpdateCompanyLogoError: 'Unable to update logo, Please try again',
		AdminProfileError: 'Error while loading company profile',
		ADAzureExist: 'Group ID has already been added on a different Account. Please enter a different Group ID'
	},
	OverlayMessage: {
		UpdatingCompanySettings: 'Updating company settings...',
		ApplicationLoading: 'Loading, please wait...'
	},
	Validate: {
		CompanyNameWarning: 'Please enter company name.',
		AddressWarning: 'Please enter street address 1.',
		StateWarning: 'Please select state.',
		WebsiteWarning: 'Please enter valid website.',
		ImageWarning: 'Please upload only image.',
		ImageDimensionWarning: 'Max dimensions are 300x100px.',
		ImageValidation: 'Image validation failed! try again...',
		TrustedDeviceExpiryDays: 'Please enter the days between 1 to 60.'
	},
	HeaderName: {
		MyCompany: 'My Company',
		CompanyDetails: 'Company Details',
		PrimaryAdmin: 'Primary Admin',
		CompanyLogo: 'Company Logo',
		EditCompany: 'Edit Company Details',
		ChangePrimaryAdmin: 'Change Primary Admin',
		UploadCompanyLogo: 'Upload Company Logo',
		CompanyLogoMessage: 'Your selected logo will be used on all web pages, emails and notifications',
		PrimaryAdminMessage: 'Primary Admin is always a system administrator'
	}

}


export const ReportFilterConstants = {
	StatusMessage: {
		SavedSuccessMessage: "Filter saved successfully.",
		SavedErrorMessage: "Failed to save the filter!",
		UpdateSuccessMessage: "Filter updated successfully.",
		UpdateErrorMessage: "Failed to update the filter!",
		DeleteSuccessMessage: "Filter deleted successfully.",
		DeleteErrorMessage: "Failed to delete the filter!",
		GetAllErrorMessage: "Failed to fetch the filters!",
	},
	ValidationMessage:
	{
		NoRecentFilters: "There is no recent filter to apply!",
	},
	ControlsLabel:
	{
		ApplyRecentFilterBtn: "Apply recent filter",
		SaveCurrentFilterBtn: "Save current filter",
		SetAsDefaultFilter: "Set as Default Filter",
		DeleteFilter: "Delete Filter",
		ExportToExcel: "Export to Excel",
		ClearFilter: "Clear Filter",
		RemoveDefault: "Remove Default",
		PleaseEnterTheFilterName: "Please enter the filter name",
		Apply: "Apply",
		SetDefault: "Set Default",
		Delete: "Delete",
		AppliedFilter: "Applied Filter"
	},
	OtherMessage: {
		DeleteFilter: "Are you sure you want to delete this filter?",
	},
	ControlsTitle: {
		ApplyMostRecentFilter: "Apply Most Recent Filter",
		ToggleDropdownMenu: "Toggle dropdown menu"
	},
	DefaultFilterBuilder: "_D_FILTER"//This suffix is trimmed to maintain total length to 25,
}



export const OrganizerFilterConstants = {
	FilterNameAlreadyExists: "Filter name already exists.",
	FilterNameIsNotValid: "Filter name should not contain special characters: # / ? \\",
	FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
	PleaseEnterFilterName: "Please enter filter name",
	FilterDoesNotExists: "Filter doesn't exists.",
	DeleteFilter: "Are you sure you want to delete this filter?",
	Title: {
		FilterSaveUpdate: "Save/Update Filter",
	},
	OtherMessage: {
		DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?"
	},
	ControlLabel: {
		YesCreateNew: "Yes, Create New",
		NoUpdateTheCurrent: "No, Update The Current"
	}
}

export const DeliveredReturnsConstants = {
	OtherMessage: {
		SelectCustomColumnValue: "Please select column value"
	},
	StatusMessage: {
		SetDefaultFilterSuccess: "Default filter has been updated successfully.",
		RemoveDefaultFilterSuccess: "Default filter has been removed successfully.",
		UnlockDocumentSuccess: "Document have been unlocked successfully."
	},
	WarningMessage: {
		SendReminderWarning: "For the selected returns, Automatic reminders has been enabled, still do you want to send the reminder?"
	},
	BulkOperationMessage: {
		SendReminderWarning: `Signing Reminder can be sent for the returns which are in 'Awaiting Upload' and 'Awaiting Esign' status. 
                               \nAlso, Automatic reminder has been enabled for the selected returns. 
                               \nDo you still want to continue ?`,
		SendReminderWaitProcessing: "We are processing the reminders for emailing. This may take several minutes",
		SendReminderError: 'We are unable to send reminder for these documents.',

		Processing: "This may take several minutes. Thank you for your patience.",
		CustomColumnError: 'We are unable to change custom column for these documents.',

		ArchiveError: 'We are unable to archive the selected documents.',
		downloadEFileWarning: `E-File forms can be downloaded for the returns which are in "Esigned", "Uploaded", "Signed and Esigned" and "Alternate and Uploaded" Status.
                               \nDo you want to continue?`,
		downloadEFileWaitProcessing: 'Download process may take some time , the downloaded Efile forms will be available in "My Downloads"',
		downloadEFileError: 'Download Failed - An error occurred while downloading the selected records. Please try again',
		DeleteReturnsError: 'We are unable to delete the selected documents.',
		downloadEFileNowWaitProcessing: 'Please wait as we are preparing your files'
	},
	ErrorMessage: {
		UploadedESignedEfileFormsCanOnlyDownloadable: "The E-file forms in the following status - 'Uploaded', 'E-signed', 'Signed & E-signed', 'Alternate & Uploaded', can be downloaded.",
		ChangeStatusError: 'Error : Status cannot be changed.',
	},
	ColumnSelectorMessage: {
		SaveColumnSettingsSuccess: "Column Settings saved successfully!",
		SaveColumnSettingsLoader: "Saving your changes. Please wait...",
		ModalHeader: "Use the toggle option to show or hide the columns in the report."
	}
}



export const RecyleReturnsConstants = {
	SuccessMessages: {
		Delete: "Deletion in progress, Please refresh after sometime.",
		Restore: "Successfully restored Selected Organizer(s)."
	},
	ConfirmMessages: {
		Delete: `<p>Are you sure you want to delete the selected organizer(s)?</p>
				<p style="margin-top:5px">On Delete, System will permanently delete all the organizer(s) from the SafeSend Organizer application and they will be no longer available to anyone and the Taxpayer's access link will be disabled permanently.<p>`,
		Restore: "<p>Are you sure you want to restore the selected organizer(s)?</p>"
			+ "<p class='please-note-msg'>Note: Any previously scheduled reminders will be turned on for organizer(s) restored to delivered report.</p>"

	},
	Title: {
		RecyleReturns: "<i class='text-danger fas fa-trash-alt'/> Permanent Delete",
		Restore: "<i class='text-danger fas fa-undo'/> Restore"
	},
	SelectOrganizerWarning: "Please select Organizer(s) to delete!",
}

export const SavedMessageConstants = {
	SaveMessageSuccess: 'Message added successfully',
	SaveMessageNameError: 'Name already exists',
	SaveMessageError: "Failed to save message",
	UpdateMessageError: "Failed to update message",
	UpdateMessageSuccess: 'Message updated successfully',
	DeleteMessageSuccess: 'Message deleted successfully',
	ValidateName: 'Please enter a message name.',
	ValidateSubject: 'Please enter a message subject.',
	ValidateBody: 'Please enter a message body.',
	DeleteMessageWarning: 'You cannot delete the default message',
	SavedMessageWarning: "Message details",
	SetDefaultMessageWarning: "This message will be set as default message",
	RemoveDefaultMessageWarning: "This message will be removed as default message",
	SelectDefaultMessageWarning: "Please select a default message first",
}



export const UserSettings = {
	fetchUserSettings: "Failed to fetch User Settings",
	saveUserSettingsSuccess: "User Settings saved successfully",
	saveUserSettingsError: "Failed to save User Settings",
	saveSignatureDelegations: "Failed to save delegates",
	fetchUserNotificationSettingsError: "Failed to fetch User Notification Settings",
	saveUserNotificationSettingsError: "Failed to save User Notification Settings",
	saveUserNotificationSettingsSuccess: "User Notification Settings saved successfully"
}


export const ReportProblemConstants = {
	OverlayMessage: {
		SubmittingIssue: "Submitting your issue, Please wait..."
	},
	SuccessMessage: 'Problem reported successfully.Your Tracking ID is ',
	ErrorMessage: 'Failed to report the problem!'
}


export const UploadtaxReturnConstants = {
	SetEROSignerWarning: "Please select a ERO / Signer to submit.",
	SubmitTaxReturnSuccess: "The following return was successfully submitted",
	SubmitTaxReturnFailed: "Failed to submit the following return, please try again",
	AbortingUploadWarning: "Aborting upload cannot be processed in between , please wait.",
	CloseConfirmationMessage: "Closing this will delete the files, do you want to proceed?",
	UploadingFailedError: "Uploading failed, please try again",
	DeleteFileMessage: "Are you sure you want to delete this file?",
	Recall: {
		FileAddingWarning: "one file is already added.",
		MultipleFileAddingWarning: "only one file can be uploaded."
	},
	ReturnSubmitSuccess: "The following return(s) were successfully submitted",
	EmptyFileListMessage: "Files list will appear here.",
	OverlayMessage: {
		ProcessTaxReturn: 'Processing tax return...',
		ApplicationLoading: 'Loading, please wait...'
	},
	StatusMessage: {
		TaxReturnLinkError: 'Error while fetching tax return link.',
		ProcessTaxReturnError: 'Error occured while processing tax return',
		UpdateTaxReturnError: 'Unable to update tax return, Please try again',
	},
}

export const MyAccountConstants = {
	//API response
	APIResponse: {
		UserUpdateSuccess: "User updated successfully."
	},
	UserUpdateFailedMessage: "Updating user details failed, please re-login",
}

export const ChangePasswordConstants =
{
	PasswordChangeLogoutWarning: "Changing your Password will require you to login again. Make the change?",
	PasswordUpdateSuccess: "Password updated successfully.",
	PasswordUpdateFail: "Updating password failed, please re-login",
}

export const FinishProcessReturn = {
	MenuItems: {
		SendForReview: 'Send for Review',
		SendToEROSigner: 'Send to ERO / Signer',
		ApproveForDelivery: 'Approve for Delivery',
		DeliverToClient: 'Deliver to Client',
		DeliverToCompany: 'Deliver to Company',
		ReturnToProcessor: 'Return to Processor',
		DeliverToTaxCaddy: 'Deliver to TaxCaddy',
		DeliverWithTaxCaddy: 'Deliver with TaxCaddy',
		RetentionPeriod: 'Modify Retention Period',
		PaperDelivery: 'Download PDF',
		Empty: "",
	},
	StatusMessage: {
		SendToEROSuccess: "Sent to ERO successfully.",
		SendForReviewSuccess: "Sent for review successfully.",
		ApproveForDeliverySuccess: "Sent to approve for delivery successfully.",
		DeliverToClientSuccess: "Delivered to client successfully.",
		SendToEROError: "Error occured while sending to ERO.",
		SendForReviewError: "Error occured while sending for review.",
		ApproveForDeliveryError: "Error occured while sent to approve for delivery.",
		DeliverToClientError: "Error occured while delivering to client.",
		GenerateTaxpayerViewSuccess: "Loaded taxpayer view successfully.",
		GenerateTaxpayerClientViewSuccess: "Loaded taxpayer client view successfully.",
		GenerateTaxpayerViewError: "Error occured while loading the taxpayer view",
		GenerateTaxpayerClientViewError: "Error occured while loading the taxpayer client view",
		DeliverToClientWithoutInvoiceError: "You are required to include an invoice and amount due with this Tax Return. Please return to the Invoice Step and ensure and invoice is included and the amount is entered in the proper field.",
		SelectApprover: "Please select a user to approve for delivery.",
		SelectDeactivatedDeletedAuthority: "Please Select Alternate Authority for Deleted/DeActivated Authority.",
		TaxCaddyLookupError: "Unable to communicate to TaxCaddy server, please contact support.",
		RetentionValidationMessage: "Please specify a valid retention period.",
	},
	InfoMessage: {
		AttchmentNotDeliverToTaxCaddy: "Attachments will not be delivered to TaxCaddy",
		TaxCaddyApprove: "On Accept, this return will be delivered to TaxCaddy",
		TaxCaddyReject: "On Reject, this return will not be delivered to TaxCaddy",
		NotDeliveredToTaxcaddy: ' This return will not be delivered to TaxCaddy'
	}
}

export const GeneralSettingConstants = {
	disableEngagementLetterPopoverMessage: "Applicable only for TaxSoftwares GoSystem, UltraTax and Lacerte",
	engagementLetterHeading: "Engagement Letter Default",
	engagementLetterCheckbox: "Allow users to deliver organizers without Engagement Letters",
	sourceDocumentHeading: "Gather Source Document",
	sourceDocumentChecbox: "Enable gathering for taxpayer source documents",
	sourceDocumentNotificationHeading: "Source Document Notification",
	notifyEveryTimeUploadOrDeleteSrcDocRadioBtn: "Send notification every time client Uploads/Deletes a source document",
	schNotification1PerDayUploadOrDeleteSrcDocRadioBtn: "Send scheduled notification once per day, if client Uploaded/Deleted source document(s)",
	sourceDocumentUploadNotificationDisabledRadioBtn: "Source Document Upload Notification Disabled",
	sourceDocNotificationtoolTip: "Enable gather source document to configure email notification.",
	automaticRemaindersHeading: "Automatic Reminders",
	automaticRemaindersCheckbox: "Enable Automatic Reminders",
	promptMessage: "Any changes made to settings will be lost on navigating without saving. Do you wish to continue?"
}

export const ClientInstructionMessageVariables = {
	CompanyName: "The listed Name of your company on your account",
	TaxPayerName: "The listed Taxpayer on the Tax Return",
	SpouseName: "The listed Spouse on the Tax Return",
	HelpContact: "The name of the user designated for customer support",
	HelpPhone: "The phone number of the user designated for customer support",
	HelpEmail: "The email address of the designated help contact",
	PrepareName: "The listed Preparer on the Tax Return",
	DueDate: "The due date of the filing",
	NumberOfays: "Number of days set for voucher reminder",
	Recipient: "The person name for which the email is being sent",
	TaxYear: "The Taxyear on the Tax Return",
	EROName: "The name of the assigned ERO Signer",
	SenderName: "The name of the user selected as the 'Sender' of the return"
}

/////////////////////////////////////////Tab Constants Start/////////////////////////////////////////////////

export const ClientInfoTab = {
	Page: {
		DeleteConfirm: "Are you sure you want to delete this page?"
	},
	AddedRefund: {
		DeleteConfirm: "Are you sure to delete?",
		AuthorityWarning: "Please select a taxing authority to add.",
		AmountEmptyWarning: "Please enter valid amount.",
		OverpaymentLessThanWarning: "Overpayment amount should be greater than overpayment applied.",
		DOBWarning: "Please enter valid date of birth."
	},
	UpdateRefund: {
		RefundUpdateSuccess: "Refunds & payments Due updated successfully",
	}
}

export const TabEFileConstants = {
	Info: {
		EROStampInfoMessage: "The ERO stamp will be applied in all pages"
	},
	SignatureControlWarning: {
		Taxpayer: "There should be at least one signature control for the taxpayer to sign.",
		Partner: "There should be at least one signature control for the partner to sign.",
		Spouse: "There should be at least one signature control for the spouse to sign.",
		EroStamp: "There should be at least one ERO signature stamp."
	}
}

export const ClientInfoValidation = {
	inCorrectEmail: "Please enter valid email address.",
	inValidSSN: "Please enter valid SSN.",
	inCorrectTaxpayerEmail: 'Please enter valid taxpayer Email Id.',
	inCorrectSpouseEmail: 'Please enter valid spouse Email Id.',
	inCorrectCompanyEmail: 'Please enter valid Company email Id.',
	inValidTaxpayerSSN: "Please enter valid taxpayer SSN.",
	inValidSpouseSSN: "Please enter valid spouse SSN.",
	inCorrectPartner: "Please select ERO / Signer",
	inCorrectERO: "Please select an ERO / Signer from drop-down"
}

export const DeliveryOptionsValidation = {
	InCorrectContactPerson: "Please select a Contact Person.",
	InValidMessageFromPreparer: "Please select a Preparer Message.",
	InValidSenderName: "Please select a Sender Name.",
	InValidNotifyAboutSigningsEvents: "Please select a contact to Notify About Signing Events.",
	InvalidNumberofDaysVoucherReminder: "Please select a number of days for the Voucher Reminders.",
	InvalidNumberofDaysSigningReminder: "Please select a number of days for the Signing Reminder."
}


export const FileConstants = {
	MaximumFileSize: '536870912',
	MaximumFileNameCharacter: 200
}

export const FileSizeExceedsLimit = (fileName: string) => `${fileName} cannot be uploaded since the size is greater than 512 MB.`;

export const FileNameCharacterLimitExceeds = (fileCount: number, totalFileCount: number) => `Unable to upload ${fileCount} out of ${totalFileCount} file(s), PDF name character limit exceeded`;
/////////////////////////////////////////Tab Constants End/////////////////////////////////////////////////


export const MailMergeDocument = {
	CsvUploadFileWarning: 'Please upload .csv file only.',
	FileInfoMessage: "File info will appear here.",
	MailMergeTemplateFileName: 'MailMergeTemplate.csv',
	DownloadSampleTemplate: 'Download Template',
	HeadingUploadCsv: 'Upload CSV',
	HeadingValidationResult: 'Validation Result',
	SendingForMailMergeOverlayMessage: 'Sending for Mail Merge...',
	DownloadExcelBtnText: 'Download'
}


export const OTPVerify = {
	InvalidOTP: 'Invalid OTP',
	validOTP: 'OTP Verified successfully',
}

export const MyDownloadsConstants = {
	StatusMessage: 'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
	DeleteMyDownload: 'Selected File has been deleted',
	ClearAllMyDownloads: 'All Downloaded files has been deleted',
	DeleteSingleFileWarning: 'File cannot be deleted, since Download is in progress.',
	DialogBox: {
		ConfirmDeletionSingle: 'Are you sure you want to delete this file?',
		ConfirmDeletionAll: 'Are you sure you want to clear all files?',
		Confirm: 'Yes',
		Cancel: 'Cancel',
		Title: 'Confirm Deletion'
	}
}
export const EngagementLetterControlDeleteWarning = {
	DialogBox: {
		MessageForTemplate: "There are signature controls placed on the page you are trying to delete. Continuing with this action will remove the page and signature controls applied from all organizers in this batch. Do you want to continue?",
		Message: "There are signature controls placed on the page you are trying to delete. Do you want to continue?",
		Confirm: 'Continue',
		Cancel: 'Cancel',
		Title: 'Page contains signature controls!'
	}
}

export const SectionDeleteWarning = {
	DialogBox: {
		Message: "There are questions added to the section you are trying to delete. Continuing with this action will remove the section and question(s) under the section. Do you want to continue?",
		Confirm: 'Continue',
		Cancel: 'Cancel',
		Title: 'Section contains question(s)!'
	},
	QuestionDelete: {
		Message: "If you delete the last question within the section, the section will be removed. Do you wish to continue?",
		Confirm: 'Continue',
		Cancel: 'Cancel',
		Title: 'Section will be deleted!'
	}
}


export const FileExtensions = {
	Pdf: '.pdf'
}

export const SupportedFilePreviewTypes = ['.pdf', '.xlsx', '.jpeg', '.png', '.docx', '.jpg']

export enum SourceDocumentNotification {
	NotifyEveryTimeUploadOrDeleteSrcDoc = 1,
	SchNotification1PerDayUploadOrDeleteSrcDoc = 2,
	NoAdditionalCPANotificationRequired = 3
}

export const UndeliveredBatchOrganizerContants = {
	DeleteModalHeader: "Confirm Batch Organizer Delete",
	DeleteModalBody: "You are about to delete the selected batch. This cannot be undone. Do you want to proceed?"
}

export const SignatureControlConstants = {
	ControlIdPrefix: "control_"
}

export const SignaturePanelConstants = {
	Signature: {
		header: "Signature Settings",
		title: "To make this signature mandatory, please check box below.",
		tooltipContent: ""
	},
	Default: {
		header: "Tool Tip",
		title: "Add Text",
		tooltipContent: "This text will help the signer to enter the necessary details"
	},
	CheckBoxControl: {
		header: "Checkbox properties",
		title: "How many checkbox(es) would you like to add?",
		tooltipContent: "Mention the count of checkbox(es) to be added. All checkboxes will be selectable by Taxpayer."
	},
	RadioButtonControl: {
		header: "Radio button properties",
		title: "How many radio button(s) would you like to add?",
		tooltipContent: "Mention the count of radio button(s) to be added. For one set Taxpayer can select only one option."
	}
}

export const TemplateModalConstants = {
	//Templates list
	RecentQuestionnaires: "Recent Questionnaires",
	StartFromScratch: "Start from scratch",
	BuildNewQuestionnaire: "Build a new questionnaire",
	WithYourCustomQuestions: "with your custom questions.",
	CreateNew: "Create New",
	// Edit Template
	UntitledQuestionnaire: "Title here",
	QuestionnaireDescription: "Brief description here",
	AddNewSection: "Add New Section",
	//Add Template Modal
	AddTemplateModalHeader: "Create New Template",
	EditTemplateModalHeader: "Rename Template",
	TemplateNamePlaceholder: "Enter template name",
	TemplateNameValidationMessage: "Please enter template name",
	TemplateDesciptionPlaceholder: "Enter template description",
	DeleteDefaultTemplateConfirmation: "This template is set to default , do you still want to delete this template ?",
	DeleteTitle: "Permanent Delete",
	DeleteConfirmation: "Are you sure want to delete the template ?",
	DeleteSuccessMessage: "Template deleted successfully",//TO confirm
	CustomTemplate: "Custom Template",
	DeleteTemplateInUse: "Template In-use",
	DeleteConfirmationForTemplateInUse: "You are trying to delete a questionnaire template that is being used for an organizer in process. Please confirm if you wish to permanently delete the selected questionnaire template.",
	DeleteConfirmationNote: "Uploaded organizer(s) will retain this saved questionnaire until updated manually.",
	// Add Questionaire Modal

	AddModalHeader: "Create New Questionnaire",
	EditModalHeader: "Rename Questionnaire",
	AddModalConfirmButton: "Continue",
	EditModalConfirmButton: "Save",
	NameLabel: "Name",
	NamePlaceholder: "Enter questionnaire name",
	NameValidationMessage: "Please enter questionnaire name",
	DescriptionValidationMessage: "Please enter questionnaire description",
	TemplateNameMaxLength: 100,
	TemplateDescriptionMaxLength: 100,
	NameMaxLength: 150,
	DesciptionLabel: "Description",
	DesciptionPlaceholder: "Enter questionnaire description",
	DesciptionMaxLength: 500,
	EditSuccessMessage: "Your changes are saved successfully",//TO confirm
	AddSuccessMessage: "Template added successfully",//TO confirm
	SetDefaultTemplateSuccess: "Default template set successfully",
	UnsetDefaultTemplateSuccess: "Default template selection removed successfully",

	//Add Section Modal
	AddSectionModalHeader: "Create New Section",
	EditSectionModalHeader: "Rename Section",
	MergeSectionModalHeader: "Merge Section",
	ReorderSections: "Reorder Sections",
	AddSectionModalConfirmButton: "Continue",
	EditSectionModalConfirmButton: "Save",
	SectionMerge: "Merge",
	SectionNameLabel: "Name",
	SectionNamePlaceholder: "Enter section name",
	SectionNameValidationMessage: "Please enter section name",
	SectionNameMaxLength: 100,

}

export const FormPreviewConstants = {
	RequiredValidation: "This field is required",
	ParagraphTypeQuestion: {
		placeholder: "Answer goes here",
		maxLength: 3250
	},
	MultiCoiceTypeQuestion:
	{
		optionAlreadyExists: "Option already exists"
	}
}

export const CustomQuestionsTabConstants = {
	ChooseTemplate: "Choose Template",
	SelectTemplate: "Select Template",
	NoTemplate: "No Template Selected",
	NoQuestionnaiers: "No questionnaire yet?",
	GetStarted: "Let’s get started!",
	DeleteQuestionTitle: "Delete question and section",
	DeleteSectionTitle: "Section contains questions",
	DeleteQuestionConfrimation: "If you delete the last question within the section, the section will be removed. Do you wish to continue?",
	DeleteSectionConfirmation: "There are questions added to the section you are trying to delete. Continuing with this action will remove the section and question(s) under the section. Do you want to continue?",
	AtleastOneSectionRequired: "Template requires at least one section",
	AtleastOneQuestionRequired: "Section requires at least one question",
};

export const FormBuilderConstants = {
	QuestionTitle: {
		placeholder: "Enter Question here",
		maxLength: 2000,
		answerPlaceholder: "Answer goes here",
	},
	SAVE_DISABLED_TOOLTIP: "No content to save",
	PREVIEW_DISABLED_TOOLTIP: "No content to preview",
	DELETE_QUESTION_DISABLED_TOOLTIP: "Section requires at least one question.",
	DUPLICATE_SECTION_LIMIT: "Unable to duplicate. Character limit exceeded for selected questionnaire.",
	DUPLICATE_QUESTION_LIMIT: "Unable to duplicate. Character limit exceeded for selected question.",
	FORM_VALIDATION_ERROR: "Please check the error(s)",
	DELETE_MODAL_HEADER: "Confirm delete section",
	DELETE_MODAL_BODY: "Deleting the section will remove all the questions created under this section. Do you wish to continue?",
	MultiChoiceConstants: {
		placeholder: "Enter Option Here",
		maxLength: 300,
		optionsMaxLimit: 5,
		atLeastOneOptionText: "Question requires at least one option"
	}
}

export const OrganizerClientIdConstants = {
	ClientIdMaxLength: 150,
	ClientIdLengthForEllipsis: 47
}

export const LocationMismatchModal = {
	header: {
		title: 'Attention'
	},
	body: {
		Content: {
			Line1: 'By changing Office Location to',
			Line2: ` {0} `,
			Line3: 'you will no longer have access to it as you are not assigned to that office.',
			Line4: 'Press Continue to change office location or Cancel to keep the current office location.'
		}
	},
	footer: {
		Cancel: 'Cancel',
		Continue: 'Continue'
	}
}

export const INVALID_PAGE = -100;
export const SignatureFlowReportFilterConstants = {
	StatusMessage: {
		SavedSuccessMessage: "Filter saved successfully.",
		SavedErrorMessage: "Failed to save the filter!",
		UpdateSuccessMessage: "Default filter has been updated successfully.",
		UpdateErrorMessage: "Failed to update the filter!",
		DeleteSuccessMessage: "Filter deleted successfully.",
		DeleteErrorMessage: "Failed to delete the filter!",
		GetAllErrorMessage: "Failed to fetch the filters!",
	},
	ValidationMessage:
	{
		NoRecentFilters: "There is no recent filter to apply!",
		EmptyDocumentNote: "Please enter a document note."
	},
	ControlsLabel:
	{
		ApplyRecentFilterBtn: "Apply recent filter",
		SaveCurrentFilterBtn: "Save current filter",
		SetAsDefaultFilter: "Set as Default Filter",
		DeleteFilter: "Delete Filter",
		ExportToExcel: "Export to Excel",
		ClearFilter: "Clear Filter",
		RemoveDefault: "Remove Default",
		PleaseEnterTheFilterName: "Please enter the filter name",
		Apply: "Apply",
		SetDefault: "Set Default",
		Delete: "Delete",
		AppliedFilter: "Applied Filter"
	},
	OtherMessage: {
		FilterNameAlreadyExists: "Filter name already exists.",
		FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
		PleaseEnterFilterName: "Please enter filter name",
		FilterDoesNotExists: "Filter doesn't exists.",
		DeleteFilter: "Are you sure you want to delete this filter?",
		CreateOrUpdateFilter: "Do you want to create New Filter or Update the Current?",
		NoReturnsFound: "No returns found"
	},
	FilterSuccessMessage: {
		RemoveDefaultFilterSuccess: "Default filter has been removed successfully.",
	},

	FilterConfirmationModalTitle: {
		FilterSaveUpdate: "Save/Update Filter",
	},
	ControlLabel: {
		YesCreateNew: "Yes, Create New",
		NoUpdateTheCurrent: "No, Update The Current"
	}
}