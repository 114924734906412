//import
import AccessDenied from '../images/AccessDenied.svg';
import LastLogin from '../images/last-login.svg';
import SourceDocument from '../images/source document - small@1x.svg';
import SignatureSmall from '../images/Signature-small-1x.svg';


import Add from '../images/CustomQuestion/add-new.svg';
import { ReactComponent as MoreOptions } from '../images/CustomQuestion/more-options.svg';
import { ReactComponent as Star } from '../images/CustomQuestion/star.svg';
import { ReactComponent as StarFilled } from '../images/CustomQuestion/star-filled.svg';
//export
export const AccessDeniedIcon = AccessDenied;
export const LastLoginIcon = LastLogin;
export const SourceDocumentIcon = SourceDocument;
export const SignatureSmallIcon = SignatureSmall;

export const AddIcon = Add;
export const MoreOptionsIcon = MoreOptions;
export const StarIcon = Star;
export const StarFilledIcon = StarFilled;



