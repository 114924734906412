import { IUserGroup } from '../User/UserViewModel'

export interface ICompanySettings {
    isDefault: boolean;
    deliverySettingsModel: IDeliverySettingsModel;
    displaySettingsModel: IDisplaySettingsModel;
    notificationSettingsModel: INotificationSettingsModel;
    signatureSettingsModel: ISignatureSettingsModel;
    defaultMessageSettings: IDefaultMessage;
    reportingChannelSettings: IReportingChannelSettings[];
    retentionSettingsModel: IRetentionSettings;
    k1Settings: IK1Settings;
    organizerSettings: IOrganizerSettings;
}

export interface ICommonSettings {
    userPrivilegeSetting: IUserPrivilegeSettingsModel;
    brandingSetting : IBrandingSetting
    mfaSetting: ICompanyMfaSettings;
    externalAuthenticationSetting: IExternalAuthenticationSettings[];
    passwordPolicySetting: IPasswordPolicySettings;
    taxSoftwareSetting: ITaxSoftwareSettings
}

export interface IUteSettings {
    uteEnabled: boolean;
    uteModified: string;
}

export interface IBrandingSetting {
    coverPageSetting: IThemeSetting
}

export interface IThemeSetting {
    bgColorCode: string,
    foreColorCode: string
}

export interface IDefaultMessage {
    initialEmail: number;
    voucherReminders: number;
    downloadTaxDocuments: number;
    downloadSignedForms: number;
    k1Distribution: number;
    welcomeMessage: number;
    k1WelcomeMessage: number;
    downloadInstruction: number;
    paymentInstruction: number;
    savedMessage: number;
    signingReminder: number;
    k1PartnerInfoUpdate: number;
    k1ClientInfoUpdate: number;
    k1PartnerWelcomeMessage: number;
    k1ClientWelcomeMessage: number;
    k1PartnerUpdatePageMessage: number;
    attachmentInstruction: number;
}
export interface IDeliverySettingsModel {
    transmittalsDownloadOptions: TransmittalDownloadOption;
    vouchersDownloadOptions: VoucherDownloadOption;
    taxpayerAuthentication: TaxpayerAuthenticationType;
    isDirectDeliveryToTaxCaddy: boolean;
    isByPassManualVerification: boolean;
    isFooterEnabled: boolean;
    isPrintToPDFEnabled: boolean;
}
export interface IDisplaySettingsModel {
    onlinePaymentUrl: string;
    isEnableInvoice: boolean;
}
export interface INotificationSettingsModel {
    paymentVoucherNotificationSettings: IPaymentVoucherNotificationSettings;
    signingReminderNotificationSettings: ISigningReminderNotificationSettings;
}

export interface ISignatureSettingsModel {
    signatureFormSelectionType: SignatureFormSelectionType,
    knowledgeBasedAuthentication: boolean,
    enableSignerDeligation: boolean,
    useSignatureStamp: boolean,
    enableEsignatureForBusinessReturns: boolean,
    manualAddressId: number
}
export interface IPaymentVoucherNotificationSettings {
    enablePaymentVoucherReminder: boolean,
    noOfDaysForVoucherReminder: number
}

export interface ISigningReminderNotificationSettings {
    enableSigningReminder: boolean,
    noOfDaysForSigningReminder: number
}
export interface IK1InfoSettings {
    noOfDays: number,
    k1MailCycle: number
}

export interface IUserPrivilegeSettingsModel {
    allowAnyUsertoSendDelivary: boolean,
    allowUserToReplaceInvoice: boolean,
    allowUserToSendwithoutInvoice: boolean,
    allowUserToReplaceDistributedInstructionsDocument: boolean,
    enableIPFiltering: boolean,
    allowUserToDistributeK1: boolean
}
export interface IReportingChannelSettings {
    reportingEngine: string;
    enabled: boolean;
    settings: IReportingEngineSettings;
}
export interface IReportingEngineSettings {

}


export interface IOrganizerSettings {
    enabled: boolean;
}


export interface ITaxSoftwareSettings {
    taxSoftware: TaxSoftwareType[];
}
export interface IPasswordPolicySettings {
    passwordPolicy: PasswordPolicyType;
    passwordSettings: IPasswordSettings;
    modifiedDate: Date;
}

export interface IK1Settings {
    k1ClientInfoSettings: IK1InfoSettings;
    k1PartnerInfoSettings: IK1InfoSettings;
}
export interface IPasswordSettings {
    passwordOptions: PasswordOptions
    length: number;
    passwordAge: number;
}
export interface IRetentionSettings {
    retentionPeriod: number;
    attestRetentionPeriod: number;
    allowOverride: boolean
}

export interface IExternalAuthenticationSettings {
    provider: AuthenticationProvider,
    enabled: boolean,
    authorisationType:AuthorisationMode,
    data: IExternalAuthenticationSettingData[],
    isModified: boolean;
}

export interface IExternalAuthenticationSettingData {
    key: string,
    roles: IUserGroup[]
}

export interface ICompanyMfaSettings {
    isMfaEnabled: boolean;
    trustedDeviceExpiryDays: number;
    isAllowUsertoRememberTrustedDevice: boolean;
    skipMfaForIP: boolean;
    iSTPMfaEnabled: boolean;
    textBasedAuthenticationForTP: string;
}

export enum AuthenticationProvider {
    None,
    AzureAD,
    SAML,
    Google
}

export enum AuthorisationMode {
    None,
    AzureAdGroups,
    SSRUserList
}

export enum TransmittalDownloadOption {
    None = "None",
    IncludeTransmittalInTaxReturnPDF = "IncludeTransmittalInTaxReturnPDF",
    CreateSeparateTransmittalPDF = "CreateSeparateTransmittalPDF"
}
export enum VoucherDownloadOption {
    None = "None",
    SeparateByQuarterlyDueDates = "SeparateByQuarterlyDueDates",
    GroupInSinglePDF = "GroupInSinglePDF"
}
export enum TaxpayerAuthenticationType {
    None = "None",
    Last4ofSSN = "Last4ofSSN",
    First4ofSSN = "First4ofSSN"
}
export enum SignatureFormSelectionType {
    None = "None",
    ESign = "ESign",
    ESignWhenAllIncludedReturnsAllowed = "ESignWhenAllIncludedReturnsAllowed",
    ManualSign = "ManualSign"
}

export enum ReportingEngineType {
    TaxCaddy = "TaxCaddy"
}

export enum TaxSoftwareType {
    None = "None",
    ProSystems = "ProSystems",
    UltraTax = "UltraTax",
    GoSystem = "GoSystem",
    UltraTaxM18 = "UltraTaxM18",
    Lacerte = "Lacerte",
    Drake = "Drake"
}

export const TaxSoftwareTypeLabels = {
    [TaxSoftwareType.ProSystems]: "ProSystem",
    [TaxSoftwareType.GoSystem]: "GoSystem",
    [TaxSoftwareType.Lacerte]: "Lacerte",
    [TaxSoftwareType.UltraTax]: "UltraTax"
}

export enum PasswordPolicyType {
    NoPolicy = "NoPolicy",
    SSRDefault = "SSRDefault",
    FirmDefinedPolicy = "FirmDefinedPolicy"
}

export enum PasswordOptions {
    None = 0,
    UpperCase = 1,
    LowerCase = 2,
    Numbers = 4,
    SpecialCharacters = 8
}

export const initialCompanySettings: ICompanySettings = {
    isDefault: true,
    deliverySettingsModel: {
        transmittalsDownloadOptions: TransmittalDownloadOption.None,
        vouchersDownloadOptions: VoucherDownloadOption.None,
        taxpayerAuthentication: TaxpayerAuthenticationType.None,
        isDirectDeliveryToTaxCaddy: false,
        isByPassManualVerification: false,
        isFooterEnabled: false,
        isPrintToPDFEnabled:false
    },
    displaySettingsModel: {
        isEnableInvoice: true,
        onlinePaymentUrl: "",
    },
    notificationSettingsModel: {
        paymentVoucherNotificationSettings: {
            enablePaymentVoucherReminder: true,
            noOfDaysForVoucherReminder: 1
        },
        signingReminderNotificationSettings: {
            enableSigningReminder: true,
            noOfDaysForSigningReminder: 1
        }
    },
    signatureSettingsModel: {
        signatureFormSelectionType: SignatureFormSelectionType.None,
        knowledgeBasedAuthentication: true,
        enableSignerDeligation: true,
        enableEsignatureForBusinessReturns: true,
        useSignatureStamp: false,
        manualAddressId: 0
    },
    defaultMessageSettings: {
        initialEmail: 0,
        voucherReminders: 0,
        downloadTaxDocuments: 0,
        downloadSignedForms: 0,
        k1Distribution: 0,
        welcomeMessage: 0,
        k1WelcomeMessage: 0,
        downloadInstruction: 0,
        paymentInstruction: 0,
        savedMessage: 0,
        signingReminder: 0,
        k1PartnerInfoUpdate: 0,
        k1ClientInfoUpdate: 0,
        k1PartnerWelcomeMessage: 0,
        k1ClientWelcomeMessage: 0,
        k1PartnerUpdatePageMessage: 0,
        attachmentInstruction: 0
    },
    reportingChannelSettings: [{
        reportingEngine: ReportingEngineType.TaxCaddy,
        enabled: false,
        settings: {
            isSkipManualVerification: false,
            firmIds: []
        }
    }],
    retentionSettingsModel: {
        retentionPeriod: 2520,
        attestRetentionPeriod: 2520,
        allowOverride: false
    },
    k1Settings: {
        k1PartnerInfoSettings:
        {
            noOfDays: 15,
            k1MailCycle: 1
        },
        k1ClientInfoSettings:
        {
            noOfDays: 15,
            k1MailCycle: 1
        }
    },
    organizerSettings: {
        enabled: false
    }
}

export const initialCommonSettings : ICommonSettings = {
    userPrivilegeSetting: {
        allowAnyUsertoSendDelivary: true,
        allowUserToReplaceInvoice: true,
        allowUserToSendwithoutInvoice: true,
        allowUserToReplaceDistributedInstructionsDocument: false,
        enableIPFiltering: false,
        allowUserToDistributeK1: false
    },
    brandingSetting: {
        coverPageSetting : {
        bgColorCode: '#000000',
        foreColorCode: '#000000'
        }
    },
    mfaSetting: {
        isMfaEnabled: false,
        trustedDeviceExpiryDays: 0,
        isAllowUsertoRememberTrustedDevice: false,
        skipMfaForIP: false,
        iSTPMfaEnabled: false,
        textBasedAuthenticationForTP: "EmailAndText"
    },
    externalAuthenticationSetting: [{
        enabled: false,
        isModified: false,
        provider: AuthenticationProvider.AzureAD,
        data: [],
        authorisationType:AuthorisationMode.None
    }],
    passwordPolicySetting: {
        passwordPolicy: PasswordPolicyType.NoPolicy,
        modifiedDate: new Date,
        passwordSettings: {
            passwordOptions: PasswordOptions.None,
            length: 8,
            passwordAge: 0
        }
    },
    taxSoftwareSetting: {
        taxSoftware: [TaxSoftwareType.ProSystems]
    }
}

