import { ITaxClient, ITaxpayer, ITaxClientAdditionalInfo } from './TaxClient';
import { IUserBaseModel, IUserModel, initialUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { IPaymentVoucherNotificationSettings, ISigningReminderNotificationSettings, TaxSoftwareType } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { Guid } from 'guid-typescript';
import { MessageDetails } from '../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { splitName } from '../helper/HelperFunctions';
import { DefaultSenderInfo, DefaultSenderInfoType } from '../navigation/profile/MySettings';
import { IColumnValues } from '../settings/GeneralSettings';
import { OrganizerTransaction } from '../../Organizer/models/OrganizerComponentModels';
import { OnehubAccessCodeStatus } from '../helper/StatusButtonHelper';

export enum StateUSA {
    AL = "Alabama",
    AK = "Alaska",
    AZ = "Arizona",
    AR = "Arkansas",
    CA = "California",
    CO = "Colorado",
    CT = "Connecticut",
    DC = "District of Columbia",
    DE = "Delaware",
    FL = "Florida",
    Fed = "Federal",
    GA = "Georgia",
    HI = "Hawaii",
    ID = "Idaho",
    IL = "Illinois",
    IN = "Indiana",
    IA = "Iowa",
    KS = "Kansas",
    KY = "Kentucky[E]",
    LA = "Louisiana",
    ME = "Maine",
    MD = "Maryland",
    MA = "Massachusetts[E]",
    MI = "Michigan",
    MN = "Minnesota",
    MS = "Mississippi",
    MO = "Missouri",
    MT = "Montana",
    NE = "Nebraska",
    NV = "Nevada",
    NH = "New Hampshire",
    NJ = "New Jersey",
    NM = "New Mexico",
    NY = "New York",
    NC = "North Carolina",
    ND = "North Dakota",
    OH = "Ohio",
    OK = "Oklahoma",
    OR = "Oregon",
    PA = "Pennsylvania[E]",
    PR = "Puerto Rico",
    RI = "Rhode Island[F]",
    SC = "South Carolina",
    SD = "South Dakota",
    TN = "Tennessee",
    TX = "Texas",
    UT = "Utah",
    VT = "Vermont",
    VA = "Virginia[E]",
    VI = "Virgin Islands",
    WA = "Washington",
    WV = "West Virginia",
    WI = "Wisconsin",
    WY = "Wyoming"
}

export interface ITaxReturn {
    formGroups: IGroup[];
    documentSettings: IDocumentSettings;
    id: number;
    clientId: string;
    documentStatus: DocumentStatus;
    uploadedOn: Date;
    partnerId: number;
    partner: IUserModel;
    assignToUserName: IUserModel;
    deliveredByUser: IUserModel;
    signatureStatus: SignatureStatus;
    createdBy: number;
    engagementType: EngagementType;
    taxYear: number;
    assignTo: number;
    assignedUser: IUserBaseModel;
    lockedBy: number;
    invoiceAmount: number;
    deliveredOn: Date;
    attachments: IAttachment[];
    documentGuid: string;
    isModified: boolean;
    isfullyLoaded: boolean;
    refundInfo: IRefundInfo[];
    documentAccess: IDocumentAccess;
    clientTracking: IDocumentTransaction[];
    signedDetails: ISignerModel[];
    accessCode: IAccessViewModel;
    downloadableEfileForms: IDownloadableDocuments[],
    downloadableDocuments: IDownloadableDocuments[],
    downloadHistory: IDocumentTransaction[],
    taxCaddyLookupResultModel: TaxCaddyLookupResultModel,
    taxSoftware: TaxSoftwareType,
    deliveredBy?: number
    //getClientInfo: () => IGroup;
};
export interface IIndividualTaxReturn extends ITaxReturn {
    taxpayer: ITaxpayer;
}

export interface IMarriedJointTaxReturn extends IIndividualTaxReturn {
    spouse: ITaxpayer;
}

export interface ICorporateTaxReturn extends ITaxReturn {
    partnership: ITaxClient;
}

export interface IGroup {
    group: DocumentGroups;
    forms: IFormBase[];
}

export interface IK1 extends IGroup {
    shareHolder: IShareHolder,
}

export interface IShareHolder {
    name: string,
    ssn: string,
    id: number,
    emailAddress: string,
    k1SendDate: Date,
    isK1Decline: boolean,
    k1DownloadDate: Date,
}

export enum DocumentGroups {
    None,
    Transmittals,
    EFile,
    Vouchers,
    TaxReturns,
    Invoice,
    Deleted,
    K1,
    ClientInfo
}

export interface IFormBase {
    formName: string;
    bookmark: string;
    pageNo: number[];
    formType: TaxFormType
}


export interface IVoucher extends IFormBase {
    authorityID: number;
    dueDate: Date | undefined;
    amount: number;
    voucherNo: VoucherNo;
    paymentType: VoucherTypes;
    customType: CustomType;
    onlinePaymentUri: string;
    customText: string;
    authorityImage: string;
    authorityName: string;
    groupId?: number;
    isMasterPaymentUrl: boolean;
    vocherMode: VoucherMode;
    isFormNameUpdated: boolean;
}


export enum CustomType {
    None = 0,
    Select,
    DoNotPayScheduledAutomaticWithdrawal,
    OnlinePaymentRequired,
    AddCustom
}

export enum EntityType {
    None,
    Individual,
    Partnership,
    Corporation,
    Estate,
    Trust,
    DisregardedEntity,
    ExemptOrganization,
    ForeignGovernment,
    Nominee
}

export interface IAttachment {
    fileExtension?: string,
    progressBar?: number,
    fileSize: string,
    status?: string,
    documentId: number,
    fileName: string,
    uploadedTime: Date,
    uploadedBy: number,
    instruction: string,
    id: number,
    uploadedUser: string,
    sasGuid?: string,
    url?: string,
    isDeleted: boolean,
    number?: number,
    selectedInstructionId: number,
}

//Document Settings start
export interface IDeliverySettings {
    deliverTo: ClientTypes,
    contactPerson: number,
    preparerMessage: MessageDetails,
    sender: DefaultSenderInfo,
    notifyUser: number;
    manualAddressId: number;
    isDirectDeliveryToTaxCaddy: boolean;
    deliveryMode: DeliveryMode
    paperReturnSettings: IPaperReturnSettings;
    watermarkId: number;
}

export interface IDocumentSettings {
    documentId: number;
    deliverySettings: IDeliverySettings;
    documentDisplaySetting: IDocumentDisplaySetting;
    documentSignatureSetting: IDocumentSignatureSetting;
    documentNotificationSetting: IDocumentNotificationSetting;
    documentRetentionSetting: IDocumentRetentionSetting
    isModified: boolean;
}

export interface IDocumentDisplaySetting {
    isSendWithoutInvoice: boolean;
    isEnableInvoice: boolean;
    customColumn: IColumnValues;
}

export interface IDocumentSignatureSetting {
    signatureFormSelectionType: SignatureType;
    knowledgeBasedAuthentication: boolean;
    signatureStampUser: IUserModel;
    kbaMode: KBAMode;
}

export interface IPaperReturnSettings {
    deliveryType: PaperReturnDeliveryType;
    documentOrder: DocumentGroups[];
    isNotifyUserEnabled: boolean;
}

export interface IDocumentAccess {
    documentId: number;
    userId: number[];
}

export interface IDownloadableDocuments {
    documentId: number;
    groupId: FormType;
    fileName: string;
    shareholderId: number;
    documentGuid: string;
    taxYear: number;
    createdOn: Date;
    signedDocumentId: number;
}

export enum ClientTypes {
    Undefied = "none",
    Taxpayer = "Taxpayer",
    Spouse = "Spouse",
    Partner = "Partner",
    PartnerShip = "PartnerShip",
    ShareHolder = "ShareHolder"
}

export enum ClientTypesNumber {
    Undefied = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    ShareHolder
}


export enum KBAMode {
    Test = 0,
    Live = 1
}

export enum SignatureType {
    None,
    ESign = 1,
    ESignWhenAllIncludedReturnsAllowed,
    ManualSign
}

export enum PaperReturnDeliveryType {
    None = 0,
    MultipleFiles = 1,
    SingleFile = 2
}

export enum DeliveryMode {
    ElectronicFiled = 0,
    PaperFiled = 1
}

export interface IDocumentNotificationSetting {
    paymentVoucherNotificationSettingsModel: IPaymentVoucherNotificationSettings;
    signingReminderNotificationSettingsModel: ISigningReminderNotificationSettings;
    notifyUser: IUserModel;
}

export interface IDocumentRetentionSetting {
    retentionPeriod: number;
}
//Document Settings end

export interface ISignatureControl {
    controlGuid: string;
    type?: SignatureControlTypes,
    top: number,
    left: number,
    signatureControlRole?: SignatureControlRole,
    selectedSignStyle?: string,
    selectedEroImage?: string
}

export interface IEFile extends IFormBase {
    authorityID: number;
    signatureControls: ISignatureControl[];
}

export interface IInvoice extends IFormBase {
    signatureControls: ISignatureControl[];
}

export interface ITransmittal extends IFormBase {
    signatureControls: ISignatureControl[];
}

export interface ITaxReturnGroup extends IFormBase {
    signatureControls: ISignatureControl[];
}

export interface IPayments extends IFormBase {
    autorityID: number,
    autorityName: string,
    amount: number,
    paymentType: PaymentType,
    voucherDueDate: Date
}

export enum PaymentType {
    DUE = 0,
    REFUND = 1
}

export enum VoucherTypes {
    None = 0,
    PaymentVoucher = 1,
    EstimatedVoucher
}

export enum VoucherMode {
    None = 0,
    Recognized = 1,
    CustomVoucher = 2
}

export enum TaxFormType {
    FormBase = 0,
    Transmittal = 1,
    EFile,
    Voucher,
    TaxReturn,
    Invoice,
    Refund
}

export interface IRefund extends IFormBase {
    authorityID: number,
    overPayment: number,
    refundAmount: number,
    overPaymentApplied: number,
    groupId?: number;
}

export interface IRefundInfo {
    id: number,
    documentId: number,
    authorityID: number,
    overPayment: number,
    overPaymentApplied: number,
    customRefundGUID?: Guid,
}

export interface ISignerModel {
    documentId: number;
    signer: string;
    isSigned: boolean;
    signedOn: Date;
    signerType: ClientTypesNumber;
    order: number;
    name: string;
}

export interface IAccessCode {
    name: string,
    clientGuid: string,
    documentId: number,
    email: string,
    isDeceased: boolean,
    clientType: ClientTypes,
    createdOn: Date,
    otp: string,
    retryCount: number
}
export interface IAccessViewModel {
    accessCodeDetails: IAccessCode[],
    clientEvents: OrganizerTransaction[]
}
export interface IOneHubAccessCodeDetail {
    clientName: string;
    clientEmail: string;
    otpDate: Date;
    accessCode: string;
    accessCodeStatus: OnehubAccessCodeStatus;
}

export const initialOneHubAccessCodeDetail: IOneHubAccessCodeDetail[] = [{
    clientName: '',
    clientEmail: '',
    otpDate: new Date(),
    accessCode: '',
    accessCodeStatus: OnehubAccessCodeStatus.NotRequested
}]
export enum DocumentStatus {
    None = "None",
    UPLOADED = "UPLOADED",
    ERROR = "ERROR",
    READY = "READY",
    PROCESSING = "PROCESSING",
    REVIEW = "REVIEW",
    USERSIGNED = "USERSIGNED",
    DELIVERED = "DELIVERED",
    APPROVEDFORDELIVERY = "APPROVEDFORDELIVERY",
    PREPARINGFORDELIVERY = "PREPARINGFORDELIVERY",
    DELIVERYFAILED = "DELIVERYFAILED",
    NONSUPPORTED = "NONSUPPORTED",
    RECALLED = "RECALLED",
    DELETED = "DELETED",
    APPROVEDFORDELIVERYDESCRIPTION = "APPROVED FOR DELIVERY",
    PREPARINGFORDELIVERYDESCRIPTION = "PREPARING FOR DELIVERY",
    DELIVERYFAILEDDESCRIPTION = "DELIVERY FAILED",
    NONSUPPORTEDDESCRIPTION = "NON SUPPORTED",
    REPROCESSING = "REPROCESSING",
    DELIVEREDTOTAXCADDY = "DELIVEREDTOTAXCADDY",
    TAXCADDYDELIVERYFAILED = "TAXCADDYDELIVERYFAILED",
    TAXCADDYDELIVERYFAILEDDESCRIPTION = "TAXCADDY DELIVERY FAILED",
    DUPLICATE = "DUPLICATE",
    DOWNLOADPDF = "DOWNLOADPDF",
    DOWNLOADPDFDESCRIPTION = "ALTERNATE DELIVERY",
    DOWNLOADINGFAILED = "DOWNLOADINGFAILED",
    PREPARINGFORPDFDOWNLOAD = "PREPARINGFORPDFDOWNLOAD",
    DOWNLOADINGFAILEDDESCRIPTION = "DOWNLOADING FAILED",
    PREPARINGFORPDFDOWNLOADDESCRIPTION = "PREPARING FOR PDF DOWNLOAD",
    TOBEREPROCESSED = "TOBEREPROCESSED",
    TOBEREPROCESSEDDESCRIPTION = "TO BE REPROCESSED"
}

export enum SignatureStatus {
    None = 0,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    AutoParsed,
    DeliveredToTaxCaddy,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed,
    UploadInProgress,
    TaxCaddyDeliveryFailed,
    AlternateDelivery,
    DownloadingFailed,
    AlternateAndUploaded,
    PartiallySigned
}

export enum EngagementType {
    None = 0,
    E1040,
    E1065,
    E1120,
    E1120S,
    E1041
}

export enum FormType {
    None = 0,
    Transmittals = 1,
    EFile,
    Vouchers,
    TaxReturns,
    Invoice,
    Deleted,
    K1
}
export enum SignatureControlRole {
    None = 0,
    Taxpayer,
    Spouse,
    ERO,
    PartnerShip
}

export enum SignatureControlTypes {
    None = 0,
    Signature = 1,
    Initial = 2,
    Date = 3,
    NameAndTitle = 4,
    Text = 10,
    Company = 11
}

export enum SignatureGroupType {
    None = 0,
    Esign,
    Stamp
}

export interface ITaxReturnMetadata {
    taxDocumentName: string,
    clientId: string,
    partner: IUserModel,
    documentStatus: DocumentStatus,
    uploadedOn: Date,
    engagementType: EngagementType,
    taxYear: number,
    assignTo: number
}

export interface ITaxingAuthority {
    Id: number,
    AuthorityName: string,
    isEsignEnabled: boolean,
    Abbreviation?: string,
    PaymentURL: string,
    PrefixStateCode: boolean,
    Active: boolean,
    CustomAuthority: boolean,
    StateImage?: string
    InUse: number
}

export enum VoucherNo {
    None = 0,
    PaymentVoucher,
    Q1,
    Q2,
    Q3,
    Q4
}

export interface IDocumentTransaction {
    documentId: number,
    eventId: DocumentEvent,
    actedOn: Date,
    actedBy: IDocumentTransactionUser,
    eventData: IDocumentEventData,
}

export interface IDocumentTransactionUser {
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export interface IDocumentEventData {
    fileName: string;
    ipAddress: string;
    mailEventId: MailEvent;
    recipient: IDocumentTransactionUser;
}

export enum MailEvent {
    None = 0,
    Click = 1,
    Delivered = 2,
    Opened = 3,
    Processed = 4,
    Dropped = 5,
    Bounce = 6,
    Deferred = 7,
    SpamReport = 8,
    ScheduleFailed = 9
}

export enum DocumentEvent {
    None,
    Created = 1,
    DocumentAssignTo = 2,
    DocumentEROStampDecline = 3,
    EROSignDecline = 4,
    DocumentDeliveryApproved = 5,
    Emailed = 6,
    DocumentChangeStatusManual = 7,
    TaxReturnAccessed = 8,
    DocumentAuthenticated = 9,
    AuthenticationFailed = 10,
    AccessLocked = 11,
    OTPAuthenticationFailed = 12,
    OTPAccessLocked = 13,
    TaxReturnReviewed = 14,
    Viewed = 15,
    EfileFormsDownloaded = 16,
    FileUploaded = 17,
    TaxpayerEsigned = 18,
    SpouseEsigned = 19,
    VouchersReviewed = 20,
    VoucherReminderDisabled = 21,
    VoucherReminderEnabled = 22,
    AttachmentDownloaded = 23,
    AllAttachmentDownloaded = 24,
    AllK1DocumentDownloaded = 25,
    DocumentAttachmentsReviewed = 26,
    PaymentSystemAccessed = 27,
    InvoiceReviewed = 28,
    Finished = 29,
    AccessLinkEmailed = 30,
    TransmittalReviewed = 31,
    DocumentTaxSaved = 32,
    K1DistributedTo = 33,
    DocumentDownloadAll = 34,
    DocumentFilingInstructionsDownloaded = 35,
    DocumentTaxReturnDownloaded = 36,
    DocumentVouchersDownloaded = 37,
    DocumentInvoiceDownloaded = 38,
    DocumentSignedEfileDownloaded = 39,
    KBAPassed = 40,
    KBAFailed = 41,
    DocumentKBASuccess = 42,
    DocumentKBAFailed = 43,
    DOBChanged = 44,
    DocumentOptOutESignOptionSelected = 45,
    DocumentSigned = 46,
    SignedDocumentReviewed = 47,
    VoucherReminderUpdated = 48,
    Deleted = 49,
    DocumentFileDeleted = 50,
    ShareholderConsentReceived = 51,
    ShareholderConsentDeclined = 52,
    K1DocumentDownloaded = 53,
    K1InstructionDocumentDownloaded = 54,
    DocumentMailStatus = 55,
    ReturnIsRecalled = 56,
    VoucherRemainderMailStatus = 57,
    DocumentRequestRecallReturn = 58,
    DocumentRequestDeleteReturn = 59,
    Shared = 60,
    Cancelled = 61,
    Filed = 62,
    DocumentRejected = 63,
    AccessCodeEmail = 64,
    PartnershipEsigned = 65,
    DocumentSignedEfileDownloadedByCPA = 66,
    ReminderSendForSigning = 67,
    DownloadLinkSend = 68,
    PaymentCompleted = 69,
    NotDefined = 75,
    UploadInProgress = 76,
    DeliverdToTaxcaddy = 77,
    TaxcaddyDeliveryFailed = 78,
    SigningReminderSend = 79,
    SigningReminderMailStatus = 80,
    K1ShareHolderReminderMailStatus = 81,
    K1TaxpayerReminderMailStatus = 82
}

export function getAllStateImages() {
    let stateImages: { imageName: string, stateUSA: StateUSA }[] = [
        { imageName: 'state-alaska.png', stateUSA: StateUSA.AK },
        { imageName: 'state-alabama.png', stateUSA: StateUSA.AL },
        { imageName: 'state-arkansas.png', stateUSA: StateUSA.AR },
        { imageName: 'state-arizona.png', stateUSA: StateUSA.AZ },
        { imageName: 'state-california.png', stateUSA: StateUSA.CA },
        { imageName: 'state-colorado.png', stateUSA: StateUSA.CO },
        { imageName: 'state-connecticut.png', stateUSA: StateUSA.CT },
        { imageName: 'state-dc.png', stateUSA: StateUSA.DC },
        { imageName: 'state-delaware.png', stateUSA: StateUSA.DE },
        { imageName: 'state-florida.png', stateUSA: StateUSA.FL },
        { imageName: 'icon-goverment.png', stateUSA: StateUSA.Fed },
        { imageName: 'state-georgia.png', stateUSA: StateUSA.GA },
        { imageName: 'state-hawaii.png', stateUSA: StateUSA.HI },
        { imageName: 'state-iowa.png', stateUSA: StateUSA.IA },
        { imageName: 'state-idaho.png', stateUSA: StateUSA.ID },
        { imageName: 'state-illinois.png', stateUSA: StateUSA.IL },
        { imageName: 'state-indiana.png', stateUSA: StateUSA.IN },
        { imageName: 'state-kansas.png', stateUSA: StateUSA.KS },
        { imageName: 'state-kentucky.png', stateUSA: StateUSA.KY },
        { imageName: 'state-kentucky.png', stateUSA: StateUSA.KY },
        { imageName: 'state-louisiana.png', stateUSA: StateUSA.LA },
        { imageName: 'state-massachusetts.png', stateUSA: StateUSA.MA },
        { imageName: 'state-maryland.png', stateUSA: StateUSA.MD },
        { imageName: 'state-main.png', stateUSA: StateUSA.ME },
        { imageName: 'state-michigan.png', stateUSA: StateUSA.MI },
        { imageName: 'state-minnesota.png', stateUSA: StateUSA.MN },
        { imageName: 'state-missouri.png', stateUSA: StateUSA.MO },
        { imageName: 'state-mississippi.png', stateUSA: StateUSA.MS },
        { imageName: 'state-montana.png', stateUSA: StateUSA.MT },
        { imageName: 'state-north-carolina.png', stateUSA: StateUSA.NC },
        { imageName: 'state-north-dakota.png', stateUSA: StateUSA.ND },
        { imageName: 'state-nebraska.png', stateUSA: StateUSA.NE },
        { imageName: 'state-new-hampshire.png', stateUSA: StateUSA.NH },
        { imageName: 'state-new-jersey.png', stateUSA: StateUSA.NJ },
        { imageName: 'state-new-mexico.png', stateUSA: StateUSA.NM },
        { imageName: 'state-nevada.png', stateUSA: StateUSA.NV },
        { imageName: 'state-new-york.png', stateUSA: StateUSA.NY },
        { imageName: 'state-ohio.png', stateUSA: StateUSA.OH },
        { imageName: 'state-oklahoma.png', stateUSA: StateUSA.OK },
        { imageName: 'state-oregon.png', stateUSA: StateUSA.OR },
        { imageName: 'state-pensylvania.png', stateUSA: StateUSA.PA },
        { imageName: 'state-puertorico.png', stateUSA: StateUSA.PR },
        { imageName: 'state-rhode-iland.png', stateUSA: StateUSA.RI },
        { imageName: 'state-south-carolina.png', stateUSA: StateUSA.SC },
        { imageName: 'state-south-dakota.png', stateUSA: StateUSA.SD },
        { imageName: 'state-tennessee.png', stateUSA: StateUSA.TN },
        { imageName: 'state-texas.png', stateUSA: StateUSA.TX },
        { imageName: 'state-utah.png', stateUSA: StateUSA.UT },
        { imageName: 'state-virginia.png', stateUSA: StateUSA.VA },
        { imageName: 'state-usvirginislands.png', stateUSA: StateUSA.VI },
        { imageName: 'state-vermont.png', stateUSA: StateUSA.VT },
        { imageName: 'state-washington.png', stateUSA: StateUSA.WA },
        { imageName: 'state-wisconsin.png', stateUSA: StateUSA.WI },
        { imageName: 'state-west-virginia.png', stateUSA: StateUSA.WV },
        { imageName: 'state-wyoming.png', stateUSA: StateUSA.WY },

    ]
    return stateImages;
}

export function isIndividual(arg: any): arg is IIndividualTaxReturn {
    return arg.taxpayer !== undefined
        && (arg.spouse === undefined || arg.spouse === null)
        && arg.partnership === undefined;
}

export function isMutual(arg: any): arg is IMarriedJointTaxReturn {
    return arg.taxpayer !== undefined
        && arg.spouse !== undefined
        //&& arg.spouse !== null
        && arg.partnership === undefined;
}

export function isPartnership(arg: any): arg is ICorporateTaxReturn {
    return arg && arg.partnership !== undefined
        && arg.taxpayer === undefined
        && arg.spouse === undefined;
}

export function engagementType(engagementType: EngagementType) {
    return engagementType.toString().slice(1, 10);
}


export interface TaxCaddyLookupResult {
    isTaxCaddyDelivery: boolean;
    isTaxCaddyLookupAccepted: boolean;
    taxpayerUniqueId: number;
    taxCaddyTransactionEventdata: TaxCaddyLookupResultObjectModel;
}

export interface TaxCaddyLookupResultModel {
    isSuccess: boolean;
    result: string;
    resultObjectModel: TaxCaddyLookupResultObjectModel;
}

export interface TaxCaddyLookupResultObjectModel {
    taxPayerUniqueId: number;
    taxpayerName: string;
    emailAddress: string;
    ssn: string;
    address: string;
    taxClientId: string;
    taxYear: number[];
}

export const DaysRangeOptionsList = {
    One_Day: '24 Hours',
    Two_Days: '2 Days',
    Three_Days: '3 Days',
    Five_Days: '5 Days',
    Seven_Days: '1 Week',
    Fifteen_Days: '15 Days',
    Thirty_Days: '30 Days'
};
