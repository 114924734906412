import * as React from 'react';
import * as bootbox from 'bootbox';
import 'isomorphic-fetch';

import { RouteComponentProps } from 'react-router';
import { PreparerMessageModal } from '../PreparerMessage/PreparerMessageModal';
import { OrganizerPrepareMessageStoreState, OrganizerSettingsStoreState } from '../../../models/OrganizerStoreModels';
import * as PreparerMessageStore from './../../../store/OrganizerPreparerMessageStore';
import * as CompanySetings from './../../../store/OrganizerCompanySettingsStore';
import { PreparerMessageConstants } from '../../Helper/OrganizerConstants';
import { getVariableList } from '../../Helper/OrganizerHelperFunctions';
import { PreparerMessageComponent } from '../PreparerMessage/PreparerMessage';
import { initialPreparerMessage, PreparerMessage } from '../../../models/OrganizerComponentModels';

var htmlencode = require('htmlencode');
const isEqual = require("react-fast-compare");
const pageTitle = "SavedMessages";
export enum ModalType {
    NONE,
    ADD,
    EDIT
  }
export interface SavedMessageState {
    showAddMessagePopUp: boolean;
    showLoader: boolean;
    loaderText: string;

    selectedPrepareMessageId: number;
    defaultPrepareMessageId: number;
    isUpdatePrepareMessage: boolean;
    prepareMessageButtonText: string;
    modalType: ModalType;
    showPopUpLoader: boolean;
}

type SavedMessageProps = {
    companySettingStore: OrganizerSettingsStoreState;
    preparerMessageStore: OrganizerPrepareMessageStoreState;
}
    & typeof CompanySetings.ActionCreators
    & typeof PreparerMessageStore.actionCreators
    & RouteComponentProps<{}>

export class SavedMessages extends React.Component<SavedMessageProps, SavedMessageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showAddMessagePopUp: false,
            showLoader: false,
            loaderText: "",

            selectedPrepareMessageId: 0,
            defaultPrepareMessageId: 0,
            isUpdatePrepareMessage: false,
            prepareMessageButtonText: "",
            modalType: ModalType.NONE,
            showPopUpLoader: false,
        }
    }

    componentDidMount() {
        this.props.requestPreparerMessages();
        this.props.requestOrganizerCompanySettings();
        this.setState({ showLoader: false, loaderText: "Loading...." })
    }

    componentWillReceiveProps(nextProps: SavedMessageProps) {
        if (!isEqual(this.props, nextProps)) {
            if (nextProps.companySettingStore.companySettings) {
                this.setState({
                    defaultPrepareMessageId: nextProps.companySettingStore.companySettings.defaultSettings.savedMessage,
                })
            }
            if (this.props.companySettingStore.companySettings && this.props.preparerMessageStore.preparerMessages.length > 0) {
                this.setState({
                    selectedPrepareMessageId: this.props.companySettingStore.companySettings.defaultSettings.savedMessage != 0 ? this.props.companySettingStore.companySettings.defaultSettings.savedMessage : this.props.preparerMessageStore.preparerMessages[0].id,
                });
            }
        }

        this.setState({
            showLoader: !nextProps.companySettingStore.loading &&
                !nextProps.preparerMessageStore.loading ? false : true,
            showPopUpLoader: !nextProps.companySettingStore.loading &&
                !nextProps.preparerMessageStore.loading ? false : true,
        }, () => {
            if (!this.state.showPopUpLoader) this.setState({
                showAddMessagePopUp: false
            })
        })
    }

    //===============List Action Button Start===========


    openAddMessagePopUp = () => {
        this.setState({
            showAddMessagePopUp: true,
            prepareMessageButtonText: "Add",
            isUpdatePrepareMessage: false,
            modalType: ModalType.ADD,
            loaderText: "Saving message"
        });
    }
    deleteButtonClick = () => {
        if (this.state.selectedPrepareMessageId == 0 || this.state.selectedPrepareMessageId == this.state.defaultPrepareMessageId) {
            bootbox.alert({
                message: PreparerMessageConstants.DeleteMessageWarning,
                size: 'small',
                buttons: {
                    ok: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn btn-success'
                    }
                }
            });
        }
        else {
            const _currentObject = this
            bootbox.confirm({
                title: 'Delete Message',
                message: 'Are you sure you want to delete this message?',
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        const selectedMessage = _currentObject.props.preparerMessageStore.preparerMessages
                            .find(message => message.id == _currentObject.state.selectedPrepareMessageId);
                        if (selectedMessage) {
                            _currentObject.props.deletePreparerMessage(selectedMessage);
                            _currentObject.setState({
                                selectedPrepareMessageId: _currentObject.state.defaultPrepareMessageId != 0 ? _currentObject.state.defaultPrepareMessageId : this.props.preparerMessageStore.preparerMessages[0].id,
                                showLoader: true,
                                loaderText: "Deleting message"
                            })
                        }
                    }
                }
            });
        }
    }
    openEditMessagePopUp = () => {
        this.setState({
            showAddMessagePopUp: true,
            prepareMessageButtonText: "Save",
            isUpdatePrepareMessage: true,
            modalType: ModalType.EDIT,
            loaderText: "Saving message"
        });
    }
    onListItemClick = (messageId: number) => {
        this.setState({
            selectedPrepareMessageId: messageId
        });
    }
    closeAddPopUp = () => {
        this.setState({ showAddMessagePopUp: false, isUpdatePrepareMessage: false });
    }
    updateButtonClick = (messageDetail: PreparerMessage, isSetDefault: boolean, isToggled: boolean) => {
        const { companySettings } = this.props.companySettingStore;
        if (isToggled) {
            companySettings.defaultSettings.savedMessage = isSetDefault ? messageDetail.id : 0;
            this.props.updateOrganizerCompanySettings(companySettings);
        }
        messageDetail.body = htmlencode.htmlEncode(messageDetail.body)
        this.props.updatePreparerMessage(messageDetail);
        this.setState({
            defaultPrepareMessageId: companySettings.defaultSettings.savedMessage,
            selectedPrepareMessageId: companySettings.defaultSettings.savedMessage == 0 ? messageDetail.id : companySettings.defaultSettings.savedMessage,
            isUpdatePrepareMessage: false,
            showPopUpLoader: true
        })
    }
    addButtonClick = (messageDetail: PreparerMessage, isSetDefault: boolean, isToggled: boolean) => {
        const { companySettings } = this.props.companySettingStore;
        messageDetail.body = htmlencode.htmlEncode(messageDetail.body)
        this.props.savePreparerMessage(messageDetail, companySettings, isSetDefault);
        this.setState({ selectedPrepareMessageId: this.state.defaultPrepareMessageId, showPopUpLoader: true })
    }


    public render() {
        const { showLoader, defaultPrepareMessageId, selectedPrepareMessageId, showPopUpLoader,
            isUpdatePrepareMessage, prepareMessageButtonText, showAddMessagePopUp, modalType, loaderText
        } = this.state;
        const { preparerMessageStore: { preparerMessages },
            companySettingStore: { companySettings: { defaultSettings: { savedMessage } } }
        } = this.props;
        const prepareId = selectedPrepareMessageId ? selectedPrepareMessageId : defaultPrepareMessageId;
        const selectedPreparerMessage = preparerMessages.find(message => message.id === prepareId);

        return (
            <div>
                <h3 style={{ marginTop: '5px' }}>Saved Messages</h3>
                <hr />
                <PreparerMessageComponent
                    heading={'Message from Tax Preparer'}
                    description={PreparerMessageConstants.AddMessageInfo}
                    defaultMessage={savedMessage}
                    onAddButtonClick={this.openAddMessagePopUp}
                    onDeleteButtonClick={this.deleteButtonClick}
                    onEditButtonClick={this.openEditMessagePopUp}
                    onListItemClick={this.onListItemClick}
                    preparerMessages={preparerMessages}
                    selectedMessage={selectedPrepareMessageId}
                    showLoader={showLoader}
                />
                <PreparerMessageModal
                    buttonText={prepareMessageButtonText}
                    defaultMessage={defaultPrepareMessageId}
                    isUpdate={isUpdatePrepareMessage}
                    loaderText={loaderText}
                    onHide={this.closeAddPopUp}
                    onShow={showAddMessagePopUp}
                    preparerMessages={preparerMessages}
                    selectedPreparerMessage={!isUpdatePrepareMessage ? initialPreparerMessage : selectedPreparerMessage ? selectedPreparerMessage : initialPreparerMessage}
                    showLoader={showPopUpLoader}
                    submitButtonClick={isUpdatePrepareMessage ? this.updateButtonClick : this.addButtonClick}
                    modalType={modalType}
                    variableList={getVariableList()}
                />

            </div>
        )
    }
}

