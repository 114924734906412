export interface MessageDetails {
    id: number;
    name: string;
    body: string;
    isAllowToEdit: boolean;
}

export const initialMessageDetails = {
    id: 0,
    name: "",
    body: "",
    isAllowToEdit: false
}