import { RouterAction } from 'connected-react-router';
import { Action, Reducer } from 'redux';
import { API_BASE_URL, SSSUITE_API_BASE_URL } from 'src/utils/constants';
import { AppThunkAction } from '../';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { ITaxingAuthority } from '../../components/common/TaxReturn';
import * as Constants from '../../components/helper/Constants';
import * as Helper from '../../components/helper/HelperFunctions';
import { IDownloadedZipFilesModel, StatusZipDownload } from '../../components/navigation/profile/MyDownload';
import { ICompanyLedgerModel } from '../../Core/ViewModels/Billing/CompanyLedgerModel';
import { IDefinedPasswordPolicy } from '../../Core/ViewModels/Common/DefinedPasswordPolicy';
import { ICompanyMfaSettings, ICompanySettings, IExternalAuthenticationSettings, initialCompanySettings, ITaxSoftwareSettings, ICommonSettings, initialCommonSettings, IUteSettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IAdmin, ICompany, ICompanyInfo, ICompanyLogoSetting, initalMyDownloadsList, initialAdminModel, initialCompanyLogoSettings, initialCompanyModel, IState } from '../../Core/ViewModels/Company/CompanyViewModel';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { ErrorExpireTaxDocumentCountAction, ReceiveExpireDocumentsCount, RequestExpireDocumentsCount } from "../reports/KnownTypes";
const isEqual = require("react-fast-compare");

export interface IAuthorityDictionary {
    [index: number]: ITaxingAuthority;
}

export interface ICompanyData { //TODO: should be a dictionary so that we can pick a user's details with 'userId' from this.
    //DataPart
    companySettings: ICompanySettings | undefined;
    commonSettings: ICommonSettings | undefined;
    taxingAuthorities: ITaxingAuthority[];
    companyProfile: ICompany;
    admins: IAdmin;
    companyLogoSetting: ICompanyLogoSetting;
    signatureUploadLink: string;
    logoUploadLink: string;
    authorityLookup: IAuthorityDictionary;
    definedPasswordPolicies: IDefinedPasswordPolicy[];
    ledgerData: ICompanyLedgerModel[];
    groupIDs: string[];
    myDownloads: IDownloadedZipFilesModel[];

    //Meta infomation
    isLoading: boolean;
    error: boolean;
    message: string;

    //extra information
    documentToExpireCount: number;
    isDocumentStoreDirty: boolean;
    isCompanyOrganizerProductEnabled: boolean;
    isCompanyOrganizerSubscriptionEnabled: boolean;
}

export interface RequestProductStatus {
    type: actionTypes.REQUEST_PRODUCT_STATUS;
    status: any;
}

export interface RequestCompanySettingsAction {
    type: actionTypes.REQUEST_COMPANY_SETTINGS;
    message: string;
}

export interface RequestCommonSettingsAction {
    type: actionTypes.REQUEST_COMMON_SETTINGS;
    message: string;
}

export interface GetMyDownloadsList {
    type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST;
    myDownloadsList: any[];
}

export interface ReceiveCompanySettingsAction {
    type: actionTypes.RECEIVE_COMPANY_SETTINGS;
    settings: ICompanySettings;
}

export interface ReceiveCommonSettingsAction {
    type: actionTypes.RECEIVE_COMMON_SETTINGS;
    settings: ICommonSettings;
}

export interface UpdateCompanySettingsAction {
    type: actionTypes.UPDATE_COMPANY_SETTINGS;
    settings: ICompanySettings;
}

export interface UpdateCompanySettingsLocalAction {
    type: actionTypes.UPDATE_COMPANY_SETTINGS_LOCAL;
    isEnableIPFiltering: boolean;
}

export interface ErrorCompanySettingsAction {
    type: actionTypes.ERROR_COMPANY_SETTINGS;
    reason: string;
}

export interface RequestTaxAuthoritiesAction {
    type: actionTypes.REQUEST_TAX_AUTHORITIES;
}

export interface ReceiveTaxAuthoritiesAction {
    type: actionTypes.RECEIVE_TAX_AUTHORITIES;
    taxingAuthorities: ITaxingAuthority[];
}

export interface ReceiveCompanyLogoAction {
    type: actionTypes.RECEIVE_COMPANY_LOGO;
    logoPath: string;
    isSsrLogo: boolean;
}

export interface ReceiveCompanyProfileAction {
    type: actionTypes.RECEIVE_COMPANY_PROFILE;
    companyProfile: ICompany;
}

export interface ReceiveAdminProfileAction {
    type: actionTypes.RECEIVE_ADMIN_PROFILE;
    admins: IAdmin;
}

export interface UpdateCompanyProfileAction {
    type: actionTypes.UPDATE_COMPANY_PROFILE;
    companyInfo: ICompanyInfo;
}

export interface RequestCompanySignatureUploadLinkAction {
    type: actionTypes.UPDATE_COMPANY_SIGNATURE;
    sasUrl: string;
}

export interface RequestCompanyLogoUploadLinkAction {
    type: actionTypes.UPDATE_COMPANY_LOGO;
    sasUrl: string;
}


export interface ValidateAzureADGroupAction {
    type: actionTypes.VALIDATE_AD_AZURE_GROUP;
    groupIDs: string[];
}

export interface RefreshTaxDocumentStore {
    type: actionTypes.REFRESH_TAX_DOCUMENT_STORE;
    isDirty: boolean;
}

interface ReceiveDefinedPasswordPolicyAction {
    type: actionTypes.RECEIVE_DEFINED_PASSWORD_POLICY;
    definedPasswordPolicies: IDefinedPasswordPolicy[];
}

interface ReceiveCompanyLedger {
    type: actionTypes.RECEIVE_COMPANY_LEDGER;
    ledgerData: ICompanyLedgerModel[];
}

interface UpdateExternalAuthenticationSettings {
    type: actionTypes.UPDATE_EXTERNAL_AUTHENTICATION_SETTINGS,
    externalAuthenticationSettings: IExternalAuthenticationSettings[]
}

interface UpdateMFASettings {
    type: actionTypes.UPDATE_MFA_SETTINGS,
    mfaSettings: ICompanyMfaSettings
}

interface UpdateOrganizerSettings {
    type: actionTypes.UPDATE_COMPANY_SETTINGS_ORGANIZER,
    enabled: boolean
}

type DispatchAction = RequestCompanySettingsAction |
    RequestCommonSettingsAction |
    ReceiveCompanySettingsAction |
    ReceiveCommonSettingsAction |
    ErrorCompanySettingsAction |
    RequestTaxAuthoritiesAction |
    ReceiveTaxAuthoritiesAction |
    UpdateCompanySettingsLocalAction |
    ReceiveCompanyLogoAction |
    ReceiveCompanyProfileAction |
    ReceiveAdminProfileAction |
    UpdateCompanyProfileAction |
    RequestCompanySignatureUploadLinkAction |
    RequestCompanyLogoUploadLinkAction |
    NotificationAction |
    ReceiveDefinedPasswordPolicyAction |
    ReceiveCompanyLedger |
    RequestExpireDocumentsCount |
    ReceiveExpireDocumentsCount |
    ErrorExpireTaxDocumentCountAction |
    ValidateAzureADGroupAction |
    RefreshTaxDocumentStore |
    UpdateExternalAuthenticationSettings |
    UpdateMFASettings |
    GetMyDownloadsList |
    UpdateOrganizerSettings |
    RequestProductStatus;

type KnownAction =
    DispatchAction |
    UpdateCompanySettingsAction |
    RouterAction |
    RequestExpireDocumentsCount |
    ReceiveExpireDocumentsCount |
    ErrorExpireTaxDocumentCountAction;

export const actionCreators = {
    requestCompanySettings: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.companySettings || isEqual(state.companySettings, initialCompanySettings)) {
            fetch(API_BASE_URL + 'api/Company/CompanySettings/GetCompanySettings', {
                method: 'GET',
                credentials: 'include',
            })
                .then(handleResponse)
                .then(json => json as Promise<ICompanySettings>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_SETTINGS, settings: data });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error
                    })
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });

                });
            
            dispatch({
                type: actionTypes.REQUEST_COMPANY_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
            });
        }
    },

    requestCommonSettings: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.commonSettings || isEqual(state.commonSettings, initialCommonSettings)) {
            fetch(API_BASE_URL + 'api/Company/CommonSettings/GetCommonSettings', {
                method: 'GET',
                credentials: 'include',
            })
                .then(handleResponse)
                .then(json => json as Promise<ICommonSettings>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMMON_SETTINGS, settings: data });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CommonSettingsError,
                        statusType: StatusType.Error
                    })
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });

                });
            
            dispatch({
                type: actionTypes.REQUEST_COMMON_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
            });
        }
    },

    requestCompanyLogo: (reload: boolean = false, isLogoUpdate: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload) {
            fetch(SSSUITE_API_BASE_URL + 'api/logo/company-logo', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json)
                .then(data => {
                    var isSsrLogo = data.isSsrlogo == "True" ? true : false;
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_LOGO, logoPath: data.logoPath, isSsrLogo: isSsrLogo });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyLogoError,
                        statusType: StatusType.Error
                    })
                });
            
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
        }
    },

    requestCompanyProfile: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.companyProfile || state.companyProfile.companyInfo.companyName.length === 0) {
            fetch(API_BASE_URL + 'api/Company/GetCompanyProfile', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IState>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_COMPANY_PROFILE, companyProfile: data.company });
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyProfileError,
                        statusType: StatusType.Error
                    })
                });
            
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
        }
    },

    requestCompanySignatureUploadLink: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + '/api/download/GetCompanySignatureUploadLinkAsync', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                dispatch({ type: actionTypes.UPDATE_COMPANY_SIGNATURE, sasUrl: data.sas });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusType: StatusType.Error,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError
                });
            });
        
        dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
    },
    
    getMyDownloadsListAsync: (callback?: (result: any[]) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Organizer/MyDownload/GetAsync', {
            method: 'GET',
            credentials: 'include'
        }).then(handleResponse).then(response => response as Promise<any[]>)
            .then(data => {
                if (data.length > 0 && Helper.SessionStore.isExists("DownloadNowIds")) {
                    let downloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                    var ids = JSON.parse(downloadNowIds) as string[];
                    let updatedDownloadNowIds: string[] = [];
                    ids.forEach((id, i) => {
                        var downoadFile = data.find(i => i.id == id);
                        if (downoadFile != undefined) {
                            if (downoadFile.status == StatusZipDownload[StatusZipDownload.Ready]
                                || downoadFile.status == StatusZipDownload[StatusZipDownload.PartialReady]) {
                                dispatch({
                                    type: actionTypes.NOTIFICATION,
                                    statusMessage: "File is ready for download",
                                    statusType: StatusType.Success
                                });
                                let downoadAction: any = actionCreators.downloadReturnFromPopup(downoadFile.jobId, downoadFile.fileName, true, getDownloadSuccessDetails);
                                dispatch(downoadAction);
                            }
                            else {
                                if (downoadFile.status == StatusZipDownload[StatusZipDownload.Error]) {
                                    dispatch({
                                        type: actionTypes.NOTIFICATION,
                                        statusMessage: downoadFile.message,
                                        statusType: StatusType.Error
                                    });
                                    let deleteaction: any = actionCreators.deleteMyDownloads(downoadFile.jobId, true);
                                    dispatch(deleteaction);
                                    data.splice(data.findIndex((m: any) => m.jobId == downoadFile.jobId), 1);
                                }
                                else {
                                    updatedDownloadNowIds.push(id);
                                }
                            }
                        }
                    });

                    Helper.SessionStore.set("DownloadNowIds", JSON.stringify(updatedDownloadNowIds));
                }
                if (callback) {
                    callback(data);
                }
                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
            });
        
    },
    
    deleteMyDownloads: (jobId: string, isDownloadNow?: boolean, callback?: (jobId: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        fetch(API_BASE_URL + 'api/Organizer/MyDownload/DeleteAsync?jobId=' + jobId, {
            method: 'DELETE',
            credentials: 'include'
        }).then(handleResponse)
            .then((response) => {
                let data: any = state.companyData.myDownloads;
                data.splice(data.findIndex((m: any) => m.jobId == jobId), 1);

                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data });
                if (isDownloadNow == undefined || !isDownloadNow) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.MyDownloadsConstants.DeleteMyDownload,
                        statusType: StatusType.Success
                    });
                    if (callback) {
                        callback(jobId);
                    }
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Delete the Selected File",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
            });
        
    },
    
    clearAllMyDownloads: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        fetch(API_BASE_URL + 'api/Organizer/MyDownload/DeleteAllAsync', {
            method: 'DELETE',
            credentials: 'include'
        }).then(handleResponse)
            .then((response) => {

                let data = state.companyData.myDownloads;
                data = data.filter(function (download: any) {
                    return download.status == StatusZipDownload[StatusZipDownload.InProgress];
                });

                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.MyDownloadsConstants.ClearAllMyDownloads,
                    statusType: StatusType.Success
                });

                if (callback) {
                    callback();
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to Clear your Downloads",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
            });
        

    },
    
    downloadReturnFromPopup: (
        jobId: string,
        fileName: string,
        isDownloadNow?: boolean,
        callback?: (url: string) => void
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(API_BASE_URL + 'api/Organizer/MyDownload/DownloadReturnFromPopupAsync', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                jobId: jobId,
                fileName: fileName
            })
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(data.sas, fileName);

                if (callback && isDownloadNow != null && isDownloadNow) {
                    // Wait for 3 seconds for the download to fetch
                    setTimeout(() => {
                        let deleteAction = actionCreators.deleteMyDownloads(jobId, isDownloadNow);
                        dispatch(deleteAction);
                    }, 3000);
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.response ? error.response.statusText : "Failed to Download the Selected File",
                    statusType: StatusType.Error
                });
            });
        
    },

    requestProductStatus: (callback?: () => void, failureCallback?: () => void, forbiddenAccessFallback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}api/Organizer/CompanyProductStatus/GetCompanyProductStatus`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                dispatch({ type: actionTypes.REQUEST_PRODUCT_STATUS, status: data });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                if (error.status == 419) {
                    failureCallback && failureCallback();
                }
                else if (error.status == 403) {
                    forbiddenAccessFallback && forbiddenAccessFallback();
                }
                else {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusType: StatusType.Error,
                        statusMessage: error.response?.statusText
                    });
                }
            });
        
    },
    getWalkMeScript: (callback: (script: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${API_BASE_URL}/api/Common/GetWalkMeScript`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                callback(data);
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : "Failed to load the WalkMeScript",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
            });
        
    },
    getORGSessionTimeOut: (callback?: (timeOut: number) => void): AppThunkAction<KnownAction> =>
        (dispatch, getState) => {
            const fetchTask = fetch(`${API_BASE_URL}/api/Common/GetORGSessionTimeOut`, {
                method: 'GET',
                credentials: 'include'
            }).then(handleResponse).then(data => {
                callback && callback(parseInt(data));
            })
                .catch(error => {
                    callback && callback(20);
                });
            
        },       
        getUTESettings: (callback?: (data: IUteSettings) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
            fetch(API_BASE_URL + 'api/Company/CommonSettings/ute-setting', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(response => response.json() as Promise<IUteSettings>)
                .then(data => {
                    if (callback) {
                        callback(data);
                    }
                }).catch(function (error) {
                });
            
        }    
};

const unloadedState: ICompanyData = {
    companySettings: initialCompanySettings,
    commonSettings: initialCommonSettings,
    myDownloads: initalMyDownloadsList,
    companyLogoSetting: initialCompanyLogoSettings,
    admins: initialAdminModel,
    companyProfile: initialCompanyModel,
    signatureUploadLink: '',
    logoUploadLink: '',
    documentToExpireCount: 0,
    isDocumentStoreDirty: false,
    isCompanyOrganizerProductEnabled: true,
    isCompanyOrganizerSubscriptionEnabled: true
} as ICompanyData;

export const arrayToHash = (array: any[], id: string = 'userId') =>
    array.reduce((obj, item) => (obj[item[id]] = item, obj), {})

export const reducer: Reducer<ICompanyData> = (state: ICompanyData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_COMPANY_SETTINGS:
            var received = { ...state };
            received.taxingAuthorities = state.taxingAuthorities ? [...state.taxingAuthorities] : state.taxingAuthorities;
            received.authorityLookup = state.authorityLookup ? { ...state.authorityLookup } : state.authorityLookup,
                received.companySettings = state.companySettings ? { ...state.companySettings } : state.companySettings,
                received.isLoading = true;
            received.error = false;
            received.message = action.message;
            received.companyLogoSetting = state.companyLogoSetting;
            return received;

        case actionTypes.REQUEST_COMMON_SETTINGS:
            var received = { ...state };
            received.isLoading = true;
            received.error = false;
            received.message = action.message;
            return received;

        case actionTypes.RECEIVE_COMPANY_SETTINGS:
            var received = { ...state };
            received.companySettings = action.settings;
            received.companySettings.isDefault = false;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_COMMON_SETTINGS:
            var received = { ...state };
            received.commonSettings = action.settings;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.ERROR_COMPANY_SETTINGS:
            return {
                companySettings: Object.assign({}, state.companySettings),
                commonSettings: Object.assign({}, state.commonSettings),
                taxingAuthorities: state.taxingAuthorities ? [...state.taxingAuthorities] : state.taxingAuthorities,
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                authorityLookup: state.authorityLookup ? { ...state.authorityLookup } : state.authorityLookup,
                isLoading: false,
                error: true,
                message: action.reason,
                myDownloads: state.myDownloads
            } as ICompanyData;

        case actionTypes.REQUEST_TAX_AUTHORITIES:
            return {
                ...state,
                companySettings: Object.assign({}, state.companySettings),
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                taxingAuthorities: [],
                authorityLookup: {} as IAuthorityDictionary,
                isLoading: true,
                error: false,
                message: Date(),
                definedPasswordPolicies: [],
                ledgerData: [],
                groupIDs: [],
                documentToExpireCount: 0,
                isAttested: false,
                isDocumentStoreDirty: false,
                myDownloads: state.myDownloads
            } as ICompanyData;

        case actionTypes.RECEIVE_TAX_AUTHORITIES:
            return {
                companySettings: Object.assign({}, state.companySettings),
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                taxingAuthorities: action.taxingAuthorities,
                authorityLookup: buildAuthorityDictionary(action.taxingAuthorities),
                isLoading: false,
                error: false,
                message: Date(),
                myDownloads: state.myDownloads
            } as ICompanyData;

        case actionTypes.RECEIVE_COMPANY_LOGO:
            var received = { ...state };
            received.companyLogoSetting.logoPath = action.logoPath;
            received.companyLogoSetting.isSsrLogo = action.isSsrLogo;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_COMPANY_PROFILE:
            var received = { ...state };
            received.companyProfile = action.companyProfile;
            received.admins.adminId = action.companyProfile.companyInfo.adminUser;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_ADMIN_PROFILE:
            var received = { ...state };
            received.admins.adminUsers = action.admins.adminUsers;
            received.admins.adminUsers.map((value, index) => {
                if (value.userId == received.companyProfile.companyInfo.adminUser) {
                    received.admins.currentAdmin = value;
                }
            });
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.UPDATE_COMPANY_SIGNATURE:
            var received = { ...state };
            received.signatureUploadLink = action.sasUrl;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.UPDATE_COMPANY_LOGO:
            var received = { ...state };
            received.logoUploadLink = action.sasUrl;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_DEFINED_PASSWORD_POLICY:
            var received = { ...state };
            received.definedPasswordPolicies = action.definedPasswordPolicies;
            return received;

        case actionTypes.RECEIVE_COMPANY_LEDGER:
            var received = { ...state };
            received.ledgerData = action.ledgerData;
            return received;

        case actionTypes.VALIDATE_AD_AZURE_GROUP:
            var received = { ...state };
            received.groupIDs = action.groupIDs;
            return received;

        case actionTypes.REFRESH_TAX_DOCUMENT_STORE:
            let companyState: ICompanyData = { ...state };
            companyState.isDocumentStoreDirty = action.isDirty;
            return companyState;

        case actionTypes.ERROR_EXPIRE_TAX_DOCUMENT_COUNT:
            let expireErrorState: ICompanyData = { ...state };
            expireErrorState.documentToExpireCount = 0;
            expireErrorState.error = true;
            expireErrorState.message = action.message;
            return expireErrorState;

        case actionTypes.UPDATE_COMPANY_SETTINGS_ORGANIZER:
            var received: ICompanyData = { ...state };
            var receivedCompanySetting = received.companySettings as ICompanySettings;
            receivedCompanySetting.organizerSettings.enabled = action.enabled;
            received.isLoading = false;
            return received;

        case actionTypes.RECEIVE_MY_DOWNLOADS_LIST:
            var received = { ...state };
            received.myDownloads = action.myDownloadsList
            return received;

        case actionTypes.REQUEST_PRODUCT_STATUS:
            var received = { ...state };
            received.isCompanyOrganizerProductEnabled = action.status.isCompanyOrganizerProductEnabled
            received.isCompanyOrganizerSubscriptionEnabled = action.status.isCompanyOrganizerSubscriptionEnabled
            return received;        
    }

    return state || unloadedState;
};


function buildAuthorityDictionary(authorities: ITaxingAuthority[]): IAuthorityDictionary {
    let ret: IAuthorityDictionary = {} as IAuthorityDictionary;
    authorities.map((authority, i) => {
        ret[authority.Id] = authority;
    });

    return ret;
}

function getDownloadSuccessDetails(data: any) {
}
