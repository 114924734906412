import { API_BASE_URL } from '../utils/constants'
import { handleResponse } from '../store/Library'
import { IMenuSection, ISideMenuItem } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { IProduct, ProductTypes } from 'src/store/productMenu/model/ProductMenuStoreModel';

export function IsAdminUser(user: any) {
    return user?.profile?.role?.split(',')?.includes('Admin') || false
}

export function IsOnlySignatureUser(user: any) {
    return user?.profile?.role === 'Signatures'
}


export function IsOnlyExchangeUser(user: any) {
    return user?.profile?.role === 'Exchange'
}


export function IsOnlyRestrictedRoles(user: any) {
    var userRoles = user?.profile?.role.split(',');
    const restrictedRoles = ['Signatures', 'Exchange'];
    return (userRoles.length == restrictedRoles.length &&
        userRoles.every((element) => { return restrictedRoles.indexOf(element) != -1 }));
}

export function IsRouteDisabled(leftMenu: IMenuSection[], url: string | string[]) {
    var matchedLeftMenu;
    leftMenu.forEach((menuSection: IMenuSection) => {
        if (menuSection.items?.length) {
            menuSection.items.forEach((sideMenuItem: ISideMenuItem) => {
                if (sideMenuItem.items?.length) {
                    var leftMenu = GetMatchedLeftMenu(sideMenuItem, url);
                    if (leftMenu)
                        matchedLeftMenu = leftMenu;
                }
                else if (sideMenuItem.route != '') {
                    if (typeof url === "string" && url.toLowerCase() == sideMenuItem.route.toLowerCase()) {
                        matchedLeftMenu = sideMenuItem;
                    }
                    else if (Array.isArray(url)) {
                        url.forEach((optionalUrl) => {
                            if (optionalUrl.toLowerCase() == sideMenuItem.route.toLowerCase()) {
                                matchedLeftMenu = sideMenuItem;
                            }
                        });
                    }
                }
            });
        }
    });
    return matchedLeftMenu ? matchedLeftMenu.disabled : true;
}

export function GetMatchedLeftMenu(sideMenuItem: ISideMenuItem, url: string | string[]) {
    var matchedLeftMenu;
    if (sideMenuItem.items?.length) {
        sideMenuItem.items.forEach((childSideMenuItem: ISideMenuItem) => {
            let leftMenu = GetMatchedLeftMenu(childSideMenuItem, url);
            if (leftMenu)
                matchedLeftMenu = leftMenu;
        });
    }
    else if (sideMenuItem.route != '') {
        if (typeof url === "string" && url.toLowerCase() == sideMenuItem.route.toLowerCase()) {
            matchedLeftMenu = sideMenuItem;
        }
        else if (Array.isArray(url)) {
            url.forEach((optionalUrl) => {
                if (optionalUrl.toLowerCase() == sideMenuItem.route.toLowerCase()) {
                    matchedLeftMenu = sideMenuItem;
                }
            });
        }
    }
    return matchedLeftMenu;
}


export function IsAccessibleProduct(productData: IProduct[]) {
    return productData.length > 0 && productData.some((p) => p.title == ProductTypes.SafeSend_Organizers);
}


export function IsAnyChildEnabled(leftMenu: ISideMenuItem[]) {
    var isEnabled: boolean = false;

    leftMenu.forEach((sideMenuItem: ISideMenuItem) => {
        if (sideMenuItem.items?.length) {
            isEnabled = IsAnyChildEnabled(sideMenuItem.items);
        }
        else {
            if (!sideMenuItem.disabled)
                isEnabled = !sideMenuItem.disabled;
        }
    });

    return isEnabled;
}
