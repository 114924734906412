import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

interface TabContentContainerProps {
    isBusy?: boolean;
    enableScroll?: boolean;
}

export class TabContentContainer extends React.Component<TabContentContainerProps, {}> {

    private createMainContent = () => {
        if (this.props.enableScroll) {
            return (<Scrollbars renderTrackHorizontal={this.renderTrackHorizontal}>
                {this.props.children}
            </Scrollbars>);
        }
        else {
            return (this.props.children);
        }
    }

    renderTrackHorizontal() {
        return <div style={{ display: 'none' }}  />
    
    }

    public render() {
        return (<LoadingOverlay style={{ height: "100%" }}>
            <div className="tab-content-container row h100">
                {this.createMainContent()}
            </div>
            <Loader loading={this.props.isBusy} />
        </LoadingOverlay>);
    }
}

