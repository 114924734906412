import * as React from 'react';
import { EngagementType } from './../../../models/OrganizerComponentModels';
import { BatchStoreState } from './../../../models/OrganizerStoreModels';
import { RouteComponentProps } from 'react-router';
import * as UndeliveredBatchStore from './../../../store/UndeliveredBatchStore';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { UndeliveredBatchOrganizerReportTable } from "./UndeliveredBatchOrganizerReportTable";
import { DeleteModal } from "../Modals/DeleteModal";
import moment from 'moment';
import { UndeliveredBatchOrganizerContants } from '../../../../components/helper/Constants'
import { BatchDownloadModal } from '../Modals/BatchDownloadModal';

const PAGESIZE = 20;

interface UndeliveredBatchOrganizerReportState {
    page: number;
    pageSize: number;
    sortName: string;
    sortOrder: string;
    filterBatchName: string;
    filterDeliveredOn?: string;
    filterBatchStatus: any;
    filterTaxYear: any;
    filterEngagementType: EngagementType;
    refreshDelay?: boolean;
    exportExcelShow: boolean;
    downloadCsvModal: {
        batchGuid: string;
        batchName: string;
        taxYear: number;
        show: boolean;
    };
    deleteBatchOrganizerModal: {
        show: boolean
        batchId: string
    }
}

type UndeliveredBatchOrganizerReportProps = {
    batchStoreState: BatchStoreState;
    pageTitle: string;
}
    & typeof UndeliveredBatchStore.actionCreators
    & RouteComponentProps<{}>


export default class UndeliveredBatchOrganizerReport extends React.Component<UndeliveredBatchOrganizerReportProps, UndeliveredBatchOrganizerReportState> {

    constructor(props: any) {
        super(props)
        this.state = {
            page: 1,
            pageSize: PAGESIZE,
            sortName: "deliveredDate",
            sortOrder: "desc",
            filterBatchName: '',
            filterDeliveredOn: undefined,
            filterBatchStatus: '',
            filterEngagementType: EngagementType.E1040,
            filterTaxYear: '',
            refreshDelay: false,
            exportExcelShow: false,
            downloadCsvModal: {
                batchGuid: '',
                batchName: '',
                show: false,
                taxYear: 0
            },
            deleteBatchOrganizerModal: {
                show: false,
                batchId: ''
            }
        }
    }

    componentDidMount() {
        this.props.requestUndeliveredBatch(this.buildQuery());
    }

    buildQuery = () => {
        const { page, pageSize, filterBatchName, filterDeliveredOn, filterBatchStatus, filterEngagementType, filterTaxYear, sortName, sortOrder } = this.state;
        return `?pageNo=${page}&pageSize=${pageSize}&sortBy=${sortName}&sortOrder=${sortOrder}&filterBatchName=${filterBatchName}` +
            `${filterDeliveredOn ? `&filterDeliveredOn=${filterDeliveredOn}` : ''}` +
            `&filterStatus=${filterBatchStatus}&filterEngagementType=${filterEngagementType}&filterTaxYear=${filterTaxYear}`;
    }

    getPageHeader = (header: string) => {
        return (
            <div className="row marB10">
                <div className="col-md-8" style={{ marginTop: '5px' }}>
                    <h3>{header}</h3>
                </div>
            </div>
        );
    }

    onPageReload = () => {
        this.setState(
            { refreshDelay: true }, () => {
                const query = this.buildQuery();
                this.props.requestUndeliveredBatch(query, () => {
                    this.setState({
                        refreshDelay: false,
                    });
                });
            });
    }

    onExportToExcel = (callback: () => void) => {
        this.props.exportReportToExcel(this.buildQuery(), callback);
    }

    onDownloadCsvOpen = (rowIndex: number) => {
        const batch = this.props.batchStoreState.batchModel.batches[rowIndex];
        this.setState({
            downloadCsvModal: {
                batchName: batch.batchName,
                show: true,
                taxYear: batch.taxYear,
                batchGuid: batch.id
            }
        });
    }

    onDownloadCsvClose = () => {
        this.setState({
            downloadCsvModal: {
                batchName: '',
                show: false,
                taxYear: 0,
                batchGuid: ''
            }
        });
    }

    onDeleteBatchModalOpen = (rowIndex: number) => {
        const batch = this.props.batchStoreState.batchModel.batches[rowIndex];
        this.setState({
            deleteBatchOrganizerModal: {
                show: true,
                batchId: batch.id
            }
        });
    }

    onDeleteBatchModalClose = () => {
        this.setState({
            deleteBatchOrganizerModal: {
                show: false,
                batchId: ''
            }
        });
    }

    onDeleteBatchModalConfirm = () => {
        const batchId = this.state.deleteBatchOrganizerModal.batchId;
        this.onDeleteBatchModalClose();
        this.props.deleteUndeliverdBatchOrganizer(batchId, this.onPageReload);
    }

    onFilterChange = (dataField: any) => {

        const newState = {
            filterBatchName: '',
            filterDeliveredOn: undefined,
            filterBatchStatus: '',
            filterEngagementType: EngagementType.E1040,
            filterTaxYear: ''
        } as UndeliveredBatchOrganizerReportState

        let isClearFilter = true;
        let filterStatus: any;
        let filterTaxYear: any;

        for (const field of Object.keys(dataField)) {
            const data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }

            switch (field) {

                case 'name':
                    newState.filterBatchName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'deliveredDate':
                    newState.filterDeliveredOn = moment(dataField[field].value ? dataField[field].value : dataField[field]).format('MM/DD/YYYY')
                    break;
                case 'engagementType':
                    newState.filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentStatus':
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterStatus = (filterStatus === "-1") ? "" : filterStatus;
                    this.setState({ filterBatchStatus: filterStatus });
                    newState.filterBatchStatus = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
                case 'taxYear':
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = (filterTaxYear === "-1") ? "" : filterTaxYear;
                    this.setState({ filterTaxYear: filterTaxYear });
                    newState.filterTaxYear = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
            }
        }

        if (this.isFilterChanged(newState)) {
            this.setState({ ...newState, page: 1 },
                () => {
                    this.props.requestUndeliveredBatch(this.buildQuery())
                });
        }
    }

    isFilterChanged = (newState: UndeliveredBatchOrganizerReportState): boolean => {
        return (
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterBatchStatus !== this.state.filterBatchStatus ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterTaxYear !== this.state.filterTaxYear ||
            newState.filterBatchName !== this.state.filterBatchName
        );
    }

    onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            page: page,
            pageSize: sizePerPage
        }, () => {
            this.props.requestUndeliveredBatch(this.buildQuery())
        });
    }

    onSortChange = (sortName: string, sortOrder: string, column: number) => {
        this.setState({
            sortName: sortName,
            sortOrder: sortOrder === "asc" ? "asc" : "desc",
        }, () => {
            this.props.requestUndeliveredBatch(this.buildQuery())
        });
    }

    render() {

        const { page, pageSize, downloadCsvModal, deleteBatchOrganizerModal, refreshDelay } = this.state;

        const { batchStoreState: { loading, batchModel: { count, batches }, popupLoader }, pageTitle, requestUndeliverdOrganizerCsv } = this.props;

        return (
            <>
                {this.getPageHeader(pageTitle)}

                <LoadingOverlay style={{ height: '100%' }}>
                    <UndeliveredBatchOrganizerReportTable
                        ref={"UndeliveredBatchOrganizer"}
                        batches={batches}
                        isLoading={loading}
                        onBatchDeleteOpen={this.onDeleteBatchModalOpen}
                        onBatchDownloadOpen={this.onDownloadCsvOpen}
                        onExportToExcel={this.onExportToExcel}
                        onFilterChange={this.onFilterChange}
                        onPageChange={this.onPageChange}
                        onSortChange={this.onSortChange}
                        pageNo={page}
                        pageSize={pageSize}
                        totalRows={count}
                        pageReload={this.onPageReload}
                        refreshDelay={refreshDelay}
                    />
                    <Loader loading={loading} text={"Loading..."} />
                </LoadingOverlay>

                <BatchDownloadModal
                    downloadUndeliveredCSV={() => { requestUndeliverdOrganizerCsv(downloadCsvModal.batchGuid, downloadCsvModal.batchName, downloadCsvModal.taxYear, this.onDownloadCsvClose) }}
                    loading={popupLoader}
                    onClose={this.onDownloadCsvClose}
                    show={downloadCsvModal.show}
                />

                <DeleteModal
                    modalHeader={UndeliveredBatchOrganizerContants.DeleteModalHeader}
                    modalBody={UndeliveredBatchOrganizerContants.DeleteModalBody}
                    show={deleteBatchOrganizerModal.show}
                    onClose={this.onDeleteBatchModalClose}
                    onConfirm={this.onDeleteBatchModalConfirm}
                />
            </>
        );
    }
}